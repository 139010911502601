import React from 'react';
import { AgGridReact } from 'ag-grid-react'
import TableContainer from '@mui/material/TableContainer';


const defaultColDef = {
  flex: 100,
  minWidth: 100,
  resizable: true,
  filter: true,
  sortable: true,
  alwaysShowHorizontalScroll: true,
  suppressHorizontalScroll: false
};

class TableComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      gridApi: {},
      selectedRow: {},
      showView: false
    }
  }
  onGridReady = (params) => {
    this.setState({
      gridApi: params.api
    })
  };

  onQuickFilterChanged = () => {
    this.state.gridApi.setQuickFilter(document.getElementById('quickFilter').value);
  };

  onSelectionChanged = () => {
    let rows = this.state.gridApi.getSelectedRows();
    this.setState({
      showView: true
    })

    if (rows && rows.length === 1) {
      this.setState({
        showView: false,
        selectedRow: rows[0]
      })

    }
  };

  render() {
    return (

      <TableContainer className='table-container'>

        <div id="myGrid" className="ag-theme-alpine grid-terminal" style={{ width: '100%', height: this.props.height, fontSize:13 }}>

          <AgGridReact
            columnDefs={this.props.headings}
            defaultColDef={defaultColDef}
            rowData={this.props.data}
            onGridReady={this.onGridReady}
            pagination={true}
            paginationPageSize={30}
            onSelectionChanged={this.onSelectionChanged}
            onCellClicked={this.props.onCellClicked}
            onRowClicked={this.props.handleClick}
            rowStyle={{fontSize:12}}
            
            />
        </div>

       </TableContainer>

    )
  }
}

export default TableComponent;
