export const openNotification = (data)=>({
    type: 'SET_SNACKBAR',
    data
})


export const setSnackbar = (snackbarOpen, snackbarType = "success", snackbarMessage = "") => {
    return function (dispatch) {
        dispatch(openNotification({snackbarOpen,snackbarType,snackbarMessage}))
    }
}

