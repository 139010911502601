import axios from '../../config/axios';
import config from '../../config/api';
import { setSnackbar } from './SnackbarAction';

export const getAllTerminals = (data) => ({
    type: 'GET_TERMINALS',
    data
})

export const getTerminalData = (data) => ({
    type: 'GET_TERMINAL_DATA',
    data
})

export const getFilteredData = (data) => ({
    type: 'FILTER_TERMINALS',
    data
})

export const setTerminalEdit = (data) => ({
    type: 'SET_EDIT',
    data
})

export const setTerminalID = (data) => ({
    type: 'SET_ID',
    data
})

export const setExist = (data) => ({
    type: 'SET_EXIST',
    data
})

export const setSerial = (data) => ({
    type: 'SET_SERIAL',
    data
})

export const setProcessorID = (data) => ({
    type: 'SET_PROCESSOR_ID',
    data
})

export const setModified = (data) => ({
    type: 'SET_MODIFIED',
    data
})

export const setTerminalBatchData = (data) =>({
    type: 'SET_BATCH_DATA',
    data
})

export const setTerminalHistory = (data) =>({
    type: 'SET_TERMINAL_HISTORY',
    data
})

export const setCassetteData = (data) =>({
    type: 'SET_CASSETTE_DATA',
    data
})

export const setLoadingBatchData = (data) =>({
    type: 'LOADING_BATCH_DATA',
    data
})

export const fetchAllTerminals = () => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/terminal/getAllTerminals`).then(response => {
            if (response.status === 200) {
                dispatch(getAllTerminals(response.data));
            } else {
                dispatch(getAllTerminals([]));
            }
        }).catch(error => {
            dispatch(getAllTerminals([]));
        })
    }
}

export const fetchTerminal = (terminalID) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/terminal/getTerminalData/${terminalID}`).then(response => {
            if (response.status === 200) {
                dispatch(getTerminalData([response.data]));
            } else {
                dispatch(setSnackbar(true, 'error', 'Terminal ID entered is not present'))
                dispatch(getTerminalData([]));

            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Terminal ID entered is not present'))
            dispatch(getTerminalData([]));
        })
    }
}

export const fetchMerchantTerminal = (merchantID) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/terminal/getAllTerminalsByMerchantID/${merchantID}`).then(response => {
            if (response.status === 200) {
                dispatch(getAllTerminals(response.data));
            } else {
                dispatch(getAllTerminals([]));
            }
        }).catch(error => {
            dispatch(getAllTerminals([]));
        })
    }
}

export const addTerminal = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.FETCH_URL}/terminal/addTerminal`, body).then(response => {
            if (response.status === 200) {
                dispatch(setModified(true))
                console.log(response.data.terminalID)
                dispatch(setTerminalID(response.data.terminalID))
                dispatch(setSnackbar(true, 'success', 'Terminal Added Successfully'))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const editTerminal = (body) => {
    return function (dispatch) {
        axios.put(`${config.api.FETCH_URL}/terminal/editTerminal`, body).then(response => {
            if (response.status === 200) {
                dispatch(setModified(true))
                dispatch(setTerminalID(response.data.terminalID))
                dispatch(setSnackbar(true, 'success', 'Terminal Updated Successfully'))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const filteredData = (data) => {
    return function (dispatch) {
        dispatch(getFilteredData(data))
    }
}

export const checkTerminalID = (terminalID) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/terminal/isTerminalExists/${terminalID}`).then(response => {
            if (response.status === 200) {
                if (response.data.status === 'Fail') {
                    dispatch(setExist(true))
                    dispatch(setSnackbar(true, 'error', 'Terminal ID Exists'))
                } else {
                    dispatch(setExist(false))
                }
            }
        }).catch(error => {
            // dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const checkSerialNumber = (serialNumber) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/terminal/isSerialNumberExists/${serialNumber}`).then(response => {
            if (response.status === 200) {
                if (response.data.status === 'Fail') {
                    dispatch(setSerial(true))
                    dispatch(setSnackbar(true, 'error', 'Serial Number Exists'))
                } else {
                    dispatch(setSerial(false))
                }
            }
        }).catch(error => {
            // dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}


export const fetchTerminalBatchData = (terminalID) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/terminal/getTerminalBatchTotals/${terminalID}`).then(response => {
            if (response.status === 200) {
                dispatch(setTerminalBatchData(response.data));
                console.log('Terminal Batch Data : ' + response.data.termID)
            } else {
                dispatch(setTerminalBatchData([]));
            }
        }).catch(error => {
            dispatch(setTerminalBatchData([]));
        })
    }
}

export const fetchTerminalHistory = (terminalID) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/terminal/getTerminalHistory/${terminalID}`).then(response => {
            if (response.status === 200) {
                dispatch(setTerminalHistory(response.data));
                console.log('Terminal History : ' + response.data.value)
            } else {
                dispatch(setTerminalHistory([]));
            }
        }).catch(error => {
            dispatch(setTerminalHistory([]));
        })
    }
}

export const fetchProcessorMapping = (processorTermID) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/terminal/getProcesorTerminalMapping/${processorTermID}`).then(response => {
            if (response.status === 200) {
                dispatch(setTerminalHistory(response.data));
                console.log('Terminal History : ' + response.data.value)
            } else {
                dispatch(setTerminalHistory([]));
            }
        }).catch(error => {
            dispatch(setTerminalHistory([]));
        })
    }
}

export const addProcessorTerminalMapping = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.FETCH_URL}/terminal/addProcesorTerminalMapping`, body).then(response => {
            if (response.status === 200) {
                dispatch(fetchTerminalHistory(body.terminalID))
                dispatch(setSnackbar(true, 'success', 'Added Processor TID to history'))
            } else {
                dispatch(setSnackbar(true, 'error', 'Could not add, try again'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Could not add, try again'))
        })
    }
}

export const deleteProcessorTerminalMapping = (body) => {
    return function (dispatch) {
        console.log(body)
        axios.delete(`${config.api.FETCH_URL}/terminal/deleteProcesorTerminalMapping/${body.processorTermID}`).then(response => {
            if (response.status === 200) {
                dispatch(fetchTerminalHistory(body.terminalID))
                dispatch(setSnackbar(true, 'success', 'Removed Processor TID to history'))
            } else {
                dispatch(setSnackbar(true, 'error', 'Error removing Processor TID from history'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Error removing Processor TID from history'))
        })
    }
}

export const fetchCassetteData = (terminalID) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/terminal/getCassetteData/${terminalID}`).then(response => {
            if (response.status === 200) {
                dispatch(setCassetteData([]));
                dispatch(setCassetteData(response.data));
                console.log('Terminal Cassette : ' + response.data.terminalID)
            } else {
                dispatch(setCassetteData([]));
            }
        }).catch(error => {
            dispatch(setCassetteData([]));
        })
    }
}

export const updateCassetteData = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.FETCH_URL}/terminal/editCassetteData`,body).then(response => {
            if (response.status === 200) {
                dispatch(setCassetteData(response.data));
                console.log('Terminal Cassette : ' + response.data)
            } else {
                dispatch(setCassetteData([]));
            }
        }).catch(error => {
            dispatch(setCassetteData([]));
        })
    }
}

export const closeTerminalBatch = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.FETCH_URL}/terminal/closeBatch`, body).then(response => {
            if (response.status === 200) {
                dispatch(setSnackbar(true, 'success', 'Terminal Batchclose Successful'))
                dispatch(fetchTerminalBatchData(body.terminalID))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
        dispatch(setLoadingBatchData(false))
    }
}