import axios from '../../config/axios';
import config from '../../config/api';
import { setSnackbar } from './SnackbarAction';

export const getAllClients = (data) => ({
    type: 'GET_CLIENTS',
    data
})

export const getClientData = (data) => ({
    type: 'GET_CLIENT',
    data
})

export const getFilteredData = (data) => ({
    type: 'FILTER_CLIENTS',
    data
})

export const setEdited = (data) => ({
    type: 'SET_EDIT',
    data
})

export const setModified = (data) => ({
    type: 'SET_MODIFIED',
    data
})

export const setISOID = (data) => ({
    type: 'SET_ID',
    data
})


export const fetchAllClients = () => {

    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/iso/getAllClients`).then(response => {
            if (response.status === 200) {
                dispatch(getAllClients(response.data));
            } else {
                dispatch(getAllClients([]));
            }
        }).catch(error => {
            dispatch(getAllClients([]));
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const fetchClient = (clientID) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/iso/getClientInfo/${clientID}`).then(response => {
            if (response.status === 200) {
                dispatch(getClientData([response.data]));
            } else {
                dispatch(getClientData([]));
            }
        }).catch(error => {
            dispatch(getClientData([]));
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const addIso = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.FETCH_URL}/iso/addClient`, body).then(response => {
            if (response.status === 201) {
                dispatch(setISOID(response.data.isoID))
                dispatch(setSnackbar(true, 'success', 'ISO Added Successfully'))
                dispatch(setModified(true))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const editIso = (body) => {
    return function (dispatch) {
        axios.put(`${config.api.FETCH_URL}/iso/updateClient`, body).then(response => {
            if (response.status === 200) {
                dispatch(setISOID(response.data.isoID))
                dispatch(setSnackbar(true, 'success', 'ISO Edited Successfully'))
                dispatch(setModified(true))
            } else {
                dispatch(setSnackbar(true, 'error', 'ISO Not Modified'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const filteredData = (data) => {
    return function (dispatch) {
        dispatch(getFilteredData(data))
    }
}

