import React, { useEffect } from 'react';
import TableView from '../Table/Table';
import { tableHeadings, terminalHeadings } from '../../../utils/constants';
import { fetchAllTerminals, fetchTerminal, filteredData } from '../../../redux/actions/TerminalActions';
import { fetchAllTransactions } from '../../../redux/actions/TransactionActions';
import { connect } from "react-redux";
import { setTerminalEdit, setTerminalID, setModified } from '../../../redux/actions/TerminalActions';
import { fetchTransactions } from '../../../redux/actions/TransactionActions';
import { Panel, PanelHeader } from '../../header/panel/panel';
import { useNavigate } from 'react-router';
import { CsvBuilder } from 'filefy';

const Terminals = (props) => {

    const navigate = useNavigate()


    const handleClick = (value) => {
        console.log(value.data.terminalId)
        props.setTerminalID(value.data.terminalId)
        navigate('/terminal/view')
    }

    useEffect(() => {
        props.fetchAllTerminals()
        props.setModified(false)
        props.filteredData()
        props.filteredData(props.TERMINALREDUCER.terminalsData)

    }, [])


    const handleSearch = (event) => {

        let filteredTerminals = event.target.value && event.target.value.length ? props.TERMINALREDUCER.terminalsData.filter(terminal => {
            if (terminal.terminalId.toLowerCase().includes(event.target.value.toLowerCase()) || ((props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6) && terminal.processorTermID.toLowerCase().includes(event.target.value.toLowerCase())) || terminal.serialNumber.toLowerCase().includes(event.target.value.toLowerCase()) || terminal.locationName.toLowerCase().includes(event.target.value.toLowerCase()) || terminal.isoName.toLowerCase().includes(event.target.value.toLowerCase())) {
                return terminal
            }
        }) : props.TERMINALREDUCER.terminalsData
        props.filteredData(filteredTerminals)
    }

    const onNewTerminalAddClick = () => {
        navigate('/terminal/add')
    }

    const exportAllSelectedRows = () => {

        let terminalHeading = props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ? tableHeadings.adminTerminals : tableHeadings.terminals

        new CsvBuilder("Terminals.csv")
            .setColumns(terminalHeading.map(col => col.headerName))
            .addRows(props.TERMINALREDUCER.terminalsData?.map(rowData => terminalHeading.map(col => rowData[col.field])))
            .exportFile();
    }

    return (

        <Panel>

            <PanelHeader>

                <div className="row mb-0">

                    <div className="col-xl-4">
                        <h4>Terminals</h4>
                    </div>
                    <div className="col-xl-2">

                    </div>
                    <div className="col-xl-2">
                        <input type="text" className="form-control" placeholder="Search..." onChange={handleSearch} />

                    </div>

                    <div className="col-xl-2">
                        {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ?
                            <button className="btn btn-sm btn-primary d-block w-40 btn-lg h-35px fs-09px" onClick={onNewTerminalAddClick}>Add Terminal</button>
                            : null}
                    </div>
                    <div className="col-xl-2">
                        <button className="btn btn-sm btn-primary d-block w-40 btn-lg h-35px fs-09px" onClick={exportAllSelectedRows}>Export Data</button>

                    </div>


                </div>


            </PanelHeader>

            {
                props.TERMINALREDUCER.filteredData && props.TERMINALREDUCER.filteredData.length ?
                    <TableView height={600} headings= {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6  ? tableHeadings.adminTerminals : tableHeadings.terminals} data={props.TERMINALREDUCER.filteredData} handleClick={handleClick} /> :
                    <TableView height={600} headings={props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ? tableHeadings.adminTerminals : tableHeadings.terminals} data={props.TERMINALREDUCER.terminalsData && props.TERMINALREDUCER.terminalsData?.length ? props.TERMINALREDUCER.terminalsData : null} handleClick={handleClick} />
            }

        </Panel>


    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchAllTerminals,
    fetchTerminal,
    fetchAllTransactions,
    filteredData,
    setTerminalEdit,
    setTerminalID,
    fetchTransactions,
    setModified
}


export default connect(mapStateToProps, mapDispatchToProps)(Terminals);