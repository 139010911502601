import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import moment from 'moment';

import { Panel, PanelBody, PanelHeader } from "../../header/panel/panel"
import Table from '../Table/Table';

import { fetchTerminalHistory, addProcessorTerminalMapping, deleteProcessorTerminalMapping, setTerminalID} from '../../../redux/actions/TerminalActions';
import SweetAlert from 'react-bootstrap-sweetalert';

const TerminalHistory = (props) => {

    const [delModelOpen, setDelModelOpen] = useState(false);
    const [addModelOpen, setAddModelOpen] = useState(false)

    const [data, setData] = useState(null)

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState('')
    const [processorTID, setProcessorTID] = useState('')



    useEffect(() => {
        let id = props.TERMINALREDUCER.terminalID
        if (id) {
            props.fetchTerminalHistory(id)
            console.log('History : ' + id + '  ' + props.TERMINALREDUCER.terminalHistory)
        }

    }, [])

    const handleDeleteConfirmation = () => {

        let body = {}

        body.terminalID = props.TERMINALREDUCER.terminalID
        body.processorTermID = data?.data?.processorTermID
        
        console.log(body)

        if(body.processorTermID && body.terminalID){
            props.deleteProcessorTerminalMapping(body)
            setDelModelOpen(false)
        }

        

    }
    const handleDeleteButton = (value) => {
        setDelModelOpen(true)
    }

    const onCellClicked = (value) => {
        setData(value)
    }

    const handleAddButton = (value) => {
        setAddModelOpen(true)
    }

    const handleChange = (event) => {

        let targetName = event.target.name
        let targetValue = event.target.value
        switch (targetName) {
            case 'processorTermID':
                setProcessorTID(targetValue)
                break;
            case 'startDate':
                setStartDate(targetValue)
                break;
            default:
                break;

        }

    }


    const handleAdd = () => {

        let body = {}

        body.terminalID = props.TERMINALREDUCER.terminalID

        if(processorTID) {
            body.processorTermID = processorTID
        }
        if(startDate) {
            body.createDate = startDate
        }
        console.log(body)

        if(body.processorTermID, body.terminalID, body.createDate){
            props.addProcessorTerminalMapping(body)
            setAddModelOpen(false)
        }

 

    }

    const DELETE_CONFIRMATION = (
        <p className="lead text-dark">
            <SweetAlert danger showCancel
                confirmBtnText="Yes!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                onConfirm={handleDeleteConfirmation}
                onCancel={() => setDelModelOpen(!delModelOpen)}
            >

                <b> Are you sure you want to remove - {data?.data?.processorTermID}</b>

            </SweetAlert>
        </p>
    )

    const ADD_HISTORY_FORM = (
        <p className="lead text-dark ">

            <SweetAlert showCancel
                confirmBtnText="Add"
                cancelBtnBsStyle="default"
                onConfirm={handleAdd}
                onCancel={() => setAddModelOpen(!addModelOpen)}
            >

                <Panel >
                    <PanelHeader>History Form</PanelHeader>
                    <PanelBody>

                        <form className="fs-13px">

                            <div className="mb-3">

                                <div className="row gx-6">

                                    <div className="col-md-12 mb-12 mb-md-2">

                                        <label className="col-md-12"><b>Processor TID</b></label>
                                        <input
                                            name="processorTermID"
                                            value={processorTID ? processorTID : ''}
                                            onChange={handleChange}
                                            type="text"
                                            className="form-control fs-13px"
                                        ></input>
                                    </div>
                                    <div className="mb-3">

                                        <div className="row gx-6">

                                            <div className="col-md-12 mb-12 mb-md-2">
                                                <label className="col-md-12"><b>Start Date</b></label>
                                                <input
                                                    name="startDate"
                                                    value={startDate ? startDate : ''}
                                                    onChange={handleChange}
                                                    type="datetime-local"
                                                    className="form-control fs-13px"
                                                ></input>

                                            </div>
                                        </div>

                                    </div>
                                    {/* <div className="mb-3">

                                        <div className="col-md-12 mb-12 mb-md-2">
                                            <label className="col-md-12"><b>End Date</b></label>
                                            <input
                                                name="autoBatchTime"
                                                value={endDate ? endDate : ''}
                                                type="datetime-local"
                                                className="form-control fs-13px"
                                            ></input>

                                        </div>
                                    </div> */}
                                </div>
                            </div>

                        </form>

                    </PanelBody>
                </Panel>
            </SweetAlert>
        </p>

    )

    const historyHeadings = {

        terminalHistory: [
            { field: 'processorTermID', tooltipField: 'processorTermID', headerName: 'Processor TID', minWidth: 100 },
            {
                field: 'bankStmtDescription', tooltipField: 'bankStmtDescription', headerName: 'Statement Descriptor', minWidth: 100, cellRenderer: function (params) {
                    return params.data.bankStmtDescription
                },
            },
            {
                field: 'createDate', tooltipField: 'createDate', headerName: 'Start Date', minWidth: 180, cellRenderer: function (params) {
                    return params.value ? moment(params.value).format('MMM DD, YYYY HH:mm:ss') : "N/A";
                }
            },

        ],

        adminHistoryHeadings: [
            { field: 'processorTermID', tooltipField: 'processorTermID', headerName: 'Processor TID', minWidth: 100 },
            {
                field: 'bankStmtDescription', tooltipField: 'bankStmtDescription', headerName: 'Statement Descriptor', minWidth: 100, cellRenderer: function (params) {
                    return params.data.bankStmtDescription
                },
            },
            {
                field: 'createDate', tooltipField: 'createDate', headerName: 'Start Date', minWidth: 180, cellRenderer: function (params) {
                    return params.value ? moment(params.value).format('MMM DD, YYYY HH:mm:ss') : "N/A";
                }
            }, {
                field: 'Action', tooltipField: 'action', headerName: '', minWidth: 50, cellRenderer: function (params) {
                    return <button className="btn btn-danger btn-icon btn-circle btn-sm me-1" onClick={handleDeleteButton}>X</button>
                }
            },
            // {
            //     field: 'Action', tooltipField: 'action', headerName: '', minWidth: 50, cellRenderer: function (params) {
            //         return <button className="btn btn-warning btn-xs" onClick={handleAddButton}>Edit</button>
            //     }
            // },

        ]
    }


    return (
        <>


            <div>
                <Panel>
                    <PanelHeader>
                        <div className="row mb-0">

                            <div className="col-xl-4">
                                Terminal History
                            </div>

                            <div className="col-xl-4"></div>

                            <div className="col-xl-4">
                                {props.LOGINREDUCER.USER.role == 1 ? <button className='btn btn-primary d-block w-30 btn-lg h-9px fs-11px' onClick={handleAddButton}>Add History</button> : null}
                            </div>
                        </div>
                    </PanelHeader>
                    {props.LOGINREDUCER.USER.role == 1 ? < Table height={270} headings={historyHeadings.adminHistoryHeadings} data={props.TERMINALREDUCER.terminalHistory} handleClick = {onCellClicked} />
                        :
                        < Table height={260} headings={historyHeadings.terminalHistory} data={props.TERMINALREDUCER.terminalHistory} handleClick = {onCellClicked} />
                    }
                </Panel>
                {delModelOpen ?
                    DELETE_CONFIRMATION : null
                }
                {addModelOpen ?
                    ADD_HISTORY_FORM : null
                }
            </div>
        </>
    )

}
function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchTerminalHistory,
    addProcessorTerminalMapping,
    deleteProcessorTerminalMapping,
}


export default connect(mapStateToProps, mapDispatchToProps)(TerminalHistory);

