const initialState = { fetching: false, exportData: [], autoReport: [], autoReportLog: [] };

export const EXPORTREDUCER = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_EXPORT_LOADING':
            return {
                ...state,
                fetching: action.data
            }
        case 'SET_EXPORT':
            return {
                ...state,
                exportData: action.data
            }
        case 'SET_AUTO_REPORT':
                return {
                    ...state,
                    autoReport: action.data
                }    
        case 'SET_AUTO_REPORT_LOG':
            return {
                ...state,
                autoReportLog: action.data
            }
        default:
            return state;
    }
}