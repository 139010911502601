import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./redux/store";
import IdleTimerContainer from './utils/IdleTimerContainer';
import SnackBar from './utils/SnackBar';

export default function Launch() {

  const routing = useRoutes(routes);

  return (

    <Provider store={configureStore.store}>
      <PersistGate loading={null} persistor={configureStore.persistor}>
        <IdleTimerContainer>
        </IdleTimerContainer>
        <SnackBar />
          {routing}
      </PersistGate>
    </Provider>
  );
}

