import axios from "../../config/axios";
import config from "../../config/api";
import { setSnackbar } from "./SnackbarAction";

export const setExportData = (data) => ({
    type: "SET_EXPORT",
    data
})

export const setFetchingReport = (data) => ({
    type: 'SET_EXPORT_LOADING',
    data
})

export const setAutoReport = (data) => ({
    type: 'SET_AUTO_REPORT',
    data
})

export const setAutoReportLog = (data) => ({
    type: 'SET_AUTO_REPORT_LOG',
    data
})


export const getDetailedReport = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.FETCH_URL}/reports/getDetailedReport`,body).then(response => {
            if (response.status === 200) {
                dispatch(setExportData(response.data))
            }
            dispatch(setFetchingReport(false))

        }).catch(error => {
            dispatch(setExportData([]));
            dispatch(setFetchingReport(false))
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })


    }
}

export const getSummaryReport = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.FETCH_URL}/reports/getSummaryReport`,body).then(response => {
            if (response.status == 200) {
                dispatch(setExportData(response.data))
            }
            dispatch(setFetchingReport(false))
        }).catch(error => {
            dispatch(setExportData([]));
            dispatch(setFetchingReport(false))
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}


export const getDetailedReportByISO = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.FETCH_URL}/reports/getDetailedReportByISO`,body).then(response => {
            if (response.status === 200) {
                dispatch(setExportData(response.data))
            }
            dispatch(setFetchingReport(false))

        }).catch(error => {
            dispatch(setExportData([]));
            dispatch(setFetchingReport(false))
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })


    }
}

export const getSummaryReportByISO = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.FETCH_URL}/reports/getSummaryReportByISO`,body).then(response => {
            if (response.status == 200) {
                dispatch(setExportData(response.data))
            }
            dispatch(setFetchingReport(false))
        }).catch(error => {
            dispatch(setExportData([]));
            dispatch(setFetchingReport(false))
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}


export const getAutoReportsList = () => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/reports/getAutoReportItems`).then(response => {
            if (response.status == 200) {
                dispatch(setAutoReport(response.data))
            }
            dispatch(setFetchingReport(false))
        }).catch(error => {
            dispatch(setAutoReport([]));
            dispatch(setFetchingReport(false))
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const addAutoReport = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.FETCH_URL}/reports/addAutoReportItem`,body).then(response => {
            if (response.status == 200) {
                dispatch(getAutoReportsList())
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const delAutoReport = (autoReportID) => {
    return function (dispatch) {
        axios.delete(`${config.api.FETCH_URL}/reports/deleteAutoReportItem/${autoReportID}`,).then(response => {
            if (response.status == 200) {
                dispatch(getAutoReportsList())
            }
        }).catch(error => {
            dispatch(getAutoReportsList())
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const processAutoReport = (autoReportID) => {
    return function (dispatch) {
        axios.post(`${config.api.FETCH_URL}/reports/processAutoReports`).then(response => {
            if (response.status == 200) {
                dispatch(setSnackbar(true, 'success', 'Process is initiated and will run behind'))
            }
        }).catch(error => {
            dispatch(getAutoReportsList())
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const getAutoReportsLog = () => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/reports/getAutoReportLog`).then(response => {
            if (response.status == 200) {
                dispatch(setAutoReportLog(response.data))
            }
            dispatch(setFetchingReport(false))
        }).catch(error => {
            dispatch(setAutoReportLog([]));
            dispatch(setFetchingReport(false))
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}