import React, { useEffect, useState } from 'react';
import { addIso, editIso, setEdited, setModified } from "../../../redux/actions/IsoActions";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { STATUS, STATES } from '../../../utils/constants';
import { fetchIsos, fetchProcessors, fetchTerminalMake, fetchReportTypes, fetchPrefix } from "../../../redux/actions/dropdownActions";
import { Panel, PanelBody, PanelHeader } from '../../header/panel/panel';
import InputMask from 'react-input-mask';

const ISOAddEdit = (props) => {
    const navigate = useNavigate();
    const [isoName, setIsoName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [state, setState] = useState('');
    const [phone, setPhone] = useState('');
    const [contactName, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [status, setStatus] = useState(1);
    const [isoError, setIsoError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [postalCodeError, setPostalCodeError] = useState(false);
    const [stateError, setStateError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [contactError, setContactError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [websiteError, setWebsiteError] = useState(false);
    const [statusError, setStatusError] = useState(false);
    const [defaultAddress, setDefaultAddress] = useState(false);
    const [defaultContact, setDefaultContact] = useState(false);

    const handleChange = (event) => {
        let targetName = event.target.name;
        let targetValue = event.target.value;
        switch (targetName) {
            case 'isoName':
                setIsoError(false);
                setIsoName(targetValue);
                break
            case 'isoAddress':
                setAddressError(false);
                setAddress(targetValue);
                break
            case 'isoCity':
                setCityError(false);
                setCity(targetValue);
                break
            case 'isoPostalCode':
                setPostalCodeError(false);
                setPostalCode(targetValue);
                break
            case 'isoState':
                setStateError(false);
                setState(targetValue);
                break
            case 'isoPhone':
                setPhoneError(false);
                setPhone(targetValue);
                break
            case 'contactName':
                setContactError(false);
                setContactName(targetValue);
                break
            case 'isoEmail':
                setEmailError(false);
                setEmail(targetValue);
                break
            case 'isoWebsite':
                setWebsiteError(false);
                setWebsite(targetValue);
                break
            case 'isoStatus':
                setStatusError(false);
                setStatus(targetValue);
                break
            default:
                break
        }
    }

    useEffect(() => {
        if (props.ISOREDUCER.modified) {
                navigate('/iso/view')
        }
    }, [props.ISOREDUCER])

    const handleEditCancel = (event) => {
        navigate('/iso/view');
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        let body = {}
        if (isoName && isoName.trim()) {
            setIsoError(false);
            body.isoName = isoName
        } else {
            setIsoError(true)
        }

        if (status) {
            setStatusError(false);
            body.isoStatus = status
        } else {
            setStatusError(true)
        }


        if (address && address.trim()) {
            let addressItems = {}
            setAddressError(false);
            addressItems.street = address;
            body.address = addressItems
        } else {
            setAddressError(true)
        }

        if (city && city.trim()) {
            setCityError(false);
            body['address'].city = city;
        } else {
            setCityError(true)
        }

        if (postalCode && postalCode.trim()) {
            setPostalCodeError(false);
            body['address'].postalCode = postalCode;
        } else {
            setPostalCodeError(true)
        }

        if (state && state.trim()) {
            setStateError(false);
            body['address'].state = state;
        } else {
            setStateError(true)
        }

        let contactItems = {}
        if (email && email.trim()) {
            setEmailError(false);
            contactItems.email = email;
        } else {
            setEmailError(true)
        }

        if (contactName && contactName.trim()) {
            setContactError(false);
            contactItems.name = contactName
        } else {
            setContactError(true)
        }

        if (phone && phone.trim()) {
            setPhoneError(false);
            contactItems.phone = phone;
        } else {
            setPhoneError(true)
        }
        if (website && website.trim()) {
            setWebsiteError(false);
            contactItems.website = website;
        } else {
            setWebsiteError(true)
        }
        body.contact = contactItems
        if (isoName && address && city && postalCode && state && phone && contactName && email && website && status && !props.edit) {
            props.addIso(body);
        } else if (isoName && address && city && postalCode && state && phone && contactName && email && website && status && props.edit) {
            body = {
                ...props.ISOREDUCER.clientData[0],
                ...body
            }
            props.editIso(body);
            props.setEdited(true);
        }

    }

    const handleResetWithPreventDefault = (event) => {
        event.preventDefault();
        handleReset();

    }
    const handleReset = () => {
        setIsoName('')
        setAddress('')
        setCity('')
        setPostalCode('')
        setState('')
        setPhone('')
        setContactName('')
        setEmail('')
        setWebsite('')
        setStatus(1)
        setIsoError(false);
        setAddressError(false);
        setCityError(false);
        setPostalCodeError(false);
        setStateError(false);
        setPhoneError(false);
        setContactError(false);
        setEmailError(false);
        setWebsiteError(false);
        setStatusError(false);
        setDefaultAddress(false);
        setDefaultContact(false)
    }

    const handleDefaultAddress = (event) => {
        setDefaultAddress(!defaultAddress)
        if (!defaultAddress) {
            setAddress('8551 W Sunrise Blvd')
            setCity('Plantation')
            setState('FL')
            setPostalCode('33322')
            setAddressError(false);
            setCityError(false);
            setPostalCodeError(false);
            setStateError(false);
        } else {
            setAddress('')
            setCity('')
            setState('')
            setPostalCode('')
        }
    }

    const handleDefaultContact = (event) => {
        setDefaultContact(!defaultContact)
        if (!defaultContact) {
            setContactName('Barma')
            setEmail('Contact@barmatechnologies.com')
            setPhone('9999999999')
            setWebsite('go-barmatech.com')
            setPhoneError(false);
            setContactError(false);
            setEmailError(false);
            setWebsiteError(false);
        } else {
            setContactName('')
            setEmail('')
            setPhone('')
            setWebsite('')
        }
    }

    useEffect(() => {
        if (props.edit) {
            let data = props.ISOREDUCER.clientData[0]
            setIsoName(data.isoName)
            setAddress(data.address.street)
            setCity(data.address.city)
            setPostalCode(data.address.postalCode)
            setState(data.address.state)
            setPhone(data.contact.phone)
            setContactName(data.contact.name)
            setEmail(data.contact.email)
            setWebsite(data.contact.website)
            setStatus(data.isoStatus)
            setDefaultAddress(false)
            setDefaultContact(false)
        }
    }, [])

    useEffect(() => {
        if (!props.edit) handleReset()
    }, [props.edit])


    let ISO_FORM = (
        <form className="fs-13px">

            <div className="mb-3">

                <div className="row gx-3">

                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (isoError ? 'text-danger' : '')}><b>ISO Name</b></label>

                        <input
                            type="text" className="form-control fs-13px"
                            name="isoName"
                            onChange={handleChange}
                            value={isoName ? isoName : ''}
                            error={isoError}
                        />
                    </div>

                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-3 " + (statusError ? 'text-danger' : '')}><b>Status</b></label>

                        <select className="form-select"
                            value={status ? status : 1}
                            error={statusError}
                            onChange={handleChange}
                            name='isoStatus'
                        >

                            {STATUS.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.status}
                                </option>
                            ))}
                        </select>
                    </div>


                </div>


            </div>

            <hr className="mb-10px bg-gray-600" />

            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-3 mb-2 mb-md-0">
                        <h5>
                            <b>
                                Address
                            </b>
                        </h5>

                    </div>
                    <div className="col-md-3 mb-2 mb-md-0">
                        <label className="form-check-label" >
                            <b>
                                Set Default
                            </b>
                            &nbsp;&nbsp;&nbsp;
                            <input className="form-check-input" type="checkbox" checked={defaultContact} onChange={handleDefaultContact} >
                            </input>
                        </label>
                    </div>
                </div>
            </div>


            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (contactError ? 'text-danger' : '')}><b>Contact Name</b></label>
                        <input type="text" className="form-control fs-13px"
                            name="contactName"
                            onChange={handleChange}
                            value={contactName ? contactName : ''}
                            error={contactError}
                        />
                    </div>
                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (emailError ? 'text-danger' : '')}><b>Email</b></label>
                        <input type="text" className="form-control fs-13px"
                            name="isoEmail"
                            onChange={handleChange}
                            value={email ? email : ''}
                            error={emailError}
                        />
                    </div>
                </div>

            </div>
            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (phoneError ? 'text-danger' : '')}><b>Phone</b></label>
                        <InputMask
                            name="isoPhone"

                            mask="(999) 999-9999"
                            className="form-control"
                            placeholder="(999) 999-9999"
                            onChange={handleChange}
                            value={phone ? phone : ''}
                            error={phoneError}
                        ></InputMask>

                    </div>
                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (emailError ? 'text-danger' : '')}><b>Website</b></label>
                        <input type="text" className="form-control fs-13px"
                            name="isoWebsite"
                            onChange={handleChange}
                            value={website ? website : ''}
                            error={websiteError}
                        />
                    </div>
                </div>

            </div>


            <hr className="mb-10px bg-gray-600" />


            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-3 mb-2 mb-md-0">
                        <h5>
                            <b>
                                Contact Details
                            </b>
                        </h5>

                    </div>
                    <div className="col-md-3 mb-2 mb-md-0">
                        <label className="form-check-label" >
                            <b>
                                Set Default
                            </b>
                            &nbsp;&nbsp;&nbsp;
                            <input className="form-check-input" type="checkbox" checked={defaultAddress} onChange={handleDefaultAddress} >
                            </input>
                        </label>
                    </div>
                </div>
            </div>


            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-6 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (emailError ? 'text-danger' : '')}><b>Street</b></label>
                        <input type="text" className="form-control fs-13px"
                            name="isoAddress"
                            onChange={handleChange}
                            value={address ? address : ''}
                            error={addressError}
                        />
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-2 mb-2 mb-md-0">
                        <label className={"col-md-6 " + (cityError ? 'text-danger' : '')}><b>City</b></label>
                        <input type="text" className="form-control fs-13px"
                            name="isoCity"
                            onChange={handleChange}
                            value={city ? city : ''}
                            error={cityError}
                        />
                    </div>

                    <div className="col-md-2 mb-2 mb-md-0">
                        <label className={"col-md-3 " + (statusError ? 'text-danger' : '')}><b>Status</b></label>
                        <select className="form-select"

                            value={state ? state : ''}
                            error={stateError}
                            onChange={handleChange}
                            name='isoState'
                        >
                            <option value=''>Select State</option>

                            {STATES.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.value}
                                </option>
                            ))}
                        </select>
                    </div>


                    <div className="col-md-2 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (cityError ? 'text-danger' : '')}><b>Zip</b></label>
                        <input type="text" className="form-control fs-13px"
                            name="isoPostalCode"
                            onChange={handleChange}
                            value={postalCode ? postalCode : ''}
                            error={postalCodeError}
                        />
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-3 mb-2 mb-md-0">
                        <button className='btn btn-primary d-block w-100 btn-lg h-9px fs-13px' onClick={handleSubmit}>{props.edit ? 'Save' : 'Add'}</button>
                    </div>
                    <div className="col-md-3 mb-2 mb-md-0">
                        {props.edit ? <button className='btn btn-gray d-block w-100 btn-lg h-9px fs-13px' onClick={handleEditCancel} >Cancel</button>
                            : <button className='btn btn-gray d-block w-100 btn-lg h-9px fs-13px' onClick={handleResetWithPreventDefault} variant='contained' >Reset</button>}
                    </div>
                </div>

            </div>
        </form >
    )

    return (
        <Panel >
            <PanelHeader>ISO Form</PanelHeader>
            <PanelBody>
                {ISO_FORM}
            </PanelBody>
        </Panel>
    );
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    addIso,
    editIso,
    setEdited, setModified,
    fetchIsos, fetchProcessors, fetchTerminalMake,
    fetchReportTypes, fetchPrefix
}


export default connect(mapStateToProps, mapDispatchToProps)(ISOAddEdit);