const initialState = { allMerchants: [], merchantInfo: [], filteredData: [], edited: false, merchantID: null, modified: false };

export const MERCHANTREDUCER = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_MERCHANTS':
            return {
                ...state,
                allMerchants: action.data,
                merchantInfo: state.merchantInfo
            }
        case 'GET_MERCHANTS':
            return {
                ...state,
                allMerchants: state.allMerchants,
                merchantInfo: action.data
            }
        case 'FILTER_MERCHANTS':
            return {
                ...state,
                allMerchants: state.allMerchants,
                filteredData: action.data
            }
        case 'SET_EDIT':
            return {
                ...state,
                edited: action.data
            }
        case 'SET_ID':
            return {
                ...state,
                merchantID: action.data
            }
        case 'SET_MODIFIED':
            return {
                ...state,
                modified: action.data
            }
        default:
            return state;
    }
}