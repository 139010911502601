
 
 export const getUser = () => {
  // removeUserSession()
     const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }
  
  // return the token from the session storage
  export const getToken = () => {
    return localStorage.getItem('token') || null;
  }
  
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
  }
  
  // set the token and user from the session storage
  export const setUserSession = (token, user, role, isEmailVerified) => {
    localStorage.setItem('token', token);
    localStorage.setItem('role', role);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('isEmailVerified', JSON.stringify(isEmailVerified))
  }

  export const getRole = () =>{

    return localStorage.getItem('role') || null;

  }