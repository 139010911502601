import axios from '../../config/axios';
import config from '../../config/api';


export const getAllTransactions = (data) => ({
    type: 'GET_TRANSACTIONS',
    data
})

export const getDashboardData = (data) => ({
    type: 'GET_DASHBOARD_DATA',
    data
})



export const fetchAllTransactions = (ID) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/transactions/getLastestTrans/${ID}`).then(response => {
            if (response.status === 200) {
                dispatch(getAllTransactions(response.data));
            } else {
                dispatch(getAllTransactions([]));
            }
        }).catch(error => {
            dispatch(getAllTransactions([]));
        })
    }
}

export const fetchTransactions = (ID) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/transactions/getTermLatestTrans/${ID}`).then(response => {
            if (response.status === 200) {
                dispatch(getAllTransactions(response.data));               
            } else {
                dispatch(getAllTransactions([]));
            }
        }).catch(error => {
            dispatch(getAllTransactions([]));
        })
    }
}

export const fetchDashboardData = () => {
    return function (dispatch) {
        console.log('Calling fetch dashboard data')
        axios.get(`${config.api.FETCH_URL}/reports/getDashboardData`).then(response => {
            if (response.status === 200) {
                dispatch(getDashboardData(response.data));
                console.log('fetched dashboard data : '+ response.data.noOfTransactions)
            } else {
                dispatch(getDashboardData([])); 
            }
        }).catch(error => {
            dispatch(getDashboardData([]));
        })
    }
}