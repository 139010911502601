import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { startLogin } from '../../src/redux/actions/LoginActions';
import { connect } from "react-redux";
import { useAuth } from '../../src/utils/Auth';


const LoginComponent = (props) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [validUser, setValidUser] = useState(true);
    const [validPassword, setValidPassword] = useState(true)
    const [loader, setLoader] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (event) => {

        if (event.target.name === 'username') {
            setValidUser(true)
            setUsername(event.target.value)
        }
        if (event.target.name === 'password') {
            setValidPassword(true)
            setPassword(event.target.value)
        }
    }


    const navigate = useNavigate();
    const auth = useAuth();
    const location = useLocation();

    const [user, setUser] = useState({ username: '', password: '' });
    const [msg, setMsg] = useState('');

    const redirectPath = location.state?.pathname || '/'


    useEffect(() => {
        setLoader(false);
    }, []);

    useEffect(() => {
        if (props.LOGINREDUCER.ISAUTH) {
            auth.login({ ...props.LOGINREDUCER.USER })
            navigate(redirectPath)
            
        } else {
            navigate('/login')
        }
        setLoader(false);
    }, [props.LOGINREDUCER])


    const handleSubmit = (event) => {
        event.preventDefault();
        let params = {
            username: username,
            password: password
        }

        if (username && username.length && password && password.length) {
            setLoader(true);
            props.startLogin(params)

        } else {
            if (!username && !username.length) {
                setValidUser(false);
            }
            if (!password && !password.length) {
                setValidPassword(false)
            }
        }
    }

    return (
        <div className="login login-v1">
            <div className="login-container">
                <div className="login-header">
                    <div className="brand">
                        <div className="d-flex align-items-center">
                            <span className="logo"></span> <b>Barma Technologies</b>
                        </div>
                        <small>Reporting Portal</small>
                    </div>
                    <div className="icon">
                        <i className="fa fa-lock"></i>
                    </div>
                </div>
                <div className="login-body">
                    <div className="login-content fs-13px">
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="username" className="d-flex align-items-center py-0">Username</label>
                            <div className="form-floating mb-20px">
                                <input type="username" className="form-control fs-15 h-45px" name="username" placeholder="username" onChange={handleChange} />
                            </div>
                            <label htmlFor="password" className="d-flex align-items-center py-0">Password</label>
                            <div className="form-floating mb-20px">
                                <input type= { showPassword ? "text" :  "password" } className="form-control fs-15px h-45px" name="password" placeholder="password" onChange={handleChange} />
                            </div>
                            <div className="form-check mb-20px">
									<input className="form-check-input" type="checkbox" value="" id="rememberMe" checked= {showPassword} onChange={() => {setShowPassword(!showPassword)}}/>
									<label className="form-check-label" htmlFor="rememberMe">
										Show Password
									</label>
								</div>

                            <div className="login-buttons">
                                <button type="submit" className="btn h-45px btn-success d-block w-100 btn-lg">Sign me in</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    startLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);