import React from 'react';
import { startLogout } from '../../../redux/actions/LoginActions';
import { connect } from "react-redux";
import { AccountCircle } from '@material-ui/icons';


const SidebarProfile = (props) => {


	return (


		<div className="menu-profile ">
			<div className="menu-profile-cover with-shadow"></div>
			<div className="menu-profile-image">
				<img src="../assets/img/user/user-13.jpg" alt="" />
				<AccountCircle></AccountCircle>
			</div>
			<div className="menu-profile-info">
				<div className="d-flex align-items-center">
					<div className="flex-grow-1">
						<b>{props.LOGINREDUCER.USER.user}</b>
					</div>
				</div>
				<div className="d-flex align-items-center">

					<div className="flex-grow-1">
						
						{props.LOGINREDUCER.USER.role === 1 ? 'Admin' : props.LOGINREDUCER.USER.role === 2 ? 'Client' : props.LOGINREDUCER.USER.role === 3 ? 'Agent' : props.LOGINREDUCER.USER.role === 4 ? 'Merchant' : props.LOGINREDUCER.USER.role === 5 ? 'User' : props.LOGINREDUCER.USER.role === 6 ? 'Tech Support' : ' '}

					</div>
				</div>

			</div>
		</div>

	)
}

function mapStateToProps(state) {
	return state
}

const mapDispatchToProps = {
	startLogout
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarProfile);
