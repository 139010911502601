const config = {
    // dev pps
    // api: {
    //     BASE_URL: 'https://dev.reportspps.com:9184',
    //     FETCH_URL: 'https://dev.reportspps.com:9185',
    //     KEYS_URL: 'https://dev.reportspps.com:9186',
    // }
    // dev barma portal
    // api: {
    //     BASE_URL: 'https://dev.reportspps.com:9284',
    //     FETCH_URL: 'https://dev.reportspps.com:9285',
    //     KEYS_URL: 'https://dev.reportspps.com:9286',
    // }
    // prod barma
    api: {
        BASE_URL: 'https://barmareports.com:8611',
        FETCH_URL: 'https://barmareports.com:8612',
        KEYS_URL: 'https://barmareports.com:8613',
    }
    // prod pps
    // api: {
    //     BASE_URL: 'https://reportspps.com:4611',
    //     FETCH_URL: 'https://reportspps.com:4612',
    //     KEYS_URL: 'https://reportspps.com:4613',
    // }
}


export default config;