import { AddBox, ExpandLess, ExpandMore, ViewList } from '@material-ui/icons';
import { Download, FileUpload, GridViewRounded } from '@mui/icons-material';
import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { loginSuccess } from '../../../redux/actions/LoginActions';
import { connect } from "react-redux";

const SidebarNav = (props) => {


	const location = useLocation();

	const [addOpen, setAddOpen] = useState(false)
	const [listOpen, setListOpen] = useState(false)
	const [adminFunctionOpen, setAdminFunctionOpen] = useState(false);


	const handleAddExpand = () => {
		setAddOpen(!addOpen)
	}

	const handleListExpand = () => {
		setListOpen(!listOpen)
	}

	const handleAdminfunctionExpand = () =>{
		setAdminFunctionOpen(!adminFunctionOpen)
	}

	return (

		<div id="sidebar" className='sidebar'>

			<div className="menu">

				<div className="menu-header" >Menu</div>

				<div className={"menu-item"}>
					<Link to='/dashboard' className="menu-link">
						<h6> <GridViewRounded fontSize='small'></GridViewRounded>  DASHBOARD</h6>
					</Link>
				</div>

				<div className={"menu-item has-sub "}>

					<Link to={location} onClick={handleListExpand} className="menu-link ">

						<h6><ViewList fontSize='small'></ViewList> LIST OF {listOpen ? <ExpandLess /> : <ExpandMore />}</h6>

					</Link>

					<div className={"menu-submenu  " + (listOpen ? "d-block" : "d-none")}>

						{[1, 2, 3, 4, 5, 6, 7].includes(props.LOGINREDUCER.USER.role) ?
							<div className={"menu-item"}>
								<Link to='/terminal/list' className="menu-link"><h6>Terminals</h6></Link>
							</div> : null}
						{[1, 2, 3, 6, 7].includes(props.LOGINREDUCER.USER.role) ?

							<div className={"menu-item"}>
								<Link to='/merchant/list' className="menu-link"><h6>Merchants</h6></Link>
							</div> : null}
						{[1, 6, 7].includes(props.LOGINREDUCER.USER.role) ?

							<div className={"menu-item"}>
								<Link to='/iso/list' className="menu-link"><h6>ISOs</h6></Link>
							</div> : null}
						{[1, 6, 7].includes(props.LOGINREDUCER.USER.role) ?

							<div className={"menu-item"}>
								<Link to='/user/list' className="menu-link"><h6>Users</h6></Link>
							</div>
							: null}

					</div>

				</div>

				{[1, 6, 7].includes(props.LOGINREDUCER.USER.role) ?

					<div className={"menu-item has-sub"}>

						<Link to={location} onClick={handleAddExpand} className="menu-link">

							<h6><AddBox fontSize='small'></AddBox> ADD NEW {addOpen ? <ExpandLess /> : <ExpandMore />}</h6>
						</Link>

						<div className={"menu-submenu  " + (addOpen ? "d-block" : "d-none")}>

							{[1, 6].includes(props.LOGINREDUCER.USER.role) ? <div className={"menu-item"}>
								<Link to='/terminal/add' className="menu-link"><h6>Terminal</h6></Link>
							</div> : null}
							{[1, 6].includes(props.LOGINREDUCER.USER.role) ? <div className={"menu-item"}>
								<Link to='/merchant/add' className="menu-link"><h6>Merchant</h6></Link>
							</div> : null}
							{[1].includes(props.LOGINREDUCER.USER.role) ? <div className={"menu-item"}>
								<Link to='/iso/add' className="menu-link"><h6>ISO</h6></Link>
							</div> : null}
							{[1, 6, 7].includes(props.LOGINREDUCER.USER.role) ? <div className={"menu-item"}>
								<Link to='/user/add' className="menu-link"><h6>User</h6></Link>
							</div> : null}

						</div>

					</div>
					: null}

				{[1, 6].includes(props.LOGINREDUCER.USER.role) ?
					<div className={"menu-item"}>
						<Link to='/import' className="menu-link">
							<h6><FileUpload fontSize='small'></FileUpload>  IMPORT</h6>
						</Link>
					</div>
					: null}

				<div className={"menu-item"}>
					<Link to='/reports' className="menu-link">
						<h6><Download fontSize='small'></Download>  REPORTS</h6>
					</Link>
				</div>

				{[1,6].includes(props.LOGINREDUCER.USER.role) ?

					<div className={"menu-item has-sub"}>

						<Link to={location} onClick={handleAdminfunctionExpand} className="menu-link">

							<h6><AddBox fontSize='small'></AddBox> ADMIN {adminFunctionOpen ? <ExpandLess /> : <ExpandMore />}</h6>
						</Link>

						<div className={"menu-submenu  " + (adminFunctionOpen ? "d-block" : "d-none")}>

							{[1,6].includes(props.LOGINREDUCER.USER.role) ? <div className={"menu-item"}>
								<Link to='/admin/loginAsUser' className="menu-link"><h6>Login As</h6></Link>
							</div> : null}
							{[1,6].includes(props.LOGINREDUCER.USER.role) ? <div className={"menu-item"}>
								<Link to='/admin/autoReports' className="menu-link"><h6>Auto Reports</h6></Link>
							</div> : null}

						</div>

					</div>
					: null}

			</div>

		</div>

	);
}

function mapStateToProps(state) {
	return state
}

const mapDispatchToProps = {
	loginSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNav);
