import React, { useEffect, useState } from 'react';
import TableView from '../Table/Table';
import { tableHeadings, terminalHeadings } from '../../../utils/constants';
import { fetchAllMerchants, fetchMerchantInfo, setModified, filteredData, setMerchantID } from '../../../redux/actions/MerchantActions';
import { connect } from "react-redux";

import { useNavigate } from 'react-router';
import { Panel, PanelHeader } from '../../header/panel/panel';

const Merchant = (props) => {

    const navigate = useNavigate()

    const [searchValue, setSearchValue] = useState('');
    
    let merchantHeading =  props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 || props.LOGINREDUCER.USER.role == 7 ? tableHeadings.merchants : tableHeadings.merchantsSubList;


    useEffect(() => {
        props.fetchAllMerchants()
        props.setModified(false)
        props.filteredData()


    }, [])


    const handleMerchantClick = (value) => {
        props.setMerchantID(value.data.merchantID)
        navigate('/merchant/view')
    }

    const handleAddMerchant = (event) => {
        event.preventDefault()
        navigate('/merchant/add')
    }


    const handleSearch = (event) => {
        setSearchValue(event.target.value)
        let filteredMerchants = event.target.value && event.target.value.length ? props.MERCHANTREDUCER.allMerchants.filter(merchant => {
            if (merchant.merchantName.toLowerCase().includes(event.target.value.toLowerCase()) || merchant.isoName.toLowerCase().includes(event.target.value.toLowerCase())) {
                return merchant
            }
        }) : []

        props.filteredData(filteredMerchants)
    }


    return (

        <Panel>

            <PanelHeader>

                <div className="row mb-0">

                    <div className="col-xl-4">
                        <h4>Merchants</h4>
                    </div>
                    <div className="col-xl-3">

                    </div>
                    <div className="col-xl-2">
                        <input type="text" className="form-control" placeholder="Search..." onChange={handleSearch} />

                    </div>
                    <div className="col-xl-1">

                    </div>
                    {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ?
                        <div className="col-xl-2">
                            <button className="btn btn-sm btn-primary d-block w-40 btn-lg h-35px fs-09px" onClick={handleAddMerchant}>Add Merchant</button>

                        </div>
                        : null}
                </div>

            </PanelHeader>

            <TableView height={600} headings={merchantHeading} data={(props.MERCHANTREDUCER.filteredData && props.MERCHANTREDUCER.filteredData.length) || searchValue ? props.MERCHANTREDUCER.filteredData : props.MERCHANTREDUCER.allMerchants} handleClick={handleMerchantClick} />

        </Panel>


    )

}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchAllMerchants,
    fetchMerchantInfo,
    filteredData,
    setModified,
    setMerchantID
}


export default connect(mapStateToProps, mapDispatchToProps)(Merchant);