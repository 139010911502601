
import React, { useState, useEffect } from 'react';
import TableView from '../Table/Table';
import { tableHeadings, userAdminHeadings, userHeadings } from '../../../utils/constants';
import { fetchAllUsers, fetchUser, filteredData, updatePassword, setModified, assignUser } from '../../../redux/actions/UserActions';
import { connect } from "react-redux";
import moment from 'moment';
import { setSnackbar } from '../../../redux/actions/SnackbarAction';
import { fetchMerchants } from '../../../redux/actions/dropdownActions';

import { Panel, PanelBody, PanelHeader } from "../../header/panel/panel"
import { useNavigate } from 'react-router';
import UserAssignments from './UserAssignments';


const ViewUser = (props) => {

    const navigate = useNavigate()


    const userHeading = props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ? userAdminHeadings : userHeadings


    const [password, setPassword] = useState('')
    const [cpassword, setCPassword] = useState('')
    const [terminalError, setTerminalError] = useState(false)
    const [merchantError, setMerchantError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [cpassError, setCPassError] = useState(false)
    const [isoError, setIsoError] = useState(false);

    const [isoValue, setIso] = useState('');
    const [merchantID, setMerchantID] = useState('')
    const [terminal, setTerminal] = useState('');

    const [terminalDropdown, setTerminalDropdown] = useState([])
    const [merchantDropdown, setMerchantDropdown] = useState([])

    useEffect(() => {
        console.log('In use effect')
        let userID = props.USERSREDUCER.userID;
        props.fetchUser(userID)
    }, [])

    useEffect(() => {
        if (props.profile) {
            props.fetchUser(props.LOGINREDUCER.USER.userID)
        }
    }, [props.profile])


    const handleUpdate = () => {
        let body = {}
        if (password) {
            body.password = password
        } else {
            setPasswordError(true)
        }

        if (cpassword) {
            body.confirmPassword = cpassword
        } else {
            setCPassError(true)
        }

        body.username = props.USERSREDUCER.userData[0].username

        checkPassword()
        if (cpassword && password && cpassword != password) {
            props.setSnackbar(true, 'error', "Passwords Doesn't Match")
        }

        if (cpassword == password && cpassword.length >= 8 && password.length >= 8 && cpassword.length == password.length) {
            props.updatePassword(body)
            setPassword('')
            setCPassword('')
        } else {
            props.setSnackbar(true, 'error', "Recheck Passwords")
        }
    }

    const handleChange = (event) => {
        if (event.target.name == 'password') {
            setPasswordError(false);
            setPassword(event.target.value)
        } else {
            setCPassError(false)
            setCPassword(event.target.value)
        }
    }


    const checkPassword = () => {
        if (password && password.length < 8) {
            props.setSnackbar(true, 'error', 'Password Should Contain Min 8 Characters')
            setPasswordError(true)
        }

        if (cpassword && cpassword.length < 8) {
            props.setSnackbar(true, 'error', 'Password Should Contain Min 8 Characters')
            setCPassError(true)
        }
    }


    const handleEdit = (event) => {
        event.preventDefault()
        navigate('/user/edit')

    }


    let PASSWORD_UPDATE_FORM = (

        <form className="fs-13px">

            <div className="mb-3">

                <div className="row gx-3">

                    <div className="col-md-8 mb-2 mb-md-0">
                        <label className={"col-md-6 " + (passwordError ? 'text-danger' : '')}><b>Password</b></label>
                        <input
                            type="password" className="form-control fs-13px"
                            name="password"
                            onChange={handleChange}
                            value={password ? password : ''}
                            error={passwordError}
                        />
                    </div>
                </div>
            </div>

            <div className="mb-3">

                <div className="row gx-6">

                    <div className="col-md-8 mb-2 mb-md-0">
                        <label className={"col-md-6 " + (cpassError ? 'text-danger' : '')}><b>Confirm Password</b></label>
                        <input
                            type="password" className="form-control fs-13px"
                            name="cpassword"
                            onChange={handleChange}
                            value={cpassword ? cpassword : ''}
                            error={cpassError}
                        />
                    </div>

                </div>
            </div>
        </form>

    )


    return (

        <div className="row mb-3 ">

            <div className="col-xl-5">

                <Panel>

                    <PanelHeader>

                        <div className="row mb-0">

                            <div className="col-xl-4">
                                User Profile
                            </div>

                            <div className="col-xl-6"></div>

                            <div className="col-xl-2">

                                {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 || props.LOGINREDUCER.USER.role == 7 ? <button className='btn btn-primary d-block w-30 btn-lg h-9px fs-11px' onClick={handleEdit}>Edit</button> : null}

                            </div>

                        </div>


                    </PanelHeader>

                    <PanelBody>

                        <div className="table-responsive">

                            <table className="table mb-0">
                                <tbody>

                                    {props.USERSREDUCER.userData && props.USERSREDUCER.userData.length ?

                                        Object.keys(userHeading).map(data => {

                                            let key = data.includes('contact') || data.includes("address") ? data.split(".") : data
                                            if (typeof (key) != 'object') {
                                                if (props.USERSREDUCER.userData[0][key] && props.USERSREDUCER.userData[0][key].toString()) {
                                                    if (data == 'userRoleId') {
                                                        return (
                                                            <tr key={data}>
                                                                <th>{userHeading[data]}</th>
                                                                <th>:</th>
                                                                <td>{props.USERSREDUCER.userData[0][key] == 1 ? 'Admin' : props.USERSREDUCER.userData[0][key] == 2 ? 'Client' : props.USERSREDUCER.userData[0][key] == 3 ? 'Agent' : props.USERSREDUCER.userData[0][key] == 4 ? 'Merchant' : props.USERSREDUCER.userData[0][key] == 5 ? 'User' : props.USERSREDUCER.userData[0][key] == 6 ? 'Tech Support' :'N/A'}</td>
                                                            </tr>
                                                        )
                                                    } else if (data == 'userStatusID') {
                                                        return (
                                                            <tr key={data}>
                                                                <th>{userHeading[data]}</th>
                                                                <th>:</th>
                                                                <td className={props.USERSREDUCER.userData[0][key] == 1 ? 'approved' : props.USERSREDUCER.userData[0][key] == 2 ? 'suspended' : props.USERSREDUCER.userData[0][key] == 3 ? 'inactive' : 'reject'}>{props.USERSREDUCER.userData[0][key] == 1 ? 'Active' : props.USERSREDUCER.userData[0][key] == 2 ? 'Suspended' : props.USERSREDUCER.userData[0][key] == 3 ? 'InAcive' : 'Deleted'}</td>
                                                            </tr>
                                                        )
                                                    } else {
                                                        return (
                                                            <tr key={data}>
                                                                <th>{userHeading[data]}</th>
                                                                <th>:</th>
                                                                <td>{key.toLowerCase().includes('date') ? moment(props.USERSREDUCER.userData[0][key]).format('MMM DD, YYYY HH:mm:ss') : props.USERSREDUCER.userData[0][key]}</td>
                                                            </tr>
                                                        )
                                                    }
                                                }
                                            } else {
                                                if (props.USERSREDUCER.userData[0][key[0]] && props.USERSREDUCER.userData[0][key[0]][key[1]]) {
                                                    return (
                                                        <tr key={data}>
                                                            <th>{userHeading[data]}</th>
                                                            <th>:</th>
                                                            <td>{props.USERSREDUCER.userData[0][key[0]][key[1]] ? props.USERSREDUCER.userData[0][key[0]][key[1]] : '-'}</td>
                                                        </tr>
                                                    )
                                                }
                                            }
                                        }) :
                                        null
                                    }

                                </tbody>
                            </table>
                        </div>

                    </PanelBody>
                </Panel>


            </div>

            <div className="col-xl-4">
                <Panel>
                    <PanelHeader>Update Password</PanelHeader>
                    <PanelBody>
                        {PASSWORD_UPDATE_FORM}
                        <div>

                            <button className='btn btn-primary d-block w-100 btn-lg h-9px fs-13px' onClick={handleUpdate}>Update Password</button>
                        </div>

                    </PanelBody>
                </Panel>
            </div>

            {!props.profile && props.USERSREDUCER.userData?.length && [3, 5, 6, 7].includes(props.USERSREDUCER.userData[0]?.userRoleId) ?

                <div className="col-xl-9">

                    <UserAssignments></UserAssignments>

                    {/* <Panel>
                        <PanelHeader>Assignments</PanelHeader>
                        <PanelBody>
                            {USER_ASSIGNMENT}
                        </PanelBody>
                    </Panel> */}
                </div> : null}

        </div>
    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchAllUsers, fetchUser, filteredData,
    setSnackbar, updatePassword, setModified, fetchMerchants, assignUser
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);