const initialState = { isosData: [], processorsData: [], terminalMakeData: [], merchantsData: [], reportTypes: [], terminalPrefix: [], processorTerminalModels: [] };

export const DROPDOWNREDUCER = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ISOS':
            return {
                ...state,
                // processorsData: state.processorsData,
                // terminalMakeData: state.terminalMakeData,
                isosData: action.data,
                // merchantsData: state.merchantsData
            }
        case 'GET_PROCESSORS':
            return {
                ...state,
                // isosData: state.isosData,
                // terminalMakeData: state.terminalMakeData,
                processorsData: action.data,
                // merchantsData: state.merchantsData
            }
        case 'GET_TERMINAL_MAKE':
            return {
                ...state,
                // isosData: state.isosData,
                // processorsData: state.processorsData,
                terminalMakeData: action.data,
                // merchantsData: state.merchantsData
            }
        case 'GET_MERCHANTS':
            return {
                ...state,
                // isosData: state.isosData,
                // processorsData: state.processorsData,
                // terminalMakeData: state.terminalMakeData,
                merchantsData: action.data
            }
        case 'GET_REPORT_TYPES':
            return {
                ...state,
                reportTypes: action.data
            }
        case 'GET_PREFIX':
            return {
                ...state,
                terminalPrefix: action.data
            }
        case 'GET_PROCESSOR_TERMINAL_MODELS':
            return {
                ...state,
                processorTerminalModels: action.data

            }
        default:
            return state;
    }
}