import React, { useEffect, useState } from 'react';
import Table from '../Table/Table';
import { tableHeadings } from '../../../utils/constants';
import { connect } from "react-redux";
import { fetchDashboardData } from '../../../redux/actions/TransactionActions';
import { Panel, PanelBody, PanelHeader } from '../../header/panel/panel';
import { Grid } from '@mui/material';


const TransactionCounts = (props) => {

    useEffect(() => {

        props.fetchDashboardData();
        
    },[])
    
    console.log(props.TRANSACTIONREDUCER.dashboardData?.feeCharged)

    return (

        <div>

            <Panel>
                <PanelHeader>

                    <Grid container >

                        <Grid item xs={4}>Month To Date Transaction Counts</Grid>

                    </Grid>

                </PanelHeader>

                <PanelBody>

                    <div className="row">

                        <div className="col-xl-2 col-md-6">
                            <div className="widget widget-stats bg-blue">
                                <div className="stats-icon"><i className="fa fa-desktop"></i></div>
                                <div className="stats-info">
                                    <h4>Total transaction</h4>
                                    <p>{props.TRANSACTIONREDUCER.dashboardData?.noOfTransactions?.toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-6">
                            <div className="widget widget-stats bg-info">
                                <div className="stats-icon"><i className="fa fa-link"></i></div>
                                <div className="stats-info">
                                    <h4>Approved</h4>
                                    <p>{props.TRANSACTIONREDUCER.dashboardData?.noOfApprovals?.toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-6">
                            <div className="widget widget-stats bg-info">
                                <div className="stats-icon"><i className="fa fa-link"></i></div>
                                <div className="stats-info">
                                    <h4>Approved Withdrawals</h4>
                                    <p>{props.TRANSACTIONREDUCER.dashboardData?.noOfWthApprovals?.toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats bg-orange">
                                <div className="stats-icon"><i className="fa fa-users"></i></div>
                                <div className="stats-info">
                                    <h4>Amount</h4>
                                    <p>$ {props.TRANSACTIONREDUCER.dashboardData?.amountAuthorized?.toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats bg-red">
                                <div className="stats-icon"><i className="fa fa-clock"></i></div>
                                <div className="stats-info">
                                    <h4>Fee</h4>
                                    <p>$ {props.TRANSACTIONREDUCER.dashboardData?.feeCharged?.toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </PanelBody>

            </Panel>
        </div>
    );

}


function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchDashboardData
}


export default connect(mapStateToProps, mapDispatchToProps)(TransactionCounts);