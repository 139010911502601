const initialState = { loading: false, uploadHistory: [], keysResult: [],  modified: false };

export const UPLOADREDUCER = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.data
            }
        case 'SET_HISTORY':
            return {
                ...state,
                uploadHistory: action.data
            }
        case 'SET_RESULT':
            return {
                ...state,
                keysResult: action.data
            }
        case 'SET_MODIFIED':
                return {
                    ...state,
                    modified: action.data
                }
        default:
            return state;
    }
}