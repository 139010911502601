import axios from 'axios';
import authAxios from '../../config/axios';
import config from '../../config/api';
import { setSnackbar } from './SnackbarAction';
import { useAuth } from '../../utils/Auth';
import { setToken } from '../../config/axios';


export const loginSuccess = (data) => ({
    type: 'LOGIN_SUCCESS',
    data
});

export const loginFailure = () => ({
    type: 'LOGIN_FAILURE',
});

export const logoutSuccess = () => ({
    type: 'LOGOUT_SUCCESS'
});

export const loginModified = (data) => ({
    type: 'LOGIN_MODIFIED',
    data
})

export const startLogin = (_body) => {
    // const auth = useAuth();

    const Body = {
        ..._body
    }
    return function (dispatch) {
        axios.post(`${config.api.BASE_URL}/security/login`, Body).then(response => {
            if (response.status === 200) {
                sessionStorage.setItem('user', JSON.stringify(response.data))
                setToken(response.data.token)
                // auth.login({})
                dispatch(loginSuccess(response.data));
                dispatch(setSnackbar(true, 'success', 'Login Successfull'))
            }
            // if (response.status === 401) {
            // dispatch(loginFailure())
            // dispatch(setSnackbar(true, 'error', 'Invalid Credentials'))
            // }
        }).catch(error => {
            dispatch(loginFailure())
            // dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            dispatch(setSnackbar(true, 'error', 'Invalid Credentials'))
        })
    }
}

export const startLogout = () => {
    return function (dispatch) {
        // const auth = useAuth()
        // auth.logout()
        dispatch(logoutSuccess())
    }
}


export const refreshToken = (body) => {

    return function (dispatch) {
        axios.post(`${config.api.BASE_URL}/security/login`, body).then(response => {
            if (response.status === 200) {
                sessionStorage.setItem('user', JSON.stringify(response.data))
                setToken(response.data.token)
                dispatch(loginSuccess(response.data));
                dispatch(setSnackbar(true, 'success', 'Login Successfull'))
            }
            // if (response.status === 401) {
            // dispatch(loginFailure())
            // dispatch(setSnackbar(true, 'error', 'Invalid Credentials'))
            // }
        }).catch(error => {
            dispatch(loginFailure())
            // dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            dispatch(setSnackbar(true, 'error', 'Invalid Credentials'))
        })
    }
}

export const loginAsUser = (body) => {

    return function (dispatch) {
        authAxios.post(`${config.api.BASE_URL}/security/loginAsUser`, body).then(response => {
            if (response.status === 200) {
                sessionStorage.setItem('user', JSON.stringify(response.data))
                dispatch(loginModified(true))
                setToken(response.data.token)
                dispatch(loginSuccess(response.data));
                dispatch(setSnackbar(true, 'success', 'Login Successfull'))
            }
        }).catch(error => {
            dispatch(loginFailure())
            dispatch(setSnackbar(true, 'error', 'Invalid Credentials/Login Error'))
            dispatch(loginModified(false))

        })
    }
}