import React, {useEffect} from 'react';
import { connect } from "react-redux";
import TransactionCounts from './TransactionCounts';
import Transactions from './Transactions';

import { fetchIsos, fetchProcessors, fetchTerminalMake, fetchReportTypes, fetchPrefix, fetchProcessorTerminalModels } from "../../../redux/actions/dropdownActions";

const Dashboard = (props) => {

	useEffect(() => {
		props.fetchIsos()
		props.fetchProcessors()
		props.fetchTerminalMake()
		props.fetchReportTypes()
		props.fetchPrefix()
		props.fetchProcessorTerminalModels()
	}, [])

    return (

        <div>
            <div className="row mb-3">
                <TransactionCounts></TransactionCounts>
            </div>

            <div className="row mb-3">

                <Transactions></Transactions>
            </div>

        </div>
    )

}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
	fetchIsos, fetchProcessors, fetchTerminalMake,
	fetchReportTypes, fetchPrefix, fetchProcessorTerminalModels
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);