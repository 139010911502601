import React from 'react';
import moment from 'moment';

export const tableHeadings = {

    realtime: [
        {
            field: 'tranTime', tooltipField: 'transTime', headerName: 'TransTime', minWidth: 180, cellRenderer: function (params) {
                return moment(params.value).format('MMM DD, YYYY HH:mm:ss');
            }
        },
        { field: 'terminalId', tooltipField: 'terminalId', headerName: 'Terminal ID', minWidth: 140 },
        {
            field: 'merchantName', tooltipField: 'merchantName', headerName: 'Merchant', minWidth: 200, cellRenderer: function (params) {
                return params.data.merchantName
            }
        },
        { field: 'tranType', tooltipField: 'tranType', headerName: 'Tran Type' },
        { field: 'seqNumber', tooltipField: 'seqNumber', headerName: 'Seq Num' },
        {
            field: 'cardNumber', tooltipField: 'cardNumber', headerName: 'PAN', cellRenderer: function (params) {
                return params.data.cardNumber.substring(params.data.cardNumber.length - 4)
            }
        },
        {
            field: 'amount', tooltipField: 'amount', headerName: 'Amount', cellRenderer: function (params) {
                return "$" + params.data.amount
            },
        },
        {
            field: 'fee', tooltipField: 'fee', headerName: 'Fee', cellRenderer: function (params) {
                return "$" + params.data.fee
            },
        },
        {
            field: 'respDesc', tooltipField: 'respDesc', headerName: 'Response', minWidth: 240, cellRenderer: function (params) {
                return <div className={params.data.respDesc == "Approved" ? 'approved' : 'reject'}>{params.data.respDesc}</div>
            }
        },
    ],
    realtimeAdmin: [
        {
            field: 'tranTime', tooltipField: 'transTime', headerName: 'TransTime', minWidth: 180, cellRenderer: function (params) {
                return moment(params.value).format('MMM DD, YYYY HH:mm:ss');
            }
        },
        { field: 'terminalId', tooltipField: 'terminalId', headerName: 'Terminal ID', minWidth: 140 },
        { field: 'processorTermID', tooltipField: 'processorTermID', headerName: 'Processor Terminal ID', minWidth: 140 },
        {
            field: 'merchantName', tooltipField: 'merchantName', headerName: 'Merchant', minWidth: 200, cellRenderer: function (params) {
                return params.data.merchantName
            }
        },
        { field: 'tranType', tooltipField: 'tranType', headerName: 'Tran Type' },
        { field: 'seqNumber', tooltipField: 'seqNumber', headerName: 'Seq Num' },
        {
            field: 'cardNumber', tooltipField: 'cardNumber', headerName: 'PAN', cellRenderer: function (params) {
                return params.data.cardNumber.substring(params.data.cardNumber.length - 4)
            }
        },
        {
            field: 'amount', tooltipField: 'amount', headerName: 'Amount', cellRenderer: function (params) {
                return "$" + params.data.amount
            },
        },
        {
            field: 'fee', tooltipField: 'fee', headerName: 'Fee', cellRenderer: function (params) {
                return "$" + params.data.fee
            },
        },
        {
            field: 'respDesc', tooltipField: 'respDesc', headerName: 'Response', minWidth: 240, cellRenderer: function (params) {
                return <div className={params.data.respDesc == "Approved" ? 'approved' : 'reject'}>{params.data.respDesc}</div>
            }
        },
    ],

    terminalTransactions: [
        {
            field: 'tranTime', tooltipField: 'transTime', headerName: 'TransTime', minWidth: 200, cellRenderer: function (params) {
                return moment(params.value).format('MMM DD, YYYY HH:mm:ss');
            }
        },
        { field: 'tranType', tooltipField: 'tranType', headerName: 'Type', maxWidth: 140 },
        { field: 'seqNumber', tooltipField: 'seqNumber', headerName: 'Seq#', maxWidth: 140 },
        {
            field: 'cardNumber', tooltipField: 'cardNumber', headerName: 'PAN', maxWidth: 140, cellRenderer: function (params) {
                return params.data.cardNumber.substring(params.data.cardNumber.length - 4)
            }
        },
        {
            field: 'amount', tooltipField: 'amount', headerName: 'Amt', minWidth: 80, maxWidth: 120, cellRenderer: function (params) {
                return "$" + params.data.amount
            },
        },
        {
            field: 'fee', tooltipField: 'fee', headerName: 'Fee', minWidth: 80, maxWidth: 120, cellRenderer: function (params) {
                return "$" + params.data.fee
            },
        },
        {
            field: 'respDesc', tooltipField: 'respDesc', headerName: 'Response', minWidth: 180, maxWidth: 350, cellRenderer: function (params) {
                return <div className={params.data.respDesc == "Approved" ? 'approved' : 'reject'}>{params.data.respDesc}</div>
            }
        },
    ],


    terminalBatchTotals: [
        { field: 'processorTermID', tooltipField: 'processorTermID', headerName: 'Processor TID', minWidth: 140 },
        {
            field: 'createdDate', tooltipField: 'createdDate', headerName: 'Time', minWidth: 180, maxWidth: 180, cellRenderer: function (params) {
                return moment(params.value).format('MMM DD, YYYY HH:mm:ss');
            }
        },

        { field: 'hostWthCount', tooltipField: 'hostWthCount', headerName: 'WTH', minWidth: 100, maxWidth: 100 },
        { field: 'hostInqCount', tooltipField: 'hostInqCount', headerName: 'INQ', minWidth: 100, maxWidth: 100, },
        {
            field: 'hostDispAmt', tooltipField: 'hostDispAmt', headerName: 'Amt', minWidth: 100, maxWidth: 100, cellRenderer: function (params) {
                return params.value ? '$' + params.value / 100 : '$ 0.00';
            }
        },
        {
            field: 'settle', tooltipField: 'settle', headerName: 'settle', cellRenderer: function (params) {
                return params.value == true ? 'Yes' : 'No';

            }

        },
        {
            field: 'initiationType', tooltipField: 'initiationType', headerName: 'Initiation Type', minWidth: 130, cellRenderer: function (params) {
                return params.value == 1 ? 'Terminal' : params.value == 2 ? 'User' : params.value == 3 ? 'Automatic' : 'Unknown';
            }
        },
        {
            field: 'initiatedBy', tooltipField: 'initiatedBy', headerName: 'Initiated By', minWidth: 140, cellRenderer: function (params) {
                return params.value != 'null' && params.value ? params.value : 'N/A'
            }
        },

    ],

    users: [
        { field: 'username', tooltipField: 'username', headerName: 'Username', minWidth: 200 },
        { field: 'firstName', tooltipField: 'firstname', headerName: 'First Name', minWidth: 200 },
        { field: 'lastName', tooltipField: 'lastname', headerName: 'Last Name', minWidth: 200 },
        {
            field: 'userRoleId', tooltipField: 'userRoleId', headerName: 'Role', minWidth: 200, cellRenderer: function (params) {
                return params.value == 1 ? 'Admin' : params.value == 2 ? 'Client' : params.value == 3 ? 'Agent' : params.value == 4 ? 'Merchant' : params.value == 5 ? 'user' : params.value == 6 ? 'Tech Support' : params.value == 7 ? 'Super ISO' : 'N/A';
            }
        },
        {
            field: 'lastLogin', tooltipField: 'lastLogin', headerName: 'Last Login', minWidth: 200, cellRenderer: function (params) {
                return moment(params.value).format('MMM DD, YYYY HH:mm:ss');
            }
        },
        {
            field: 'userStatusID', tooltipField: 'userStatusID', headerName: 'User Status', minWidth: 200, cellRenderer: function (params) {
                return <div className={params.data.userStatusID == 1 ? 'approved' : params.data.userStatusID == 2 ? 'suspended' : params.data.userStatusID == 3 ? 'inactive' : params.data.userStatusID == 4 ? 'deleted' : params.data.userStatusID == 5 ? 'locked' : 'reject'}>{params.data.userStatusID == 1 ? 'Active' : params.data.userStatusID == 2 ? 'Suspended' : params.data.userStatusID == 3 ? 'Inactive' : params.data.userStatusID == 4 ? 'Deleted' : params.data.userStatusID == 5 ? 'Locked' : 'reject'}</div>

            }
        },
    ],
    terminals: [
        {
            field: 'terminalId', tooltipField: 'terminalId', headerName: 'Term ID', minWidth: 120, cellRenderer: function (params) {
                return params.value
            }
        },
        { field: 'locationName', tooltipField: 'locationName', headerName: 'Merchant', minWidth: 200, },

        {
            field: 'terminalStatus', tooltipField: 'terminalStatus', headerName: 'Status', minWidth: 120, cellRenderer: function (params) {
                return <div className={params.data.terminalStatus == 1 ? 'approved' : params.data.terminalStatus == 2 ? 'suspended' : params.data.terminalStatus == 3 ? 'inactive' : 'reject'}>{params.data.terminalStatus == 1 ? 'Active' : params.data.terminalStatus == 2 ? 'Suspended' : params.data.terminalStatus == 3 ? 'InActive' : 'Deleted'}</div>
            }
        },
        {
            field: 'lastTranDate', tooltipField: 'lastTranDate', headerName: 'Last Transaction', minWidth: 240, cellRenderer: function (params) {
                return params.value ? moment(params.value).format('MMM DD, YYYY HH:mm:ss') : "N/A";
            }
        },
    ],

    adminTerminals: [
        {
            field: 'terminalId', tooltipField: 'terminalId', headerName: 'Term ID', minWidth: 120, cellRenderer: function (params) {
                return params.value
            }
        },
        { field: 'processorTermID', tooltipField: 'processorTermID', headerName: 'Processor TID', minWidth: 180 },

        {
            field: 'serialNumber', tooltipField: 'serialNumber', headerName: 'Serial Number', minWidth: 200, cellRenderer: function (params) {
                return params.value ? params.value : 'N/A'
            }
        },
        { field: 'locationName', tooltipField: 'locationName', headerName: 'Merchant', minWidth: 200, },
        { field: 'isoName', tooltipField: 'isoName', headerName: 'ISO', minWidth: 200, },
        {
            field: 'terminalStatus', tooltipField: 'terminalStatus', headerName: 'Status', minWidth: 120, cellRenderer: function (params) {
                return <div className={params.data.terminalStatus == 1 ? 'approved' : params.data.terminalStatus == 2 ? 'suspended' : params.data.terminalStatus == 3 ? 'inactive' : 'reject'}>{params.data.terminalStatus == 1 ? 'Active' : params.data.terminalStatus == 2 ? 'Suspended' : params.data.terminalStatus == 3 ? 'InActive' : 'Deleted'}</div>
            }
        },
        {
            field: 'lastTranDate', tooltipField: 'lastTranDate', headerName: 'Last Transaction', minWidth: 240, cellRenderer: function (params) {
                return params.value ? moment(params.value).format('MMM DD, YYYY HH:mm:ss') : "N/A";
            }
        },
    ],

  
    adminTerminalsSubList: [
        {
            field: 'terminalId', tooltipField: 'terminalId', headerName: 'Terminal ID', minWidth: 200, cellRenderer: function (params) {
                return <div>{params.value}</div>
            }
        },
        { field: 'processorTermID', tooltipField: 'processorTermID', headerName: 'Processor TID', minWidth: 180 },

        {
            field: 'serialNumber', tooltipField: 'serialNumber', headerName: 'Serial Number', minWidth: 200, cellRenderer: function (params) {
                return params.value ? params.value : 'N/A'
            }
        },
        {
            field: 'terminalStatus', tooltipField: 'terminalStatus', headerName: 'Status', minWidth: 140, cellRenderer: function (params) {
                return <div className={params.data.terminalStatus == 1 ? 'approved' : params.data.terminalStatus == 2 ? 'suspended' : params.data.terminalStatus == 3 ? 'inactive' : 'reject'}>{params.data.terminalStatus == 1 ? 'Active' : params.data.terminalStatus == 2 ? 'Suspended' : params.data.terminalStatus == 3 ? 'InActive' : 'Deleted'}</div>
            }
        },
        {
            field: 'lastTranDate', tooltipField: 'lastTranDate', headerName: 'Last Transaction', minWidth: 240, cellRenderer: function (params) {
                return params.value ? moment(params.value).format('MMM DD, YYYY HH:mm:ss') : "N/A";
            }
        },
    ],

    terminalsSubList: [
        {
            field: 'terminalId', tooltipField: 'terminalId', headerName: 'Terminal ID', minWidth: 200, cellRenderer: function (params) {
                return <div>{params.value}</div>
            }
        },
        {
            field: 'terminalStatus', tooltipField: 'terminalStatus', headerName: 'Status', minWidth: 140, cellRenderer: function (params) {
                return <div className={params.data.terminalStatus == 1 ? 'approved' : params.data.terminalStatus == 2 ? 'suspended' : params.data.terminalStatus == 3 ? 'inactive' : 'reject'}>{params.data.terminalStatus == 1 ? 'Active' : params.data.terminalStatus == 2 ? 'Suspended' : params.data.terminalStatus == 3 ? 'InActive' : 'Deleted'}</div>
            }
        },
        {
            field: 'lastTranDate', tooltipField: 'lastTranDate', headerName: 'Last Transaction', minWidth: 240, cellRenderer: function (params) {
                return params.value ? moment(params.value).format('MMM DD, YYYY HH:mm:ss') : "N/A";
            }
        },
    ],

    iso: [
        {
            field: 'isoName', tooltipField: 'isoName', headerName: 'ISO', minWidth: 200, cellRenderer: function (params) {
                return <div>{params.data.isoName}</div>
            }
        },
        {
            field: 'createDate', tooltipField: 'createdDate', headerName: 'Created Date', minWidth: 240, cellRenderer: function (params) {
                return moment(params.value).format('MMM DD, YYYY HH:mm:ss');
            }
        },
        {
            field: 'status', tooltipField: 'status', headerName: 'Status', minWidth: 200, cellRenderer: function (params) {
                return <div className={params.data.isoStatus == 1 ? 'approved' : params.data.isoStatus == 2 ? 'suspended' : params.data.isoStatus == 3 ? 'inactive' : 'reject'}>{params.data.isoStatus == 1 ? 'Active' : params.data.isoStatus == 2 ? 'Suspended' : params.data.isoStatus == 3 ? 'InActive' : 'Deleted'}</div>
            }
        },
    ],

    isoDetails: [
        {
            field: 'isoName', tooltipField: 'isoName', headerName: 'ISO', minWidth: 200, cellRenderer: function (params) {
                return <div>{params.data.isoName}</div>
            }
        },
        {
            field: 'mainCity', tooltipField: 'mainCity', headerName: 'City', minWidth: 200, cellRenderer: function (params) {
                return params.data.address.city
            }
        },
        {
            field: 'mainState', tooltipField: 'mainState', headerName: 'State', minWidth: 200, cellRenderer: function (params) {
                return params.data.address.state
            }
        },
        {
            field: 'contact', tooltipField: 'contact', headerName: 'Contact', minWidth: 200, cellRenderer: function (params) {
                return params.data.contact.phone
            }
        },
        {
            field: 'status', tooltipField: 'status', headerName: 'Status', minWidth: 200, cellRenderer: function (params) {
                return params.data.isoStatus
            }
        },
    ],

    merchants: [
        {
            field: 'merchantName', tooltipField: 'merchantName', headerName: 'Merchant', minWidth: 200, cellRenderer: function (params) {
                return params.data.merchantName
            }
        },
        {
            field: 'isoName', tooltipField: 'isoName', headerName: 'ISO', minWidth: 200, cellRenderer: function (params) {
                return <div>{params.data.isoName}</div>
            }
        },

        {
            field: 'merchantStatusID', tooltipField: 'merchantStatusID', headerName: 'Merchant Status', minWidth: 200, cellRenderer: function (params) {
                return <div className={params.data.merchantStatusID == 1 ? 'approved' : params.data.merchantStatusID == 2 ? 'suspended' : params.data.merchantStatusID == 3 ? 'inactive' : 'reject'}>{params.data.merchantStatusID == 1 ? 'Active' : params.data.merchantStatusID == 2 ? 'Suspended' : params.data.merchantStatusID == 3 ? 'InActive' : 'Deleted'}</div>

            }
        },
    ],

    merchantsSubList: [
        {
            field: 'merchantName', tooltipField: 'merchantName', headerName: 'Merchant', minWidth: 200, cellRenderer: function (params) {
                return params.data.merchantName
            }
        },

        {
            field: 'merchantStatusID', tooltipField: 'merchantStatusID', headerName: 'Merchant Status ID', minWidth: 200, cellRenderer: function (params) {
                return <div className={params.data.merchantStatusID == 1 ? 'approved' : params.data.merchantStatusID == 2 ? 'suspended' : params.data.merchantStatusID == 3 ? 'inactive' : 'reject'}>{params.data.merchantStatusID == 1 ? 'Active' : params.data.merchantStatusID == 2 ? 'Suspended' : params.data.merchantStatusID == 3 ? 'InActive' : 'Deleted'}</div>

            }
        },
    ],

    keys: [
        {
            field: 'keyId', tooltipField: 'keyId', headerName: 'Terminal ID', minWidth: 140, cellRenderer: function (params) {
                return params.data.keyId ? params.data.keyId : 'N/A'
            }
        },
        {
            field: 'terminalPresent', tooltipField: 'terminalPresent', headerName: 'Terminal Present', minWidth: 180, cellRenderer: function (params) {
                return params.data.terminalPresent ? params.data.terminalPresent : 'N/A'
            }
        },
        {
            field: 'status', tooltipField: 'status', headerName: 'Key Status', minWidth: 130, cellRenderer: function (params) {
                return params.data.status ? <div className={params.data.status == "Successful" ? 'approved' : 'reject'}>{params.data.status}</div> : 'N/A'
            }
        },
        {
            field: 'partOneCheckDigit', tooltipField: 'partOneCheckDigit', headerName: 'Part One', minWidth: 110, cellRenderer: function (params) {
                return params.data.partOneCheckDigit ? <div className={params.data.partOneCheckDigit == "Match" ? 'approved' : 'reject'}>{params.data.partOneCheckDigit}</div> : 'N/A'
            }
        },
        {
            field: 'partTwoCheckDigit', tooltipField: 'partTwoCheckDigit', headerName: 'Part Two', minWidth: 110, cellRenderer: function (params) {
                return params.data.partTwoCheckDigit ? <div className={params.data.partTwoCheckDigit == "Match" ? 'approved' : 'reject'}>{params.data.partTwoCheckDigit}</div> : 'N/A'
            }
        },
        {
            field: 'combinedCheckDigits', tooltipField: 'combinedCheckDigits', headerName: 'Check Digit', minWidth: 130, cellRenderer: function (params) {
                return params.data.combinedCheckDigits ? params.data.combinedCheckDigits : 'N/A'
            }
        },

        {
            field: 'keyPresent', tooltipField: 'keyPresent', headerName: 'Key Present', minWidth: 140, cellRenderer: function (params) {
                return params.data.keyPresent ? params.data.keyPresent : 'N/A'
            }
        },
        {
            field: 'keyBound', tooltipField: 'keyBound', headerName: 'Key Bound', minWidth: 140, cellRenderer: function (params) {
                return params.data.keyBound ? params.data.keyBound : 'N/A'
            }
        },
        {
            field: 'keyOverriden', tooltipField: 'keyOverriden', headerName: 'Key Changed', minWidth: 140, cellRenderer: function (params) {
                return params.data.keyOverriden ? params.data.keyOverriden : 'N/A'
            }
        },
        {
            field: 'message', tooltipField: 'message', headerName: 'Message', minWidth: 120, cellRenderer: function (params) {
                return params.data.message && params.data.message.trim() ? params.data.message : 'N/A'
            }
        },
    ],

    uploadTransactions: [
        {
            field: 'importDate', tooltipField: 'importDate', headerName: 'Import Date', minWidth: 200, cellRenderer: function (params) {
                return params.value ? moment(params.value).format('MMM DD, YYYY HH:mm:ss') : "N/A";
            }
        },

        {
            field: 'importFileType', tooltipField: 'importFileType', headerName: 'File Type', minWidth: 150, cellRenderer: function (params) {
                return params.data.importFileType == 1 ? "Transactions" : "Keys";
            }
        },
        {
            field: 'fileName', tooltipField: 'fileName', headerName: 'File Name', minWidth: 150, cellRenderer: function (params) {
                return params.data.fileName;
            }
        },
        {
            field: 'pocessorID', tooltipField: 'pocessorID', headerName: 'Processor', minWidth: 150, cellRenderer: function (params) {
                return params.value == 1 ? 'PAI' : params.value == 2 ? 'CDS' : params.value == 3 ? 'Switch' : params.value == 4 ? 'Connected' : params.value == 5 ? 'CORD' : params.value == 6 ? 'DNS' : 'N/A';
            }
        },
        {
            field: 'numberOfRecords', tooltipField: 'numberOfRecords', headerName: 'Records', minWidth: 120, cellRenderer: function (params) {
                return params.data.numberOfRecords
            }
        },

        {
            field: 'importStatus', tooltipField: 'importStatus', headerName: 'Status', minWidth: 120, cellRenderer: function (params) {
                return <div className={params.data.importStatus == "1" ? 'approved' : 'reject'}>{params.data.importStatus == 1 ? "Success" : "Failed"}</div>;
            }
        },

        {
            field: 'importBy', tooltipField: 'importBy', headerName: 'Import By', minWidth: 140, cellRenderer: function (params) {
                return params.data.importBy
            }
        },

    ],

    summaryReportAdmin : [

        {
            field: 'terminalID', tooltipField: 'terminalID', headerName: 'Terminal ID', minWidth: 140, cellRenderer: function (params) {
                return params.data.terminalID
            }
        },
        {
            field: 'processorTermID', tooltipField: 'processorTermID', headerName: 'Processor TID', minWidth: 160, cellRenderer: function (params) {
                return params.data.processorTermID
            }
        },
        {
            field: 'merchantName', tooltipField: 'merchantName', headerName: 'Merchant', minWidth: 110, cellRenderer: function (params) {
                return params.data.merchantName
            }
        },        {
            field: 'transactionDate', tooltipField: 'transactionDate', headerName: 'Date', minWidth: 140, cellRenderer: function (params) {
                return params.value ? moment(params.value).format('MM-DD-YYYY') : "N/A";
            }
        },
        {
            field: 'totalTransactions', tooltipField: 'totalTransactions', headerName: 'Total Transactions', minWidth: 180, cellRenderer: function (params) {
                return params.data.totalTransactions
            }
        },
        {
            field: 'numOfApprovals', tooltipField: 'numOfApprovals', headerName: 'Approved', minWidth: 120, cellRenderer: function (params) {
                return params.data.numOfApprovals
            }
        },
        {
            field: 'numOfWthApprovals', tooltipField: 'numOfWthApprovals', headerName: 'Approved Withdrawals', minWidth: 200, cellRenderer: function (params) {
                return params.data.numOfWthApprovals
            }
        },
        {
            field: 'numOfDeclines', tooltipField: 'numOfDeclines', headerName: 'Declines', minWidth: 120, cellRenderer: function (params) {
                return params.data.numOfDeclines
            }
        },
        {
            field: 'amount', tooltipField: 'amount', headerName: 'Amount', minWidth: 120, cellRenderer: function (params) {
                return "$" + params.data.amount
            },
        },
        {
            field: 'fee', tooltipField: 'fee', headerName: 'Fee', minWidth: 100, cellRenderer: function (params) {
                return "$" + params.data.fee
            },
        },
        {
            field: 'bankStmtDescription', tooltipField: 'bankStmtDescription', headerName: 'Statement Descriptor', minWidth: 100, cellRenderer: function (params) {
                return params.data.bankStmtDescription
            },
        },
    ],


    summaryReportClient: [
        {
            field: 'terminalID', tooltipField: 'terminalID', headerName: 'Terminal ID', minWidth: 140, cellRenderer: function (params) {
                return params.data.terminalID
            }
        },
        {
            field: 'merchantName', tooltipField: 'merchantName', headerName: 'Merchant', minWidth: 110, cellRenderer: function (params) {
                return params.data.merchantName
            }
        },
        {
            field: 'transactionDate', tooltipField: 'transactionDate', headerName: 'Date', minWidth: 140, cellRenderer: function (params) {
                return params.value ? moment(params.value).format('MM-DD-YYYY') : "N/A";
            }
        },
        {
            field: 'totalTransactions', tooltipField: 'totalTransactions', headerName: 'Total Transactions', minWidth: 180, cellRenderer: function (params) {
                return params.data.totalTransactions
            }
        },
        {
            field: 'numOfApprovals', tooltipField: 'numOfApprovals', headerName: 'Approved', minWidth: 120, cellRenderer: function (params) {
                return params.data.numOfApprovals
            }
        },
        {
            field: 'numOfWthApprovals', tooltipField: 'numOfWthApprovals', headerName: 'Approved Withdrawals', minWidth: 200, cellRenderer: function (params) {
                return params.data.numOfWthApprovals
            }
        },
        {
            field: 'numOfDeclines', tooltipField: 'numOfDeclines', headerName: 'Declines', minWidth: 120, cellRenderer: function (params) {
                return params.data.numOfDeclines
            }
        },
        {
            field: 'amount', tooltipField: 'amount', headerName: 'Amount', minWidth: 120, cellRenderer: function (params) {
                return "$" + params.data.amount
            },
        },
        {
            field: 'fee', tooltipField: 'fee', headerName: 'Fee', minWidth: 100, cellRenderer: function (params) {
                return "$" + params.data.fee
            },
        },
        {
            field: 'bankStmtDescription', tooltipField: 'bankStmtDescription', headerName: 'Statement Descriptor', minWidth: 100, cellRenderer: function (params) {
                return params.data.bankStmtDescription
            },
        },

    ],

    detailedReportAdmin: [
        {
            field: 'terminalID', tooltipField: 'terminalID', headerName: 'Term ID', minWidth: 110, cellRenderer: function (params) {
                return params.data.terminalID
            }
        },
        {
            field: 'processorTermID', tooltipField: 'processorTermID', headerName: 'Processor TID', minWidth: 110, cellRenderer: function (params) {
                return params.data.processorTermID
            }
        },
        {
            field: 'merchantName', tooltipField: 'merchantName', headerName: 'Merchant', minWidth: 110, cellRenderer: function (params) {
                return params.data.merchantName
            }
        },
        {
            field: 'tranTime', tooltipField: 'tranTime', headerName: 'Tran Time', minWidth: 170, cellRenderer: function (params) {
                return params.value ? params.value : "N/A";

            }
        },
        {
            field: 'tranDate', tooltipField: 'tranDate', headerName: 'Tran Date', minWidth: 135, cellRenderer: function (params) {
                return params.value ? params.value : "N/A";
            }
        },

        {
            field: 'businessDate', tooltipField: 'businessDate', headerName: 'Settled Date', minWidth: 135, cellRenderer: function (params) {
                return params.value ? params.value : "N/A";
            }
        },

        {
            field: 'seqNumber', tooltipField: 'seqNumber', headerName: 'Seq#', minWidth: 90, cellRenderer: function (params) {
                return params.data.seqNumber
            }
        },
        {
            field: 'tranType', tooltipField: 'tranType', headerName: 'Type', minWidth: 90, cellRenderer: function (params) {
                return params.data.tranType
            }
        },

        {
            field: 'pan', tooltipField: 'pan', headerName: 'Pan', minWidth: 100, cellRenderer: function (params) {
                return params.data.pan
            }
        },
        {
            field: 'amount', tooltipField: 'amount', headerName: 'Amount', minWidth: 120, cellRenderer: function (params) {
                return "$" + params.data.amount
            },
        },
        {
            field: 'fee', tooltipField: 'fee', headerName: 'Fee', minWidth: 80, cellRenderer: function (params) {
                return "$" + params.data.fee
            },
        },
        {
            field: 'tranResponse', tooltipField: 'tranResponse', headerName: 'Response', minWidth: 120, cellRenderer: function (params) {
                return params.data.tranResponse == 'A' ? 'Approved' : params.data.tranResponse == 'D' ? 'Declined' : params.data.tranResponse == 'R' ? 'Reversed' : 'N/A';
            }
        },
        {
            field: 'bankStmtDescription', tooltipField: 'bankStmtDescription', headerName: 'Statement Descriptor', minWidth: 100, cellRenderer: function (params) {
                return params.data.bankStmtDescription
            },
        },
    ],

    detailedReportClient: [
        {
            field: 'terminalID', tooltipField: 'terminalID', headerName: 'Term ID', minWidth: 110, cellRenderer: function (params) {
                return params.data.terminalID
            }
        },
        {
            field: 'merchantName', tooltipField: 'merchantName', headerName: 'Merchant', minWidth: 110, cellRenderer: function (params) {
                return params.data.merchantName
            }
        },
        {
            field: 'tranTime', tooltipField: 'tranTime', headerName: 'Tran Time', minWidth: 170, cellRenderer: function (params) {
                return params.value ? moment(params.value).format('MMM DD, YYYY HH:mm:ss') : "N/A";
            }
        },
        {
            field: 'tranDate', tooltipField: 'tranDate', headerName: 'Tran Date', minWidth: 135, cellRenderer: function (params) {
                return params.value ? params.value : "N/A";
            }
        },        {
            field: 'businessDate', tooltipField: 'businessDate', headerName: 'Settled Date', minWidth: 135, cellRenderer: function (params) {
                return params.value ? moment(params.value).format('MMM DD, YYYY') : "N/A";
            }
        },

        {
            field: 'seqNumber', tooltipField: 'seqNumber', headerName: 'Seq#', minWidth: 90, cellRenderer: function (params) {
                return params.data.seqNumber
            }
        },
        {
            field: 'tranType', tooltipField: 'tranType', headerName: 'Type', minWidth: 90, cellRenderer: function (params) {
                return params.data.tranType
            }
        },

        {
            field: 'pan', tooltipField: 'pan', headerName: 'Pan', minWidth: 100, cellRenderer: function (params) {
                return params.data.pan
            }
        },
        {
            field: 'amount', tooltipField: 'amount', headerName: 'Amount', minWidth: 120, cellRenderer: function (params) {
                return "$" + params.data.amount
            },
        },
        {
            field: 'fee', tooltipField: 'fee', headerName: 'Fee', minWidth: 80, cellRenderer: function (params) {
                return "$" + params.data.fee
            },
        },
        {
            field: 'tranResponse', tooltipField: 'tranResponse', headerName: 'Response', minWidth: 120, cellRenderer: function (params) {
                return params.data.tranResponse == 'A' ? 'Approved' : params.data.tranResponse == 'D' ? 'Declined' : params.data.tranResponse == 'R' ? 'Reversed' : 'N/A';
            }
        },
        {
            field: 'bankStmtDescription', tooltipField: 'bankStmtDescription', headerName: 'Statement Descriptor', minWidth: 100, cellRenderer: function (params) {
                return params.data.bankStmtDescription
            },
        },
    ],

   
}

export const tabItems = {
    1: {
        list: [
            {
                title: 'Dashboard',
                route: "/dashboard"
            },
            {
                title: 'View',
                route: "/list"
            },
            {
                title: 'Add',
                route: "/add"
            },
            {
                title: "Report",
                route: '/reports'
            },
            {
                title: "Import",
                route: '/import'
            }
        ]
    },
    2: {
        list: [
            {
                title: 'Dashboard',
                route: "/dashboard"
            },
            {
                title: 'View',
                route: "/list"
            },
            {
                title: "Report",
                route: '/reports'
            }
        ]
    },
    3: {
        list: [
            {
                title: 'Dashboard',
                route: "/dashboard"
            },
            {
                title: 'View',
                route: "/list"
            },
            {
                title: "Report",
                route: '/reports'
            }
        ]
    },
    4: {
        list: [
            {
                title: 'Dashboard',
                route: "/dashboard"
            },
            {
                title: 'View',
                route: "/list"
            },
            {
                title: "Report",
                route: '/reports'
            }
        ]
    },
    5: {
        list: [
            {
                title: 'Dashboard',
                route: "/dashboard"
            },
            {
                title: 'View',
                route: "/list"
            },
            {
                title: "Report",
                route: '/reports'
            }
        ]
    }

}

export const subItems = {
    1: {
        'View': [
            {
                title: 'ISO',
                route: "/list/isos"
            },
            {
                title: 'Merchants',
                route: "/list/merchants"
            },
            {
                title: 'Terminals',
                route: "/list/terminals"
            },
            {
                title: 'Users',
                route: "/list/users"
            }
        ],
        'Add': [
            {
                title: 'ISO',
                route: "/add/iso"
            },
            {
                title: 'Merchants',
                route: "/add/merchant"
            },
            {
                title: 'Terminals',
                route: "/add/terminal"
            },
            {
                title: 'Users',
                route: "/add/user"
            }
        ]
    },
    2: {
        'View': [
            {
                title: 'Merchants',
                route: "/list/merchants"
            },
            {
                title: 'Terminals',
                route: "/list/terminals"
            }
        ],

    },
    3: {
        'View': [
            {
                title: 'Merchants',
                route: "/list/merchants"
            },
            {
                title: 'Terminals',
                route: "/list/terminals"
            }
        ],

    },
    4: {

        'View': [
            {
                title: 'Terminals',
                route: "/list/terminals"
            }
        ]
    },
    5: {

        'View': [

            {
                title: 'Terminals',
                route: "/list/terminals"
            }
        ]
    },
}

export const isoAdminHeadings = {
    'isoName': 'ISO',
    'isoStatus': 'Status',
    "contact.name": 'Contact Name',
    'contact.phone': 'Contact Phone',
    'contact.email': 'Contact Email',
    'contact.website': 'Website',
    'address.street': 'Street',
    'address.city': 'City',
    'address.state': "State",
    'address.postalCode': "Postal Code",
    'createDate': 'Created Date',
    'lastUpdated': 'Last Updated'

}

export const isoHeadings = {
    'isoName': 'ISO',
    'isoStatus': 'Status',
    // "contact.name": 'Contact Name',
    // 'contact.phone': 'Contact Phone',
    // 'contact.email': 'Contact Email',
    // 'contact.website': 'Website',
    // 'address.street': 'Street',
    // 'address.city': 'City',
    // 'address.state': "State",
    // 'address.postalCode': "Postal Code",

}

export const merchantAdminHeadings = {
    'merchantName': 'Merchant',
    'merchantStatusID': 'Status',
    "contact.name": 'Contact Name',
    'contact.phone': 'Contact Phone',
    'contact.email': 'Contact Email',
    'contact.website': 'Website',
    'address.street': 'Street',
    'address.city': 'City',
    'address.state': "State",
    'address.postalCode': "Postal Code",
    'createDate': 'Created Date',
    'createDate': 'Create Date',
    'createdBy': 'Created By',
    'lastUpdated': 'Last Updated',
    'lastUpdatedBy': 'Updated By'
}


export const merchantHeadings = {
    'merchantName': 'Merchant',
    // "contact.name": 'Contact Name',
    // 'contact.phone': 'Contact Phone',
    // 'contact.email': 'Contact Email',
    // 'contact.website': 'Website',
    // 'address.street': 'Street',
    // 'address.city': 'City',
    // 'address.state': "State",
    // 'address.postalCode': "Postal Code",
}

export const userHeadings = {
    'username': 'Username',
    'firstName': 'First Name',
    'lastName': 'Last Name',
    'userStatusID': 'Status',
    'userRoleId': 'User Role',
    'locked': 'Is Locked',

}

export const userAdminHeadings = {
    'username': 'Username',
    'firstName': 'First Name',
    'lastName': 'Last Name',
    'userStatusID': 'Status',
    'userRoleId': 'User Role',
    'locked': 'Is Locked',
    'createDate': 'Create Date',
    // 'createBy' : 'Created By',
    'lastUpdated': 'Last Updated',
    // 'lastUpdatedBy' : 'Updated By',

}


export const terminalAdminHeadings = {
    'terminalID': 'Terminal ID',
    'processorTermID': 'Processor Terminal ID',
    'serialNumber': 'Serial Number',
    'processorID': 'Processor',
    'isoName': 'ISO',
    'merchantName': 'Merchant',
    'bankStmtDescription' : 'Statement Descriptor',
    'timeZone' : 'Timezone',
    'fee': 'Fee',
    'feeOnProcessor': 'Fee On Processor',
    'maxTranAmount': 'Max Allowable Amount',
    'modelID': 'Model Name',
    'creditBlock': 'Credit Block',
    'autoBatch': 'Auto Batch',
    'autoBatchTime': 'Auto Batch Time',
    'checkDigit': 'Check Digit',
    'processorTermModel': 'Processor Terminal Model',
    'softwareVersion': 'Software Version',
    'osVersion': 'OS Version',
    'eprom': 'EPROM',
    'resetKey': 'Reset Key',
    'createDate': 'Create Date',
    'createdBy': 'Created By',
    'lastUpdated': 'Last Updated',
    'lastUpdatedBy': 'Updated By',
    'terminalStatusID': 'Terminal Status',
}

export const terminalSuperISOHeadings = {
    'terminalID': 'Terminal ID',
    'terminalStatusID': 'Terminal Status',
    'isoName': 'ISO',
    'merchantName': 'Merchant',
    'fee': 'Fee',
    'maxTranAmount': 'Max Allowable Amount',
    'modelID': 'Model Name',
}

export const terminalHeadings = {
    'terminalID': 'Terminal ID',
    'terminalStatusID': 'Terminal Status',
    'merchantName': 'Merchant',
    'modelID': 'Model Name',
}

export const processorDetails = {

    'processorTermID': 'Processor Terminal ID',
    'processorID': 'Processor Name',
    'checkDigit': 'Check Digit',
    'resetKey': 'Reset Key',
    'createDate': 'Create Date',
    'createdBy': 'Created By',
    'lastUpdated': 'Last Updated',
    'lastUpdatedBy': 'Updated By',
    'terminalStatusID': 'Terminal Status',

}

export const ADMIN_ROLES = [
    {
        'id': 1,
        'role': 'Admin'
    },
    {
        'id': 2,
        'role': 'Client'
    },
    {
        'id': 3,
        'role': 'Agent'
    },
    {
        'id': 4,
        'role': 'Merchant'
    },
    {
        'id': 5,
        'role': 'User'
    },
    {
        'id': 6,
        'role': 'Tech Support',
    }, {
        'id': 7,
        'role': 'Super ISO'
    }
]

export const ROLES = [
    {
        'id': 2,
        'role': 'Client'
    },
    {
        'id': 3,
        'role': 'Agent'
    },
    {
        'id': 4,
        'role': 'Merchant'
    },
    {
        'id': 5,
        'role': 'User'
    },
]

export const LOGIN_AS_ROLES = [
    {
        'id': 2,
        'role': 'Client'
    },
    {
        'id': 3,
        'role': 'Agent'
    },
    {
        'id': 4,
        'role': 'Merchant'
    },
    {
        'id': 5,
        'role': 'User'
    },
]

export const STATUS = [
    {
        'id': 1,
        'status': 'Active'
    },
    {
        'id': 2,
        'status': 'Suspended'
    },
    {
        'id': 3,
        'status': 'InActive'
    },
    {
        'id': 4,
        'status': 'Deleted'
    },
]

export const CREDITBLOCK = [
    {
        'id': "true",
        'status': "Yes"
    },
    {
        'id': "false",
        'status': "No"
    }
]

export const BUSINSESS = [
    {
        'id': "true",
        'status': "Business Day"
    },
    {
        'id': "false",
        'status': "Calendar Day"
    },
    {
        'id': '3',
        'status': 'Unreconciled'
    }
]

export const STATES = [
    { id: 'AK', value: 'Alaska' },
    { id: 'AL', value: 'Alabama' },
    { id: 'AR', value: 'Arkansas' },
    { id: 'AZ', value: 'Arizona' },
    { id: "CA", value: 'California' },
    { id: 'CO', value: 'Colorado' },
    { id: 'CT', value: '	Connecticut' },
    { id: 'DE', value: '	Delaware' },
    { id: 'FL', value: '	Florida' },
    { id: 'GA', value: '	Georgia' },
    { id: 'HI', value: '	Hawaii' },
    { id: 'IA', value: '	Iowa' },
    { id: 'ID', value: '	Idaho' },
    { id: 'IL', value: '	Illinois' },
    { id: 'IN', value: '	Indiana' },
    { id: 'KS', value: '	Kansas' },
    { id: 'KY', value: '	Kentucky' },
    { id: 'LA', value: '	Louisiana' },
    { id: 'MA', value: '	Massachusetts' },
    { id: 'MD', value: '	Maryland' },
    { id: 'ME', value: '	Maine' },
    { id: 'MI', value: '	Michigan' },
    { id: 'MN', value: '	Minnesota' },
    { id: 'MO', value: '	Missouri' },
    { id: 'MS', value: '	Mississippi' },
    { id: 'MT', value: '	Montana' },
    { id: 'NC', value: '	North Carolina' },
    { id: 'ND', value: '	North Dakota' },
    { id: 'NE', value: '	Nebraska' },
    { id: 'NH', value: '	New Hampshire' },
    { id: 'NJ', value: '	New Jersey' },
    { id: 'NM', value: '	New Mexico' },
    { id: 'NV', value: '	Nevada' },
    { id: 'NY', value: '	New York' },
    { id: 'OH', value: '	Ohio' },
    { id: 'OK', value: '	Oklahoma' },
    { id: 'OR', value: '	Oregon' },
    { id: 'PA', value: '	Pennsylvania' },
    { id: 'RI', value: '	Rhode Island' },
    { id: 'SC', value: '	South Carolina' },
    { id: 'SD', value: '	South Dakota' },
    { id: 'TN', value: '	Tennessee' },
    { id: 'TX', value: '	Texas' },
    { id: 'UT', value: '	Utah' },
    { id: 'VA', value: '	Virginia' },
    { id: 'VT', value: '	Vermont' },
    { id: 'WA', value: '	Washington' },
    { id: 'WI', value: '	Wisconsin' },
    { id: 'WV', value: '	West Virginia' },
    { id: 'WY', value: '	Wyoming' },

]


export const SOFTWARE_VERSION = [
    {
        'id': "CE-V601.30",
        'value': "CE-V601.30"
    },
    {
        'id': "CE-V601.31",
        'value': "CE-V601.31"
    },
    {
        'id': "CE-V601.32",
        'value': "CE-V601.32"
    }
]


export const OS_VERSION = [
    {
        'id': "OS-0601.12",
        'value': "OS-0601.12"
    },
    {
        'id': "OS-0601.13",
        'value': "OS-0601.13"
    }
]

export const EPROM = [
    {
        'id': "RM01.03032",
        'value': "RM01.03032"
    },
    {
        'id': "RM01.03033",
        'value': "RM01.03033"
    },
    {
        'id': "RM01.03034",
        'value': "RM01.03034"
    }
]

export const DENOMINATIONS = [
    {
        'id': "0",
        'value': "$0"
    },
    {
        'id': "5",
        'value': "$5"
    },
    {
        'id': "10",
        'value': "$10"
    },
    {
        'id': "20",
        'value': "$20"
    },
    {
        'id': "50",
        'value': "$50"
    },
]

export const TIMEZONES = [
    {
        'id': 'EST5EDT',
        'value': 'EST'
    },
    {
        'id': 'CST',
        'value': 'CST'
    },
    {
        'id': 'MST',
        'value': 'MST'
    },
    {
        'id': 'PST',
        'value': 'PST'
    },
    {
        'id': 'AST',
        'value': 'AST'
    },
    
]

export const BANK_STMT_DESC = [
    {
        'id': 'SwitchCommerce',
        'status': 'SwitchCommerce'
    },
    {
        'id': 'DNSSettlement',
        'status': 'DNSSettlement'
    },
    {
        'id': 'MVNT',
        'status': 'MVNT'
    }
]
