const intialState = { snackbarOpen: false, snackbarType: 'success', snackbarMessage: "" }


export const SNACKBARREDUCER = (state = intialState, action) => {
    switch (action.type) {
        case 'SET_SNACKBAR':
            const { snackbarOpen, snackbarType, snackbarMessage } = action.data
            return {
                snackbarOpen, snackbarType, snackbarMessage
            }
        default:
            return state;
    }
}