import axios from '../../config/axios';
import config from '../../config/api';


export const getIsos = (data) => ({
    type: 'GET_ISOS',
    data
})

export const getProcessors = (data) => ({
    type: 'GET_PROCESSORS',
    data
})

export const getTerminalMake = (data) => ({
    type: 'GET_TERMINAL_MAKE',
    data
})


export const getMerchants = (data) => ({
    type: 'GET_MERCHANTS',
    data
})

export const getReportTypes = (data) => ({
    type: 'GET_REPORT_TYPES',
    data
})

export const getPrefix = (data) => ({
    type: 'GET_PREFIX',
    data
})

export const getStates = (data) => ({
    type: 'GET_STATES',
    data
})

export const getProcessorTerminalModels = (data) => ({
    type: 'GET_PROCESSOR_TERMINAL_MODELS',
    data
})

export const fetchIsos = () => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/ddl/isos`).then(response => {
            if (response.status === 200) {
                dispatch(getIsos(response.data));
            } else {
                dispatch(getIsos([]));
            }
        }).catch(error => {
            dispatch(getIsos([]));
        })
    }
}

export const fetchProcessors = () => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/ddl/processors`).then(response => {
            if (response.status === 200) {
                dispatch(getProcessors(response.data));
            } else {
                dispatch(getProcessors([]));
            }
        }).catch(error => {
            dispatch(getProcessors([]));
        })
    }
}
export const fetchTerminalMake = () => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/ddl/terminalMakes`,).then(response => {
            if (response.status === 200) {
                dispatch(getTerminalMake(response.data));
            } else {
                dispatch(getTerminalMake([]));
            }
        }).catch(error => {
            dispatch(getTerminalMake([]));
        })
    }
}

export const fetchMerchants = (merchantID) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/ddl/terminals/${merchantID}`).then(response => {
            if (response.status === 200) {
                console.log('Fetching dropdown data for : ' + merchantID)
                dispatch(getMerchants(response.data));
            } else {
                dispatch(getMerchants([]));
            }
        }).catch(error => {
            dispatch(getMerchants([]));
        })
    }
}

export const fetchReportTypes = () => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/ddl/getReportTypes`,).then(response => {
            if (response.status === 200) {
                dispatch(getReportTypes(response.data));
            } else {
                dispatch(getReportTypes([]));
            }
        }).catch(error => {
            dispatch(getReportTypes([]));
        })
    }
}


export const fetchPrefix = () => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/ddl/getProcessorPrefixes`,).then(response => {
            if (response.status === 200) {
                dispatch(getPrefix(response.data));
            } else {
                dispatch(getPrefix([]));
            }
        }).catch(error => {
            dispatch(getPrefix([]));
        })
    }
}

export const fetchProcessorTerminalModels = () => {
    return function (dispatch) {
        console.log('Fetching Processor Models')
        axios.get(`${config.api.FETCH_URL}/ddl/getProcessorTerminalModels`,).then(response => {
            if (response.status === 200) {
                dispatch(getProcessorTerminalModels(response.data));
            } else {
                dispatch(getProcessorTerminalModels([]));
            }
        }).catch(error => {
            dispatch(getProcessorTerminalModels([]));
        })
    }
}