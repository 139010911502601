import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useNavigate } from 'react-router';
import { Panel, PanelBody, PanelHeader } from "../../header/panel/panel"
import { merchantAdminHeadings, merchantHeadings } from '../../../utils/constants';
import { fetchAllMerchants, fetchMerchantInfo, setModified } from '../../../redux/actions/MerchantActions';
import { fetchMerchantTerminal, fetchTerminal, setTerminalEdit, setTerminalID,getAllTerminals } from '../../../redux/actions/TerminalActions';
import moment from 'moment';
import { setMerchantEdit, setMerchantID } from '../../../redux/actions/MerchantActions';
import TableView from '../Table/Table';
import { tableHeadings } from '../../../utils/constants';


const MerchantView = (props) => {

    const navigate = useNavigate();

    const heading = props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ? merchantAdminHeadings : merchantHeadings


    useEffect(() => {
        let id = props.MERCHANTREDUCER.merchantID
        console.log(id)
        if (id) {
            props.fetchMerchantInfo(id)
            props.fetchMerchantTerminal(id)
        }
        props.setModified(false)

        return () => {
            props.setMerchantEdit(false)
            props.getAllTerminals([])
        }
    }, [])


    const handleTerminalClick = (value) => {
        props.setTerminalID(value.data.terminalId)
        navigate('/terminal/view')
    }


    const handleEditClick = () => {
        navigate('/merchant/edit')
    }

    return (


        <div className="row mb-3">

            <div className="col-xl-4">

                <Panel>

                    <PanelHeader>

                        <div className="row mb-0">

                            <div className="col-xl-4">
                                Merchant Profile
                            </div>

                            <div className="col-xl-6"></div>

                            <div className="col-xl-2">

                                {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ? <button className='btn btn-primary d-block w-30 btn-lg h-9px fs-11px' onClick={handleEditClick}>Edit</button> : null}

                            </div>

                        </div>


                    </PanelHeader>

                    <PanelBody>

                        <div className="table-responsive">

                            <table className="table mb-0">
                                <tbody>

                                    {props.MERCHANTREDUCER.merchantInfo && props.MERCHANTREDUCER.merchantInfo.length ? Object.keys(heading).map(data => {
                                        let key = data.includes('contact') || data.includes("address") ? data.split(".") : data
                                        if (typeof (key) != 'object') {
                                            if (props.MERCHANTREDUCER.merchantInfo[0][key] && props.MERCHANTREDUCER.merchantInfo[0][key].toString()) {
                                                if (key == 'merchantStatusID') {
                                                    return (

                                                        <tr key={data}>
                                                            <th >{heading[data]}</th>
                                                            <th>:</th>
                                                            <td className={props.MERCHANTREDUCER.merchantInfo[0][key] == 1 ? 'approved' : props.MERCHANTREDUCER.merchantInfo[0][key] == 2 ? 'suspended' : props.MERCHANTREDUCER.merchantInfo[0][key] == 3 ? 'inactive' : 'reject'}>{props.MERCHANTREDUCER.merchantInfo[0][key] == 1 ? 'Active' : props.MERCHANTREDUCER.merchantInfo[0][key] == 2 ? 'Suspended' : props.MERCHANTREDUCER.merchantInfo[0][key] == 3 ? 'InAcive' : 'Deleted'}</td>
                                                        </tr>
                                                    )
                                                } else {
                                                    return (
                                                        <tr key={data}>
                                                            <th>{heading[data]}</th>
                                                            <th>:</th>
                                                            <td>{key.toLowerCase().includes('date') ? moment(props.MERCHANTREDUCER.merchantInfo[0][key]).format('MMM DD, YYYY HH:MM:SS') : key.toLowerCase().includes('password') ?
                                                                <input
                                                                    margin="normal"
                                                                    type='password'
                                                                    name="tempPassword"
                                                                /> : props.MERCHANTREDUCER.merchantInfo[0][key]}</td>
                                                        </tr>
                                                    )
                                                }

                                            }
                                        } else {
                                            if (props.MERCHANTREDUCER.merchantInfo[0][key[0]] && props.MERCHANTREDUCER.merchantInfo[0][key[0]][key[1]]) {
                                                return (
                                                    <tr>
                                                        <th>{heading[data]}</th>
                                                        <th>:</th>
                                                        <td>{props.MERCHANTREDUCER.merchantInfo[0][key[0]][key[1]] ? props.MERCHANTREDUCER.merchantInfo[0][key[0]][key[1]] : '-'}</td>
                                                    </tr>
                                                )
                                            }
                                        }
                                    }
                                    ) : null}
                                </tbody>
                            </table>
                        </div>

                    </PanelBody>
                </Panel>
            </div>

            <div className="col-xl-8">

                <Panel>
                    <PanelHeader>Terminals</PanelHeader>

                    <TableView height={600} headings={props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ?  tableHeadings.adminTerminalsSubList : tableHeadings.terminalsSubList} data={props.TERMINALREDUCER.terminalsData} handleClick={handleTerminalClick} />

                </Panel>
            </div>

        </div>
    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchAllMerchants,
    fetchMerchantInfo,
    fetchMerchantTerminal,
    fetchTerminal,
    setMerchantEdit,
    setTerminalEdit,
    setTerminalID,
    setMerchantID,
    setModified,
    getAllTerminals


}


export default connect(mapStateToProps, mapDispatchToProps)(MerchantView);