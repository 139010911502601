import React from 'react';

import LoginComponent from './Login/LoginComponent';
import Sidebar from './components/header/sidebar/sidebar.jsx';
import RequiredAuth from './utils/RequiredAuth';
import TerminalView from './components/body/Terminal/TerminalView';
import TerminalAddEdit from './components/body/Terminal/TerminalAddEdit';
import { Navigate } from 'react-router';
import Transactions from './components/body/Dashboard/Transactions';
import ISOList from './components/body/ISO/ISOList';
import UserList from './components/body/User/UserList';
import ErrorPage from './components/Error/ErrorPage';
import Reports from './components/body/Reports/Reports';
import Import from './components/body/Import/Import';
import TerminalList from './components/body/Terminal/TerminalList';
import MerchantList from './components/body/Merchant/MerchantList';
import MerchantView from './components/body/Merchant/MerchantView';
import MerchantAddEdit from './components/body/Merchant/MerchantAddEdit';
import KeysTable from './components/body/Import/KeysTable';
import ISOView from './components/body/ISO/ISOView';
import ISOAddEdit from './components/body/ISO/ISOAddEdit';
import UserAddEdit from './components/body/User/UserAddEdit';
import UserView from './components/body/User/UserView';
import TerminalProfile from './components/body/Terminal/TerminalProfile';
import EmailVerificationComponent from './EmailVerification/EmailVerificationComponent';
import Dashboard from './components/body/Dashboard/Dashboard';
import LoginAsUser from './components/body/User/LoginAsUser';
import AutoReports from './components/body/Reports/AutoReports';



const routes = [


    {
        path: '/',
        element: <Sidebar />,
        children: [
            { path: '/dashboard', element: <RequiredAuth allowableRoles={["1", "2", "3", "4", "5", "6","7"]}><Dashboard></Dashboard></RequiredAuth> },
            { path: '/', element: <RequiredAuth allowableRoles={["1", "2", "3", "4", "5", "6","7"]}> <Navigate to="/dashboard" /> </RequiredAuth> },
            { path: '/import', element: <RequiredAuth allowableRoles={["1", "6"]}><Import /> </RequiredAuth> },
            { path: '/keys', element: <RequiredAuth allowableRoles={["1", "6"]}><KeysTable /> </RequiredAuth> },
            { path: '/reports', element: <RequiredAuth allowableRoles={["1","2","3","4","5","6","7"]}><Reports /> </RequiredAuth> },

        ]
    },
    {
        path: '/user',
        element: <Sidebar />,
        children: [

            { path: 'profile', element: <RequiredAuth allowableRoles={["1", "2", "3", "4", "5", "6","7"]}><UserView profile={true} /> </RequiredAuth> },
        ]
    },

    {
        path: '/terminal',
        element: <Sidebar />,
        children: [
            { path: 'list', element: <RequiredAuth allowableRoles={["1", "2", "3", "4", "5", "6","7"]}><TerminalList /> </RequiredAuth> },
            { path: 'view', element: <RequiredAuth allowableRoles={["1", "2", "3", "4", "5", "6","7"]}><TerminalProfile /> </RequiredAuth> },
            { path: 'add', element: <RequiredAuth allowableRoles={["1", "6"]}><TerminalAddEdit /> </RequiredAuth> },
            { path: 'edit', element: <RequiredAuth allowableRoles={["1", "6"]}><TerminalAddEdit edit={true} /> </RequiredAuth> },

        ]
    },

    {
        path: '/merchant',
        element: <Sidebar />,
        children: [
            { path: 'list', element: <RequiredAuth allowableRoles={["1", "2", "3", "6","7"]}><MerchantList /> </RequiredAuth> },
            { path: 'view', element: <RequiredAuth allowableRoles={["1", "2", "3", "4", "5", "6","7"]}><MerchantView /> </RequiredAuth> },
            { path: 'add', element: <RequiredAuth allowableRoles={["1", "6"]}><MerchantAddEdit /> </RequiredAuth> },
            { path: 'edit', element: <RequiredAuth allowableRoles={["1", "6"]}><MerchantAddEdit edit={true} /> </RequiredAuth> },

        ]
    },

    {
        path: '/iso',
        element: <Sidebar />,
        children: [
            { path: 'list', element: <RequiredAuth allowableRoles={["1","6","7"]}><ISOList /> </RequiredAuth> },
            { path: 'view', element: <RequiredAuth allowableRoles={["1", "6","7"]}><ISOView /> </RequiredAuth> },
            { path: 'add', element: <RequiredAuth allowableRoles={["1"]}><ISOAddEdit /> </RequiredAuth> },
            { path: 'edit', element: <RequiredAuth allowableRoles={["1"]}><ISOAddEdit edit={true} /> </RequiredAuth> },

        ]
    },

    {
        path: '/user',
        element: <Sidebar />,
        children: [
            { path: 'list', element: <RequiredAuth allowableRoles={["1", "6","7"]}><UserList /> </RequiredAuth> },
            { path: 'view', element: <RequiredAuth allowableRoles={["1", "2", "3", "4", "5", "6","7"]}><UserView /> </RequiredAuth> },
            { path: 'add', element: <RequiredAuth allowableRoles={["1", "6","7"]}><UserAddEdit /> </RequiredAuth> },
            { path: 'edit', element: <RequiredAuth allowableRoles={["1", "6","7"]}><UserAddEdit edit={true} /> </RequiredAuth> },

        ]
    },

    {
        path:'/admin',
        element: <Sidebar/>,
        children: [
            { path: 'loginAsUser', element: <RequiredAuth allowableRoles= {["1","6"]}><LoginAsUser/></RequiredAuth>},
            { path: 'autoReports', element: <RequiredAuth allowableRoles= {["1"]}><AutoReports/></RequiredAuth>},

        ]
    },

    { path: '/error', element: <ErrorPage /> },

    {path: '/verifyEmail', element: <EmailVerificationComponent/>},


    { path: '/login', element: <LoginComponent /> },

    { path: '*', element: <ErrorPage /> },


];

export default routes;