import React, { useState, useEffect } from 'react';
import { addEmail, verifyCode } from '../../src/redux/actions/UserActions';
import { useNavigate, useLocation } from 'react-router-dom';

import { loginSuccess } from '../../src/redux/actions/LoginActions';

import { connect, useDispatch } from "react-redux";
import { useAuth } from '../../src/utils/Auth';
import FormErrorMessage from 'rsuite/esm/FormErrorMessage';

const EmailVerificationComponent = (props) => {

    const dispatch = useDispatch()

    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [code, setCode] = useState('')
    const [errorMsg, setErrorMsg] = useState('')

    const handleChange = (event) => {

        if (event.target.name === 'email') {
            setValidEmail(true)
            setEmail(event.target.value)
        } else if (event.target.name === 'code') {
            setCode(event.target.value)
        }
    }

    useEffect(() => {

        if (props.USERSREDUCER.addEmailresponse?.emailVerified) {
            let params = {
                user: props.LOGINREDUCER.USER.user,
                token: props.LOGINREDUCER.USER.token,
                emailVerified: true,
                userID: props.LOGINREDUCER.USER.userID,
                role: props.LOGINREDUCER.USER.role
            }
            dispatch(loginSuccess(params))
            navigate('/dashboard')
        }
    }, [props.USERSREDUCER])


    const navigate = useNavigate();
    const auth = useAuth();
    const location = useLocation();


    const handleSubmit = (event) => {
        event.preventDefault();
        let params = {
            emailID: email,
            username: props.LOGINREDUCER.USER.user,
        }

        console.log(params)
        if (email && email.length) {
            props.addEmail(params)
        }
    }

    const handleCodeVerification = (event) => {
        event.preventDefault();
        let params = {
            emailID: email,
            code: code,
            username: props.LOGINREDUCER.USER.user,
        }

        console.log(params)
        if (email && email.length) {
            props.verifyCode(params)
        }
    }

    return (
        <div className="coming-soon">
            <div className="coming-soon-content">
                <div className="desc">
                    Please add your email to receive updates related to Reporting Portal, Payment gateway and Terminals.
                </div>
                <div className="input-group input-group-lg mx-auto mb-12">
                    {/* <span className="input-group-text border-0  bg-light"><i className="fa fa-envelope"></i></span> */}
                    {/* {props.USERSREDUCER.addEmailresponse?.emailSent ?
                        <>
                            <div className="desc">
                                Email sent to <b>{email}</b>. Please check email and enter code below.
                            </div>

                            <input type="text" className="form-control fs-13px" placeholder="Verification code" name="code" onChange={handleChange} />
                            <button type="button" className="btn fs-13px btn-dark" onClick={handleCodeVerification}>Verify code</button>
                        </>
                        :
                        <>

                            <input type="text" className="form-control fs-13px border-0 shadow-none ps-0 bg-light" placeholder="Email Address" name="email" onChange={handleChange} />
                            <button type="button" className="btn fs-13px btn-dark" onClick={handleSubmit}>Add Email</button>
                        </>

                    } */}

                    {/* <input type="text" className="form-control fs-13px border-0 shadow-none ps-0 bg-light" placeholder="Email Address" name="email" onChange={handleChange} />
                    <button type="button" className="btn fs-13px btn-dark" onClick={handleSubmit}>Add Email</button> */}

                    {props.USERSREDUCER.addEmailresponse?.emailSent ?
                        <div className="desc">
                            <div>
                            Please check email and enter code below.
                                <input type="text" className="form-control fs-13px" placeholder="Verification code" name="code" onChange={handleChange} />
                                <button type="button" className="btn fs-13px btn-dark" onClick={handleCodeVerification}>Verify code</button>
                            </div>
                        </div> : <div className="desc">
                            Please enter an active email address.
                            <div>
                                <input type="text" className="form-control fs-13px" placeholder="Email Address   " name="email" onChange={handleChange} />
                                <button type="button" className="btn fs-13px btn-dark" onClick={handleSubmit}>Add Email</button>
                            </div>
                        </div>

                    }

                </div>
                {/* <p className="text-gray-500 mb-5">We don't spam. Your email address is secure with us.</p> */}

            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    addEmail, verifyCode, loginSuccess
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationComponent);