import React, { useEffect, useState } from 'react';
import Select from 'react-select'

import Table from '../Table/Table';
import { tableHeadings } from '../../../utils/constants';
import { connect } from "react-redux";

import { useNavigate } from 'react-router';
import { Panel, PanelBody, PanelHeader } from '../../header/panel/panel';

import { uploadReport, uploadKeys, setLoading, getUploadHistory, setModified } from '../../../redux/actions/UploadActions';
import { setSnackbar } from "../../../redux/actions/SnackbarAction";

import { Backdrop, CircularProgress } from '@mui/material';

const Import = (props) => {


    const fileTypeDropdown = [{ fileType: 1, fileTypeName: 'Transactions' }, { fileType: 2, fileTypeName: 'Keys' }]

    const navigate = useNavigate();
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [uploadType, setUploadType] = useState('');
    const [hostValue, setHostValue] = useState('');
    const [hostError, setHostError] = useState(false);




    useEffect(() => {
        props.getUploadHistory()
        handleReset()
    }, [])

    useEffect(() => {
        if (props.UPLOADREDUCER.modified) {
            props.setModified(false)
            navigate('/keys')
        }
    }, [props.UPLOADREDUCER])


    const handleUploadFiles = (event) => {
        if (event.target.files && event.target.files.length)
            setUploadedFiles([event.target.files[0]])
    }

    const handleUpload = () => {
        if ((uploadType == '1' && hostValue && uploadedFiles && uploadedFiles.length)) {
            let body = {
                processorID: hostValue,
                file: uploadedFiles[0]
            }
            props.setLoading(true)
            props.uploadReport(body)
            handleReset()

        } else if (uploadType == '2' && uploadedFiles && uploadedFiles.length) {
            let body = {
                file: uploadedFiles[0]
            }

            props.setLoading(true)
            props.uploadKeys(body)

        }

        if (uploadType == '1' && !hostValue) {
            setHostError(true)
        }

        if (uploadedFiles && !uploadedFiles.length) {
            props.setSnackbar(true, 'error', 'Choose File')
        }

    }



    const handleChange = (event) => {
        let targetName = event.target.name
        let targetValue = event.target.value
        if (targetValue) {

            switch (targetName) {
                case 'host':
                    setHostError(false)
                    setHostValue(targetValue)
                    break;

                case 'uploadType':
                    setUploadType(targetValue)
                    break;
                default:
                    break;
            }
        }
    }

    const handleReset = () => {
        setHostValue('')
        setUploadType('transactions')
        setUploadedFiles([])
    }

    return (
        <>
            {
                props.UPLOADREDUCER.loading ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={props.UPLOADREDUCER.loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop> : null
            }

            <div className="row mb-3">

                <div className="col-xl-3">

                    <Panel>
                        <PanelHeader>Upload</PanelHeader>

                        <PanelBody>

                            <div className="row mb-3">
                                <div className="col-xl-12">

                                    <select className="form-select"
                                        value={uploadType ? uploadType : ''}
                                        onChange={handleChange}
                                        name='uploadType'

                                    >
                                        <option value=''>Select File Type</option>

                                        {fileTypeDropdown.map((option) => (
                                            <option key={option.fileType} value={option.fileType}>
                                                {option.fileTypeName}
                                            </option>
                                        ))}
                                    </select>

                                    {/* <Select 
                                            // onChange={setUploadType}
                                            // name='uploadType'
                                            // value={uploadType ? uploadType : ''}
                                            // className="form-control fs-13px" 
                                            options={fileTypeDropdown.map(opt => ({ label: opt.fileTypeName, value: opt.fileType }))} 
                                            placeholder="Select Import File Type"
                                            noOptionsMessage={() => "No option"}> 
                                    </Select> */}
                                </div>

                            </div>

                            {uploadType === '1' ?
                                <div className="row mb-3">
                                    <div className="col-xl-12">

                                        <select className="form-select"
                                            value={hostValue ? hostValue : ''}
                                            onChange={handleChange}
                                            name='host'
                                        >
                                            <option value=''>Select Processor</option>

                                            {props.DROPDOWNREDUCER.processorsData.map((option) => (
                                                <option key={option.processorID} value={option.processorID}>
                                                    {option.processorName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                </div> : null}

                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <input
                                        type='file'
                                        className="form-control fs-13px"
                                        onChange={handleUploadFiles}>

                                    </input>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <button className="btn btn-sm btn-primary d-block w-100 btn-lg h-35px fs-13px"
                                        onClick={handleUpload}>
                                        Upload
                                    </button>
                                </div>
                            </div>


                        </PanelBody>

                    </Panel>
                </div>
                <div className="col-xl-9">

                    <Panel>
                        <PanelHeader>Upload History</PanelHeader>
                        <Table height={600} headings={tableHeadings['uploadTransactions']} data={props.UPLOADREDUCER.uploadHistory} />
                    </Panel>
                </div>

            </div>
        </>


    )

}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    uploadReport,
    uploadKeys,
    setLoading,
    getUploadHistory,
    setModified,
    setSnackbar
}


export default connect(mapStateToProps, mapDispatchToProps)(Import);