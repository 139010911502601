import React, { useEffect, useState } from 'react';
import { fetchTerminal } from "../../../redux/actions/TerminalActions";
import { connect } from "react-redux";
import { BANK_STMT_DESC, STATUS } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { fetchMerchants } from '../../../redux/actions/dropdownActions';
import { CREDITBLOCK, TIMEZONES } from '../../../utils/constants';
import { addTerminal, editTerminal, checkTerminalID, setModified, checkSerialNumber } from '../../../redux/actions/TerminalActions';
import { fetchIsos, fetchProcessors, fetchTerminalMake, fetchReportTypes, fetchPrefix, fetchProcessorTerminalModels } from "../../../redux/actions/dropdownActions";
import { Panel, PanelBody, PanelHeader } from '../../header/panel/panel';


const AddEditTerminal = (props) => {

    const navigate = useNavigate()

    const [processorTermID, setProcessorTermID] = useState('')
    const [terminalID, setTerminalID] = useState('')
    const [processorID, setProcessorID] = useState('')
    const [isoValue, setIsoValue] = useState('');
    const [merchantID, setMerchantID] = useState('')
    const [serialNumber, setSerialNumber] = useState()
    const [makeID, setMakeID] = useState('')
    const [modelID, setModelID] = useState('')
    const [status, setStatus] = useState(1);
    const [creditBlock, setCreditBlock] = useState("false");
    const [autoBatch, setAutoBatch] = useState("false");
    const [autoBatchTime, setAutoBatchTime] = useState('')
    const [fee, setFee] = useState(0)
    const [maxTranAmount, setMaxTranAmount] = useState(0)
    const [statusMonitoring, setStatusMonitoring] = useState("false");
    const [terminalProcessorModel, setTerminalProcessorModel] = useState('')
    const [timezone, setTimezone] = useState('')
    const [bankStmtDescription, setBankStmtDescription] = useState('')


    const [terminalIDError, setTerminalIDError] = useState(false)
    const [processorTermIDError, setProcessorTermIDError] = useState(false)
    const [processorIDError, setprocessorIDError] = useState(false)
    const [isoError, setIsoError] = useState(false);
    const [statusError, setStatusError] = useState(false);
    const [merchantError, setMerchantError] = useState(false)
    const [serialError, setSerialError] = useState(false)
    const [makeError, setMakeError] = useState(false)
    const [modelError, setModelError] = useState(false)
    const [creditError, setCreditError] = useState(false)
    const [autoBatchError, setAutoBatchError] = useState(false)
    const [feeError, setFeeError] = useState(false)
    const [maxTranAmountError, setMaxTranAmountError] = useState(false)
    const [statusMonitoringError, setStatusMonitoringError] = useState(false);
    const [terminalProcessorModelError, setTerminalProcessorModelError] = useState(false)
    const [timezoneError, setTimezoneError] = useState(false)
    const [bankStmtDescriptionError, setBankStmtDescriptionError] = useState(false)


    const [modelDropdown, setModelDropdown] = useState([])
    const [merchantDropdown, setMerchantDropdown] = useState([])
    const [terminalProcessorModelDropDown, setTerminalProcessorModelDropDown] = useState([])

    useEffect(() => {
        if (props.edit) {
            let data = props.TERMINALREDUCER.terminalData[0]
            setTerminalID(data.terminalID)
            setProcessorTermID(data.processorTermID)
            setIsoValue(data.isoID)
            setProcessorID(data.processorID)
            setMerchantID(data.merchantID)
            setSerialNumber(data.serialNumber)
            setModelID(data.modelID)
            setMakeID(data.makeID)
            setStatus(data.terminalStatusID)
            setCreditBlock(String(data.creditBlock))
            setAutoBatch(String(data.autoBatch))
            setAutoBatchTime(data.autoBatchTime)
            setFee(data.fee)
            setMaxTranAmount(data.maxTranAmount)
            setStatusMonitoring(data.statusMonitoringEnabled)
            setTerminalProcessorModel(data.processorTermModelID)
            setTimezone(data.timeZone)
            setBankStmtDescription(data.bankStmtDescription)

            let dataPo = props.DROPDOWNREDUCER.terminalMakeData.filter(d => {
                return d.makeID === data.makeID
            })
            setModelDropdown(dataPo[0]?.terminalModels)
            let merchants = props.DROPDOWNREDUCER.isosData.filter(iso => {
                return iso.isoID === data.isoID
            })
            setMerchantDropdown(merchants[0]?.merchants)
        }
    }, [])

    useEffect(() => {
        props.fetchIsos()
        props.fetchProcessors()
        props.fetchTerminalMake()
        props.fetchPrefix()
        props.fetchProcessorTerminalModels()
    }, [])

    useEffect(() => {
        if (!props.edit)
            handleReset()
    }, [props.edit])

    useEffect(() => {
        if (props.TERMINALREDUCER.modified) {
            props.setModified(false)
            navigate('/terminal/view')
        }
    }, [props.TERMINALREDUCER])

    useEffect(() => {
        props.TERMINALREDUCER.isExist ? setTerminalIDError(true) : setTerminalIDError(false)
    }, [props.TERMINALREDUCER.isExist])

    useEffect(() => {
        props.TERMINALREDUCER.isSerial ? setSerialError(true) : setSerialError(false)
    }, [props.TERMINALREDUCER.isSerial])

    const handleChange = (event) => {
        let targetName = event.target.name
        let targetValue = event.target.value
        if (targetValue) {

            switch (targetName) {
                case 'processorTermID':
                    setProcessorTermIDError(false)
                    setProcessorTermID(targetValue)

                    let processId = props.DROPDOWNREDUCER.terminalPrefix.filter(tp => {
                        return targetValue.includes(tp.terminalIDPrefix)
                    })
                    if (processId && processId.length)
                        setProcessorID(processId[0].processorID)
                    break;
                case 'processorID':
                    setprocessorIDError(false)
                    setProcessorID(targetValue)
                    break;
                case 'isoID':
                    setIsoError(false)
                    setIsoValue(targetValue);
                    break;
                case 'merchantID':
                    setMerchantError(false);
                    setMerchantID(targetValue)
                    break;
                case 'serialNumber':
                    setSerialError(false);
                    setSerialNumber(targetValue)
                    break;
                case 'makeID':
                    setMakeError(false);
                    setMakeID(targetValue)
                    break;
                case 'modelID':
                    setModelError(false);
                    setModelID(targetValue)
                    break;
                case 'creditBlock':
                    setCreditError(false);
                    setCreditBlock(targetValue)
                    break;
                case 'terminalStatusID':
                    setStatusError(false)
                    setStatus(targetValue)
                    break
                case 'autoBatch':
                    setAutoBatchError(false)
                    setAutoBatch(targetValue)
                    break;
                case 'autoBatchTime':
                    setAutoBatchTime(targetValue)
                    break;
                case 'fee':
                    setFee(targetValue)
                    break;
                case 'maxTranAmount':
                    setMaxTranAmount(targetValue)
                    break;
                // case 'softwareVersion':
                //     setSoftwareVersion(targetValue)
                //     break;
                // case 'osVersion':
                //     setOsVersion(targetValue)
                //     break;
                // case 'eprom':
                //     setEprom(targetValue)
                //     break;
                case 'statusMonitoring':
                    setStatusMonitoringError(false)
                    setStatusMonitoring(targetValue)
                    break;
                case 'terminalProcessorModel':
                    setTerminalProcessorModel(targetValue)
                    setTerminalProcessorModelError(false)
                    break;
                case 'timezone':
                    setTimezone(targetValue)
                    setTimezoneError(false)
                    break;
                case 'bankStmtDescription':
                    setBankStmtDescription(targetValue)
                    setBankStmtDescriptionError(false)
                    break;
                default:
                    break;
            }

            if (targetName == 'makeID') {
                let dataPo = props.DROPDOWNREDUCER.terminalMakeData.filter(data => {
                    return data.makeID == targetValue
                })
                setModelDropdown(dataPo[0]?.terminalModels)
            }
            if (targetName == 'isoID') {
                let merchants = props.DROPDOWNREDUCER.isosData.filter(iso => {
                    return iso.isoID == targetValue
                })
                setMerchantDropdown(merchants[0]?.merchants)
            }

        }
    }

    const checkTerminalID = (event) => {
        props.checkTerminalID(event.target.value)
    }

    const checkSerialNumber = (event) => {
        props.checkSerialNumber(event.target.value)
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        let body = {}

        if (terminalID) {
            body.terminalID = terminalID
        } else {
            setTerminalIDError(true)
        }

        if (processorTermID) {
            body.processorTermID = processorTermID
        } else {
            setProcessorTermID(true)
        }

        if (processorID) {
            body.processorID = processorID
        } else {
            setprocessorIDError(true)
        }


        if (merchantID) {
            body.merchantID = merchantID
        } else {
            setMerchantError(true)
        }

        if (status) {
            setStatusError(false);
            body.terminalStatusID = status
        } else {
            setStatusError(true)
        }

        if (autoBatch) {
            setAutoBatchError(false)
            body.autoBatch = autoBatch
        } else {
            setAutoBatchError(true)
        }
        if (autoBatchTime) {
            body.autoBatchTime = autoBatchTime;
        }

        if (serialNumber) {
            body.serialNumber = serialNumber
        } else {
            setSerialError(true)
        }


        if (modelID) {
            body.modelID = modelID
        } else {
            setModelError(true)
        }

        if (creditBlock) {
            body.creditBlock = creditBlock
        } else {
            setCreditError(true)
        }
        if (fee) {
            body.fee = fee
        } else {
            setFeeError(true)
        }
        if (maxTranAmount) {
            body.maxTranAmount = maxTranAmount
        } else {
            setMaxTranAmountError(true)
        }
        if (timezone) {
            body.timeZone = timezone
        } else {
            setTimezoneError(true)
        }
        if (bankStmtDescription) {
            body.bankStmtDescription = bankStmtDescription
        } else {
            setBankStmtDescriptionError(true)
        }
        // if (softwareVersion) {
        //     body.softwareVersion = softwareVersion
        // } else {
        //     setSoftwareVersionError(true)
        // }

        // if (osVersion) {
        //     body.osVersion = osVersion
        // } else {
        //     setOsVersionError(true)
        // }
        // if (eprom) {
        //     body.eprom = eprom
        // } else {
        //     setEpromError(true)
        // }

        if (terminalProcessorModel) {
            body.processorTerminalModelID = terminalProcessorModel
        } else {
            setTerminalProcessorModelError(true)
        }

        if (statusMonitoring) {
            body.statusMonitoringEnabled = statusMonitoring
        } else {
            setStatusMonitoringError(true)
        }

        if (processorTermID && status && processorID && isoValue && merchantID && serialNumber && makeID && modelID && creditBlock && !props.edit && fee && maxTranAmount && !bankStmtDescriptionError && terminalProcessorModel) {
            props.addTerminal(body)
        } else if (terminalID && status && processorID && isoValue && merchantID && serialNumber && makeID && modelID && creditBlock && props.edit && fee && maxTranAmount && !bankStmtDescriptionError&& terminalProcessorModel) {
            props.editTerminal(body)
        }

    }

    const handleResetPreventingRefresh = (event) => {
        event.preventDefault();
        handleReset()

    }

    const handleReset = (event) => {

        setProcessorTermID('')
        setProcessorID('')
        setIsoValue('')
        setMerchantID('')
        setSerialNumber('')
        setMakeID('')
        setModelID('')
        setCreditBlock('false')
        setStatusMonitoring('false')
        setStatus(1)
        setStatusError(false)
        setTerminalIDError(false)
        setprocessorIDError(false)
        setIsoError(false)
        setMerchantError(false)
        setSerialError(false)
        setMakeError(false)
        setModelError(false)
        setCreditError(false)
        setAutoBatchError(false)
        setFeeError(false)
        setStatusMonitoringError(false)
        setTerminalProcessorModelError(false)
    }

    const handleEditCancel = () => {
        navigate('/terminal/view');
    }


    return (

        <Panel >

            <PanelHeader>Terminal Form</PanelHeader>

            <PanelBody>

                <form className="fs-13px">

                    {props.edit ? <div className="mb-3">
                        <div className="row gx-3">
                            <div className="col-md-3 mb-2 mb-md-0">
                                <label className={"col-md-3 " + (terminalIDError ? 'text-danger' : '')}><b>Terminal ID</b></label>
                                <input type="text" className="form-control fs-13px"
                                    name="terminalID"
                                    onChange={handleChange}
                                    value={terminalID ? terminalID : ''}
                                    disabled={props.edit}
                                />
                            </div>
                        </div>

                    </div> : null}


                    <div className="mb-3">
                        <div className="row gx-3">
                            <div className="col-md-3 mb-2 mb-md-0">
                                <label className={"col-md-6 " + (processorTermIDError ? 'text-danger' : '')}><b>Processor Terminal ID</b></label>
                                <input type="text" className="form-control fs-13px"
                                    name="processorTermID"
                                    onChange={handleChange}
                                    onBlur={checkTerminalID}
                                    value={processorTermID ? processorTermID : ''}
                                />
                            </div>

                            <div className="col-md-3 mb-2 mb-md-0">

                                <label className={"col-md-3 " + (statusError ? 'text-danger' : '')}><b>Status</b></label>

                                <select className="form-select"

                                    label="Statment Descriptor"
                                    value={bankStmtDescription ? bankStmtDescription : ''}
                                    error={bankStmtDescriptionError}
                                    onChange={handleChange}
                                    name='bankStmtDescription'
                                >
                                    <option value=' '>Select Statement Descriptor </option>

                                    {BANK_STMT_DESC.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.status}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                    </div>

                    <div className="mb-3">

                        <div className="row gx-6">

                            <div className="col-md-3 mb-3 mb-md-0">

                                <label className={"col-md-3 " + (processorIDError ? 'text-danger' : '')} ><b>Processor</b></label>
                                <select className="form-select"
                                    onChange={handleChange}
                                    name='processorID'
                                    value={processorID ? processorID : ' '}
                                >
                                    <option value=''>Select Processor</option>
                                    {props.DROPDOWNREDUCER.processorsData.map((option) => (
                                        <option key={option.processorID} value={option.processorID}>
                                            {option.processorName}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-3 mb-2 mb-md-0">

                                <label className={"col-md-3 " + (statusError ? 'text-danger' : '')}><b>Status</b></label>

                                <select className="form-select"
                                    value={status ? status : 1}
                                    error={statusError}
                                    onChange={handleChange}
                                    name='terminalStatusID'
                                >

                                    {STATUS.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.status}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>

                    </div>

                    <div className="mb-3">
                        <div className="row gx-3">
                            <div className="col-md-3 mb-2 mb-md-0">
                                <label className={"col-md-6 " + (serialError ? 'text-danger' : '')}><b>Serial Number</b></label>
                                <input type="text" className="form-control fs-13px"
                                    name="serialNumber"
                                    onChange={handleChange}
                                    onBlur={checkSerialNumber}
                                    value={serialNumber ? serialNumber : ''}
                                />
                            </div>

                            <div className="col-md-3 mb-2 mb-md-0">

                                <label className={"col-md-3 " + (isoError ? 'text-danger' : '')}><b>Timezone</b></label>
                                <select className="form-select"
                                    value={timezone ? timezone : ''}
                                    error={timezoneError}
                                    onChange={handleChange}
                                    name='timezone'
                                >
                                    <option value=''>Select Timezone</option>

                                    {TIMEZONES.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.value}
                                        </option>
                                    ))}


                                </select>
                            </div>
                        </div>

                    </div>

                    <div className="mb-3">

                        <div className="row gx-3">

                            <div className="col-md-3 mb-2 mb-md-0">

                                <label className={"col-md-3 " + (isoError ? 'text-danger' : '')}><b>ISO</b></label>
                                <select className="form-select"
                                    value={isoValue ? isoValue : ''}
                                    error={isoError}
                                    onChange={handleChange}
                                    name='isoID'
                                >
                                    <option value=''>Select ISO</option>

                                    {props.DROPDOWNREDUCER.isosData.map((option) => (
                                        <option key={option.isoID} value={option.isoID}>
                                            {option.isoName}
                                        </option>
                                    ))}

                                </select>
                            </div>

                            <div className="col-md-3 mb-2 mb-md-0">
                                <label className={"col-md-3 " + (merchantError ? 'text-danger' : '')}><b>Merchant</b></label>

                                <select className="form-select"
                                    value={merchantID ? merchantID : ''}
                                    error={merchantError}
                                    onChange={handleChange}
                                    name='merchantID'
                                >

                                    <option value=''>Select Merchant</option>
                                    {merchantDropdown.map((option) => (
                                        <option key={option.merchantID} value={option.merchantID}>
                                            {option.merchantName}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>

                    </div>

                    <div className="mb-3">

                        <div className="row gx-6">

                            <div className="col-md-3 mb-3 mb-md-0">

                                <label className={"col-md-6 " + (feeError ? 'text-danger' : '')}><b>Fee</b></label>
                                <input type="number"
                                    step="0.01"
                                    className="form-control fs-13px"
                                    name="fee"
                                    onChange={handleChange}
                                    value={fee ? fee : ''}
                                />

                            </div>

                            <div className="col-md-3 mb-2 mb-md-0">

                                <label className={"col-md-6 " + (maxTranAmountError ? 'text-danger' : '')}><b>Max Allowable Amount</b></label>
                                <input type="number"
                                    step="0.01"
                                    className="form-control fs-13px"
                                    name="maxTranAmount"
                                    onChange={handleChange}
                                    value={maxTranAmount ? maxTranAmount : ''}
                                />

                            </div>

                        </div>

                    </div>

                    <div className="mb-3">

                        <div className="row gx-3">

                            <div className="col-md-3 mb-2 mb-md-0">

                                <label className={"col-md-12 " + (makeError ? 'text-danger' : '')}><b>Make</b></label>

                                <select className="form-select"
                                    value={makeID ? makeID : ''}
                                    error={makeError}
                                    onChange={handleChange}
                                    name='makeID'
                                >
                                    <option value=''>Select Make</option>
                                    {props.DROPDOWNREDUCER.terminalMakeData.map((option) => (
                                        <option key={option.makeID} value={option.makeID}>
                                            {option.makeName}
                                        </option>
                                    ))}

                                </select>
                            </div>

                            <div className="col-md-3 mb-2 mb-md-0">

                                <label className={"col-md-12 " + (modelError ? 'text-danger' : '')}><b>Model</b></label>

                                <select className="form-select"
                                    value={modelID ? modelID : ''}
                                    error={modelError}
                                    onChange={handleChange}
                                    name='modelID'
                                >
                                    <option value=''>Select Model</option>
                                    {modelDropdown.map((option) => (
                                        <option key={option.modelID} value={option.modelID}>
                                            {option.modelName}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>

                    </div>

                    <div className="mb-3">

                        <div className="row gx-3">

                            <div className="col-md-3 mb-2 mb-md-0">

                                <label className={"col-md-12 " + (creditError ? 'text-danger' : '')}><b>Credit Block</b></label>

                                <select className="form-select"
                                    label="Credit Block"
                                    value={creditBlock ? creditBlock : 'false'}
                                    error={creditError}
                                    onChange={handleChange}
                                    name='creditBlock'
                                >
                                    {CREDITBLOCK.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.status}
                                        </option>
                                    ))}

                                </select>
                            </div>

                            <div className="col-md-3 mb-2 mb-md-0">

                                <label className={"col-md-12 " + (statusMonitoringError ? 'text-danger' : '')}><b>Status Monitoring</b></label>

                                <select className="form-select"
                                    label="Credit Block"
                                    value={statusMonitoring ? statusMonitoring : 'false'}
                                    error={statusMonitoringError}
                                    onChange={handleChange}
                                    name='statusMonitoring'
                                >
                                    {CREDITBLOCK.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.status}
                                        </option>
                                    ))}

                                </select>
                            </div>

                        </div>
                    </div>

                    <div className="mb-3">

                        <div className="row gx-3">

                            <div className="col-md-3 mb-2 mb-md-0">
                                <label className="col-md-12"><b>Auto Batch</b></label>

                                <select className="form-select"
                                    value={autoBatch ? autoBatch : 'false'}
                                    error={autoBatchError}
                                    onChange={handleChange}
                                    name='autoBatch'
                                >
                                    {CREDITBLOCK.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.status}
                                        </option>
                                    ))}
                                </select>
                            </div>


                            {autoBatch === 'true' ? <div className="col-md-3 mb-2 mb-md-0">
                                <label className="col-md-12"><b>Auto Batch Time</b></label>

                                <input
                                    name="autoBatchTime"
                                    value={autoBatchTime ? autoBatchTime : ''}
                                    type="time"
                                    onChange={handleChange}
                                    className="form-control fs-13px"
                                ></input>
                            </div> : null}


                        </div>
                    </div>



                    <div className="mb-3">
                        <div className="row gx-3">
                            <div className="col-md-6 mb-2 mb-md-0">

                                <label className={"col-md-12 " + (terminalProcessorModelError ? 'text-danger' : '')} ><b>EPROM</b></label>
                                <select className="form-select"
                                    onChange={handleChange}
                                    name='terminalProcessorModel'
                                    value={terminalProcessorModel ? terminalProcessorModel : ' '}
                                >
                                    <option value=''>Select Terminal Model</option>

                                    {props.DROPDOWNREDUCER.processorTerminalModels.map((option) => (
                                        <option key={option.modelID} value={option.modelID}>
                                            {option.modelAndVersions}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* <div className="col-md-2 mb-2 mb-md-0">

                                <label className={"col-md-12 " + (softwareVersionError ? 'text-danger' : '')} ><b>Software Version</b></label>
                                <select className="form-select"
                                    onChange={handleChange}
                                    name='softwareVersion'
                                    value={softwareVersion ? softwareVersion : ' '}
                                >
                                    <option value=''>Select Software Version</option>
                                    {SOFTWARE_VERSION.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.value}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-2 mb-2 mb-md-0">

                                <label className={"col-md-12 " + (osVersionError ? 'text-danger' : '')}><b>OS Version</b></label>

                                <select className="form-select"
                                    value={osVersion ? osVersion : ''}
                                    error={osVersionError}
                                    onChange={handleChange}
                                    name='osVersion'
                                >
                                    <option value=''>Select OS Version</option>

                                    {OS_VERSION.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2 mb-2 mb-md-0">

                                <label className={"col-md-12 " + (epromError ? 'text-danger' : '')}><b>EPROM</b></label>

                                <select className="form-select"
                                    value={eprom ? eprom : ''}
                                    error={epromError}
                                    onChange={handleChange}
                                    name='eprom'
                                >
                                    <option value=''>Select EPROM</option>

                                    {EPROM.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.value}
                                        </option>
                                    ))}
                                </select>
                            </div> */}
                        </div>

                    </div>


                    <div className="mb-3">

                        <div className="row gx-3">

                            <div className="col-md-3 mb-2 mb-md-0">
                                <button className='btn btn-primary d-block w-100 btn-lg h-9px fs-13px' onClick={handleSubmit}>{props.edit ? 'Save' : 'Add'}</button>
                            </div>

                            <div className="col-md-3 mb-2 mb-md-0">

                                {props.edit ? <button className='btn btn-gray d-block w-100 btn-lg h-9px fs-13px' onClick={handleEditCancel}>Cancel</button>
                                    : <button className='btn btn-gray d-block w-100 btn-lg h-9px fs-13px' onClick={handleResetPreventingRefresh}>Reset</button>}


                            </div>
                        </div>
                    </div>

                </form>

            </PanelBody>

        </Panel>

    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchTerminal,
    fetchMerchants,
    addTerminal,
    editTerminal,
    checkTerminalID,
    setModified,
    checkSerialNumber,
    fetchIsos,
    fetchProcessors,
    fetchTerminalMake,
    fetchReportTypes,
    fetchPrefix,
    fetchProcessorTerminalModels
}


export default connect(mapStateToProps, mapDispatchToProps)(AddEditTerminal);