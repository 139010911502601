import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { startLogout } from '../redux/actions/LoginActions';
import IdleTimer from "react-idle-timer";
import { connect } from 'react-redux';

function IdleTimerContainer(props) {

    const idleTimerRef = useRef(null)

    const navigate = useNavigate();

    const onIdle = () => {

        props.startLogout()
        navigate('/login');
    }

    return (
        <div>
            <IdleTimer ref={idleTimerRef} timeout={10 * 60000 } onIdle={onIdle}></IdleTimer>
        </div>
    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    startLogout
}


export default connect(mapStateToProps, mapDispatchToProps)(IdleTimerContainer);