
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import TableView from '../Table/Table';
import { isoAdminHeadings,isoHeadings,tableHeadings } from '../../../utils/constants';
import { fetchAllClients, fetchClient, filteredData, setEdited, setModified } from '../../../redux/actions/IsoActions';
import { fetchMerchantByClient } from '../../../redux/actions/MerchantActions';
import { connect } from "react-redux";
import moment from 'moment';
import { setMerchantEdit, setMerchantID } from '../../../redux/actions/MerchantActions';

import { Panel, PanelBody, PanelHeader } from "../../header/panel/panel"


const ISOView = (props) => {


    const navigate = useNavigate()

    const heading = props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ? isoAdminHeadings : isoHeadings


    useEffect(() => {
        let isoID = props.ISOREDUCER.isoID;
        if (isoID) {
            props.fetchClient(isoID)
            props.fetchMerchantByClient(isoID)

        }
        props.setModified(false)
        return () => {
            props.setEdited(false)
        }
    }, [])

    const handleEditClick = () => {
        navigate('/iso/edit')
    }

    const handleMerchantClick = (value) => {
        props.setMerchantID(value.data.merchantID)

        navigate('/merchant/view')

    }


    return (

        <div className="row mb-3">

            <div className="col-xl-4">

                <Panel>

                    <PanelHeader>

                        <div className="row mb-0">

                            <div className="col-xl-4">
                                ISO Profile
                            </div>

                            <div className="col-xl-6"></div>

                            <div className="col-xl-2">

                                {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ? <button className='btn btn-primary d-block w-30 btn-lg h-9px fs-11px' onClick={handleEditClick}>Edit</button> : null}

                            </div>

                        </div>


                    </PanelHeader>

                    <PanelBody>

                        <div className="table-responsive">

                            <table className="table mb-0">
                                <tbody>

                                    {props.ISOREDUCER.clientData && props.ISOREDUCER.clientData.length ?
                                        Object.keys(heading).map(data => {
                                            let key = data.includes('contact') || data.includes("address") ? data.split(".") : data
                                            if (typeof (key) != 'object') {
                                                if (props.ISOREDUCER.clientData[0][key] && props.ISOREDUCER.clientData[0][key].toString()) {
                                                    if (data == 'isoStatus') {
                                                        return (
                                                            <tr key={data}>
                                                                <th>{heading[data]}</th>
                                                                <th>:</th>
                                                                <td className={props.ISOREDUCER.clientData[0][key] == 1 ? 'approved' : props.ISOREDUCER.clientData[0][key] == 2 ? 'suspended' : props.ISOREDUCER.clientData[0][key] == 3 ? 'inactive' : 'reject'}>{props.ISOREDUCER.clientData[0][key] == 1 ? 'Active' : props.ISOREDUCER.clientData[0][key] == 2 ? 'Suspended' : props.ISOREDUCER.clientData[0][key] == 3 ? 'InAcive' : 'Deleted'}</td>
                                                            </tr>
                                                        )
                                                    } else {
                                                        return (
                                                            <tr key={data}>
                                                                <th>{heading[data]}</th>
                                                                <th>:</th>
                                                                <td>{key.toLowerCase().includes('date') ? moment(props.ISOREDUCER.clientData[0][key]).format('MMM DD, YYYY HH:MM:SS') : props.ISOREDUCER.clientData[0][key]}</td>
                                                            </tr>
                                                        )
                                                    }
                                                }
                                            } else {
                                                if (props.ISOREDUCER.clientData[0][key[0]][key[1]]) {
                                                    return (
                                                        <tr key={data}>
                                                            <th>{heading[data]}</th>
                                                            <th>:</th>
                                                            <td>{props.ISOREDUCER.clientData[0][key[0]][key[1]] ? props.ISOREDUCER.clientData[0][key[0]][key[1]] : '-'}</td>
                                                        </tr>
                                                    )
                                                }
                                            }
                                        }
                                        ) : null}
                                </tbody>
                            </table>
                        </div>

                    </PanelBody>
                </Panel>


            </div>

            <div className="col-xl-8">

                <Panel>
                    <PanelHeader>Merchants</PanelHeader>
                    <TableView height={600} headings={tableHeadings.merchantsSubList} data={props.MERCHANTREDUCER.allMerchants} handleClick={handleMerchantClick} />
                </Panel>
            </div>

        </div>

    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchAllClients,
    fetchClient,
    filteredData,
    setEdited,
    setMerchantEdit,
    setMerchantID,
    setModified,
    fetchMerchantByClient
}


export default connect(mapStateToProps, mapDispatchToProps)(ISOView);