const initialState = { terminalsData: [], terminalData: [], terminalBatchData: [], terminalHistory: [], cassetteData: [], filteredData: [], edited: false, terminalID: null, isExist: false, modified: false, isSerial: false, loadingBatchData:  false };

export const TERMINALREDUCER = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TERMINALS':
            return {
                ...state,
                terminalsData: action.data,
                terminalData: state.terminalData
            }
        case 'GET_TERMINAL_DATA':
            return {
                ...state,
                terminalData: action.data,
                terminalsData: state.terminalsData
            }
        case 'FILTER_TERMINALS':
            return {
                ...state,
                terminalsData: state.terminalsData,
                filteredData: action.data
            }
        case 'SET_EDIT':
            return {
                ...state,
                edited: action.data
            }
        case 'SET_ID':
            return {
                ...state,
                terminalID: action.data
            }
        case 'SET_EXIST':
            return {
                ...state,
                isExist: action.data
            }
        case 'SET_SERIAL':
            return {
                ...state,
                isSerial: action.data
            }
        case 'SET_MODIFIED':
            return {
                ...state,
                modified: action.data
            }
        case 'SET_BATCH_DATA':
            return {
                ...state,
                terminalBatchData: action.data
            }
        case 'SET_TERMINAL_HISTORY':
            return {
                ...state,
                terminalHistory: action.data
            }
        case 'SET_CASSETTE_DATA':
            return{
                ...state,
                cassetteData: action.data
            }    
        case 'LOADING_BATCH_DATA' : 
        return {
            ...state,
            loadingBatchData: action.data
        }    
        default:
            return state;
    }
}