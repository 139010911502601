import React, { useState, useEffect } from 'react';
import TableView from '../Table/Table';
// import { tableHeadings } from '../../../utils/constants';
import { fetchAllUsers, fetchUser, filteredData, updatePassword, setModified, fetchAssignments, assignUser, unAssignUser } from '../../../redux/actions/UserActions';
import { connect } from "react-redux";
import { setSnackbar } from '../../../redux/actions/SnackbarAction';
import { fetchMerchants } from '../../../redux/actions/dropdownActions';

import { Panel, PanelBody, PanelHeader } from "../../header/panel/panel"

import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';

const UserAssignments = (props) => {

    const [terminalError, setTerminalError] = useState(false)
    const [merchantError, setMerchantError] = useState(false)
    const [isoError, setIsoError] = useState(false);

    const [isoValue, setIso] = useState('');
    const [merchantID, setMerchantID] = useState('')
    const [terminal, setTerminal] = useState('');

    const [terminalDropdown, setTerminalDropdown] = useState([])
    const [merchantDropdown, setMerchantDropdown] = useState([])
    const [infoModelOpen, setInfoModelOpen] = useState(false);

    const [data, setData] = useState(null)

    useEffect(() => {
        let userID = props.USERSREDUCER.userID;
        if ([3, 5, 6, 7].includes(props.USERSREDUCER.userData[0]?.userRoleId)) {
            console.log('In use effect user assignmnets...')
            props.fetchAssignments(userID)
        }
    }, [])


    const handleAssignment = (event) => {
        let targetValue = event.target.value
        let targetName = event.target.name

        switch (targetName) {
            case 'isoValue':
                setIsoError(false);
                setIso(targetValue);
                setTerminal('');
                setTerminalDropdown([])
                let merchants = props.DROPDOWNREDUCER.isosData.filter(iso => {
                    return iso.isoID == targetValue
                })
                setMerchantDropdown(merchants[0]?.merchants)

                break;
            case 'merchantID':
                setMerchantError(false);
                setMerchantID(targetValue)
                setTerminal('');
                props.fetchMerchants(targetValue)
                break;
            case 'terminalID':
                setTerminalError(false);
                setTerminal(targetValue)
                break;
            default:
                break;
        }

    }

    const handleTerminalDrop = () => {

        if (props.DROPDOWNREDUCER.merchantsData && props.DROPDOWNREDUCER.merchantsData.length) {
            setTerminalDropdown(props.DROPDOWNREDUCER.merchantsData)
        } else {
            setTerminalDropdown([])
        }

    }

    const handleDeleteButton = (value) => {
        setInfoModelOpen(true)
    }

    const handleDeleteConfirmation = (value) => {
        console.log(value.data)
        setInfoModelOpen(true)
        setData(value)

    }

    const onCellClicked = (value) => {
        setData(value)
    }


    const submitUnassignment = (event) => {

        console.log(data?.data?.assigneeID)
        // event.preventDefault()

        let body = {}
        body.userID = props.USERSREDUCER.userData[0].id
        body.assignee = data?.data?.assigneeID

        console.log(body)

        if ((body.userID && body.assignee)) {
            props.unAssignUser(body)
            setInfoModelOpen(false)
        }
    }
    
    const DELETE_CONFIRMATION = (
        <p className="lead text-dark">
            <SweetAlert danger showCancel
                confirmBtnText="Yes!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                onConfirm={ submitUnassignment}
                onCancel={() => setInfoModelOpen(!infoModelOpen)}
            >

               <b> Are you sure you want to unassign - {data?.data?.assignee}</b>
                                    
            </SweetAlert>
        </p>
    )

    const tableHeadings = {

        assignments: [
            {
                field: 'assigneeType', tooltipField: 'assigneeType', headerName: 'Assignee Type', minWidth: 160, cellRenderer: function (params) {
                    return params.data.assigneeType == 1 ? 'ISO' : params.data.assigneeType == 2 ? "Merchant" : params.data.assigneeType == 3 ? "Terminal ID" : ""
                }
            },
            {
                field: 'assignee', tooltipField: 'assignee', headerName: 'Assignee', minWidth: 200, cellRenderer: function (params) {
                    return <div>{params.value}</div>
                }
            },
            { field: 'createBy', tooltipField: 'createBy', headerName: 'Created By', minWidth: 200, },
            {
                field: 'createDate', tooltipField: 'createDate', headerName: 'Create Date', minWidth: 200, cellRenderer: function (params) {
                    return params.value ? moment(params.value).format('MMM DD, YYYY HH:mm:ss') : "N/A";
                }
            },
            {
                field: 'Action', tooltipField: 'action', headerName: 'Action', minWidth: 120, cellRenderer: function (params) {
                    return <button className="btn btn-danger btn-icon btn-circle btn-sm me-1" onClick={handleDeleteButton}>X</button>
                }
            },
        ],

    }

    const submitAssignment = (event) => {

        event.preventDefault()

        let body = {}
        body.userID = props.USERSREDUCER.userData[0].id

        console.log(merchantID)

        if (!isoValue) {
            setIsoError(true)
        }

        if (!merchantID) {
            setMerchantError(true)
        }

        if (props.USERSREDUCER.userData[0].userRoleId == 5 && !terminal) {
            setTerminalError(true)
        }
        if (props.USERSREDUCER.userData[0].userRoleId == 3 && merchantID) {
            body.assignee = merchantID
            body.assigneeType = 2
            setMerchantError(false)
        }

        if (props.USERSREDUCER.userData[0].userRoleId == 5 && terminal) {
            body.assignee = terminal
            body.assigneeType = 3
            setTerminalError(false)
        }

        if (props.USERSREDUCER.userData[0].userRoleId == 6 || props.USERSREDUCER.userData[0].userRoleId == 7) {
            body.assignee = isoValue
            body.assigneeType = 1
            setTerminalError(false)
            setMerchantError(false)
        }

        console.log(body)

        if ((props.USERSREDUCER.userData[0].userRoleId == 5 && terminal && terminal.length) || (props.USERSREDUCER.userData[0].userRoleId == 3 && merchantID) || ((props.USERSREDUCER.userData[0].userRoleId == 6 || props.USERSREDUCER.userData[0].userRoleId == 7) && isoValue)) {
            setTerminal('')
            setIso('')
            setMerchantID('')
            props.assignUser(body)
        }
    }

    let USER_ASSIGNMENT = (
        <form className="fs-13px">

            <div className="mb-3">

                <div className="row gx-3">


                    {[3, 5, 6, 7].includes(props.USERSREDUCER.userData[0]?.userRoleId) ?

                        <div className="col-md-3 mb-2 mb-md-0">

                            <label className={"col-md-3 " + (isoError ? 'text-danger' : '')}><b>ISO</b></label>
                            <select className="form-select"
                                value={isoValue ? isoValue : ''}
                                error={isoError}
                                onChange={handleAssignment}
                                name='isoValue'
                            >
                                <option value=''>Select ISO</option>

                                {props.DROPDOWNREDUCER.isosData.map((option) => (
                                    <option key={option.isoID} value={option.isoID}>
                                        {option.isoName}
                                    </option>
                                ))}

                            </select>
                        </div>
                        : null}
                    {[3, 5].includes(props.USERSREDUCER.userData[0]?.userRoleId) ?
                        <div className="col-md-3 mb-2 mb-md-0">
                            <label className={"col-md-3 " + (merchantError ? 'text-danger' : '')}><b>Merchant</b></label>

                            <select className="form-select"
                                value={merchantID ? merchantID : ''}
                                error={merchantError}
                                onChange={handleAssignment}
                                onBlur={handleTerminalDrop}
                                name='merchantID'
                            >

                                <option value=''>Select Merchant</option>
                                {merchantDropdown.map((option) => (
                                    <option key={option.merchantID} value={option.merchantID}>
                                        {option.merchantName}
                                    </option>
                                ))}
                            </select>
                        </div> : null}

                    {[5].includes(props.USERSREDUCER.userData[0]?.userRoleId) ?
                        <div className="col-md-3 mb-2 mb-md-0">
                            <label className={"col-md-6 " + (terminalError ? 'text-danger' : '')}><b>Terminal ID</b></label>

                            <select className="form-select"
                                value={terminal ? terminal : ''}
                                onChange={handleAssignment}
                                name='terminalID'
                            >

                                <option value=''>Select Terminal</option>
                                {terminalDropdown.map((option) => (
                                    <option key={option.terminalID} value={option.terminalID}>
                                        {option.terminalID}
                                    </option>
                                ))}
                            </select>
                        </div>
                        : null}

                    {[3, 5, 6, 7].includes(props.USERSREDUCER.userData[0]?.userRoleId) ?
                        <div className="col-md-3 mb-2 mb-md-0">
                            <label className={"col-md-6 "}></label>

                            <button className='btn btn-primary d-block w-100 btn-lg h-9px fs-13px' onClick={submitAssignment}>Assign</button>

                        </div> : null}


                </div>
                <br></br>
                <hr className="mb-10px bg-gray-600" />

                <div className="mb-3">
                    <div className="row gx-3">

                        {[3, 5, 6, 7].includes(props.USERSREDUCER.userData[0]?.userRoleId) ? <TableView height={600} headings={tableHeadings['assignments']} data={props.USERSREDUCER.assignedData} handleClick={onCellClicked} /> : null}
                    </div>

                </div>

            </div>

        </form >
    )

    return (
        <>
            {infoModelOpen ?
                DELETE_CONFIRMATION : null
            }
            <Panel>
                <PanelHeader>Assignments</PanelHeader>

                <PanelBody>
                    {USER_ASSIGNMENT}
                </PanelBody>
            </Panel>
        </>
    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchAllUsers, fetchUser, filteredData,
    setSnackbar, updatePassword, setModified,
    fetchAssignments, fetchMerchants, assignUser, unAssignUser
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAssignments);