import React from 'react';
import { useLocation } from "react-router"
import { Navigate } from 'react-router-dom';
import { connect } from "react-redux";

const RequiredAuth = (props) => {

    const location = useLocation()

    return (

        props.LOGINREDUCER.ISAUTH && !props.LOGINREDUCER.IS_EMAIL_VERIFIED ? <Navigate to='/verifyEmail' state={{ from: location.pathname }} /> :

            props.LOGINREDUCER.ISAUTH && props.allowableRoles?.includes(String(props.LOGINREDUCER.USER.role))
                ? <>{props.children} </>
                : props.LOGINREDUCER.ISAUTH
                    ? <Navigate to='/error' state={{ from: location.pathname }} />
                    : <Navigate to='/login' state={{ from: location.pathname }} />
    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(RequiredAuth);