const initialState = { clientsData: [], clientData: [], filteredData: [], edited: false, modified: false, isoID: null };

export const ISOREDUCER = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CLIENTS':
            return {
                ...state,
                clientsData: action.data,
                clientData: state.clientData
            }
        case 'GET_CLIENT':
            return {
                ...state,
                clientsData: state.clientsData,
                clientData: action.data
            }
        case 'FILTER_CLIENTS':
            return {
                ...state,
                clientsData: state.clientsData,
                filteredData: action.data
            }
        case 'SET_EDIT':
            return {
                ...state,
                edited: action.data
            }
        case 'SET_MODIFIED':
            return {
                ...state,
                modified: action.data
            }
        case 'SET_ID':
            return {
                ...state,
                isoID: action.data
            }

        default:
            return state;
    }
}