import React, { createContext, useContext, useState } from "react";
import { removeUserSession, setUserSession,getUser,getRole  } from "../utils/SecurityUtils";


const AuthContext = createContext(null)

export const AuthProvider = ({children}) =>{


    const login = (user) => {
        setUserSession(user.token, user.user, user.role, user.isEmailVerified);
    }

    const logout = () =>{
        removeUserSession()
    }


    return  (

        <AuthContext.Provider value = {{getUser, getRole, login, logout}}>
            {children}
        </AuthContext.Provider>
    )

}

export const useAuth = () =>{
    return useContext(AuthContext)
}

