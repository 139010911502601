import React, { useEffect, useState } from 'react';
import { addMerchant, editMerchant } from "../../../redux/actions/MerchantActions";
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { STATUS, STATES } from '../../../utils/constants';
import { setMerchantEdit, setModified } from '../../../redux/actions/MerchantActions';
import { fetchIsos, fetchProcessors, fetchTerminalMake, fetchReportTypes, fetchPrefix } from "../../../redux/actions/dropdownActions";
import { Panel, PanelBody, PanelHeader } from '../../header/panel/panel';
import InputMask from 'react-input-mask';


const AddEditMerchants = (props) => {
    const navigate = useNavigate()
    const [isoValue, setIsoValue] = useState('');
    const [merchant_name, setMerchantName] = useState('');
    const [status, setStatus] = useState(1);
    const [notes, setNotes] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [state, setState] = useState('');
    const [phone, setPhone] = useState('');
    const [contactName, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [defaultAddress, setDefaultAddress] = useState(false);
    const [defaultContact, setDefaultContact] = useState(false);

    const [isoError, setIsoError] = useState(false);
    const [merchantError, setMerchantError] = useState(false);
    const [statusError, setStatusError] = useState(false);
    const [notesError, setNotesError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [postalCodeError, setPostalCodeError] = useState(false);
    const [stateError, setStateError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [contactError, setContactError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [websiteError, setWebsiteError] = useState(false);

    useEffect(() => {
        if (props.edit) {
            let data = props.MERCHANTREDUCER.merchantInfo[0]
            setAddress(data?.address?.street)
            setCity(data?.address?.city)
            setPostalCode(data?.address?.postalCode)
            setState(data?.address?.state)
            setPhone(data?.contact?.phone)
            setContactName(data?.contact?.name)
            setEmail(data?.contact?.email)
            setWebsite(data?.contact?.website)
            setStatus(data?.merchantStatusID)
            setNotes(data?.notes)
            setMerchantName(data?.merchantName)
            setIsoValue(data?.isoID)
            setDefaultAddress(false)
            setDefaultContact(false)
        }
    }, [])
    useEffect(() => {
        props.fetchIsos()
    }, [])

    useEffect(() => {
        if (!props.edit) handleReset()
    }, [props.edit])

    useEffect(() => {
        if (props.MERCHANTREDUCER.modified) {
            navigate('/merchant/view')
        }
    }, [props.MERCHANTREDUCER.modified])

    const handleChange = (event) => {
        let targetName = event.target.name
        let targetValue = event.target.value

        if (targetValue) {

            switch (targetName) {
                case 'isoID':
                    setIsoError(false);
                    setIsoValue(targetValue);
                    break;
                case 'merchantName':
                    setMerchantError(false);
                    setMerchantName(targetValue)
                    break;
                case 'merchantStatusID':
                    setStatusError(false)
                    setStatus(targetValue)
                    break
                case 'notes':
                    setNotesError(false);
                    setNotes(targetValue);
                    break
                case 'contactName':
                    setContactError(false);
                    setContactName(targetValue);
                    break
                case 'merchantEmail':
                    setEmailError(false);
                    setEmail(targetValue);
                    break
                case 'merchantPhone':
                    setPhoneError(false);
                    setPhone(targetValue);
                    break
                case 'merchantWebsite':
                    setWebsiteError(false);
                    setWebsite(targetValue)
                    break
                case 'address':
                    setAddressError(false);
                    setAddress(targetValue)
                    break
                case 'city':
                    setCityError(false)
                    setCity(targetValue);
                    break
                case 'state':
                    setStateError(false);
                    setState(targetValue)
                    break
                case 'zip':
                    setPostalCodeError(false);
                    setPostalCode(targetValue)
                    break
                default:
                    break
            }
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        let body = {}
        if (isoValue) {
            setIsoError(false);
            body.isoID = isoValue
        } else {
            setIsoError(true)
        }

        if (merchant_name && merchant_name.trim()) {
            setMerchantError(false);
            body.merchantName = merchant_name
        } else {
            setMerchantError(true)
        }

        if (status) {
            setStatusError(false);
            body.merchantStatusID = status
        } else {
            setStatusError(true)
        }

        if (notes && notes.trim()) {
            setNotesError(false);
            body.notes = notes
        } else {
            setNotesError(true)
        }

        let addressItems = {}
        if (address && address.trim()) {
            setAddressError(false);
            addressItems.street = address;
        } else {
            setAddressError(true)
        }

        if (city && city.trim()) {
            setCityError(false);
            addressItems.city = city;
        } else {
            setCityError(true)
        }

        if (postalCode && postalCode.trim()) {
            setPostalCodeError(false);
            addressItems.postalCode = postalCode;
        } else {
            setPostalCodeError(true)
        }

        if (state && state.trim()) {
            setStateError(false);
            addressItems.state = state;
        } else {
            setStateError(true)
        }

        let contactItems = {}
        if (email && email.trim()) {
            setEmailError(false);
            contactItems.email = email;
        } else {
            setEmailError(true)
        }

        if (contactName && contactName.trim()) {
            setContactError(false);
            contactItems.name = contactName;
        } else {
            setContactError(true)
        }
        if (phone && phone.trim()) {
            setPhoneError(false);
            contactItems.phone = phone;
        } else {
            setPhoneError(true)
        }
        if (website && website.trim()) {
            setWebsiteError(false);
            contactItems.website = website;
        } else {
            setWebsiteError(true)
        }
        body.contact = contactItems;
        body.address = addressItems;

        if (isoValue && merchant_name && status && address && city && postalCode &&
            state && phone && contactName && email && website && !props.edit) {
            props.addMerchant(body)
        } else if (isoValue && merchant_name && status && address && city && postalCode &&
            state && phone && contactName && email && website && props.edit) {
            body = {
                ...props.MERCHANTREDUCER.merchantInfo[0],
                ...body
            }
            props.editMerchant(body)
            props.setMerchantEdit(true)
        }
    }

    const handleResetPreventingRefresh = (event) => {
        event.preventDefault();
        handleReset()

    }

    const handleEditCancel = (event) => {
        navigate('/merchant/view');
    }


    const handleReset = () => {
        setIsoValue('');
        setMerchantName('')
        setState('')
        setStatus(1)
        setAddress('')
        setCity('')
        setPostalCode('')
        setState('')
        setPhone('')
        setContactName('')
        setEmail('')
        setWebsite('')
        setIsoError(false);
        setMerchantError(false);
        setStatusError(false)
        setContactError(false);
        setEmailError(false);
        setPhoneError(false);
        setWebsiteError(false);
        setAddressError(false);
        setCityError(false)
        setStateError(false);
        setPostalCodeError(false);
        setDefaultAddress(false)
        setDefaultContact(false)
    }
    const handleDefaultContact = (event) => {
        setDefaultContact(!defaultContact)
        if (!defaultContact) {
            setContactName('Barma')
            setEmail('Contact@barmatechnologies.com')
            setPhone('9999999999')
            setWebsite('go-barmatech.com')
            setContactError(false);
            setEmailError(false);
            setPhoneError(false);
            setWebsiteError(false);
        } else {
            setContactName('')
            setEmail('')
            setPhone('')
            setWebsite('')
        }
    }

    const handleDefaultAddress = (event) => {
        setDefaultAddress(!defaultAddress)
        if (!defaultAddress) {
            setAddress('8551 W Sunrise Blvd')
            setCity('Plantation')
            setState('FL')
            setPostalCode('33322')
            setAddressError(false);
            setCityError(false)
            setStateError(false);
            setPostalCodeError(false);
        } else {
            setAddress('')
            setCity('')
            setState('')
            setPostalCode('')
        }
    }

    let MERCHANTSFORMLATEST = (

        <form className="fs-13px">

            <div className="mb-3">

                <div className="row gx-3">

                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (merchantError ? 'text-danger' : '')}><b>Merchant Name</b></label>

                        <input
                            type="text" className="form-control fs-13px"
                            required
                            id="merchant_name"
                            label="Merchant Name"
                            name="merchantName"
                            autoComplete="merchantName"
                            onChange={handleChange}
                            value={merchant_name ? merchant_name : ''}
                        />
                    </div>

                </div>


            </div>

            <div className="mb-3">


                <div className="row gx-6">

                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-3 " + (isoError ? 'text-danger' : '')}><b>ISO</b></label>
                        <select className="form-select"
                            value={isoValue ? isoValue : ''}
                            error={isoError}
                            onChange={handleChange}
                            name='isoID'
                        >
                            <option value=''>Select ISO</option>

                            {props.DROPDOWNREDUCER.isosData.map((option) => (
                                <option key={option.isoID} value={option.isoID}>
                                    {option.isoName}
                                </option>
                            ))}

                        </select>
                    </div>

                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-3 " + (statusError ? 'text-danger' : '')}><b>Status</b></label>

                        <select className="form-select"
                            value={status ? status : 1}
                            error={statusError}
                            onChange={handleChange}
                            name='merchantStatusID'
                        >

                            {STATUS.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.status}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

            </div>
            <hr className="mb-10px bg-gray-600" />

            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-3 mb-2 mb-md-0">
                        <h5>
                            <b>
                                Contact
                            </b>
                        </h5>

                    </div>
                    <div className="col-md-3 mb-2 mb-md-0">
                        <label className="form-check-label" >
                            <b>
                                Set Default
                            </b>
                            &nbsp;&nbsp;&nbsp;
                            <input className="form-check-input" type="checkbox" checked={defaultContact} onChange={handleDefaultContact} >
                            </input>
                        </label>
                    </div>
                </div>
            </div>


            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (contactError ? 'text-danger' : '')}><b>Contact Name</b></label>
                        <input type="text" className="form-control fs-13px"
                            name="contactName"
                            onChange={handleChange}
                            value={contactName ? contactName : ''}
                            error={contactError}
                        />
                    </div>
                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (emailError ? 'text-danger' : '')}><b>Email</b></label>
                        <input type="text" className="form-control fs-13px"
                            name="merchantEmail"
                            onChange={handleChange}
                            value={email ? email : ''}
                            error={emailError}
                        />
                    </div>
                </div>

            </div>
            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (phoneError ? 'text-danger' : '')}><b>Phone</b></label>
                        <InputMask 
                            mask="(999) 999-9999" 
                            name="merchantPhone"
                            className="form-control" 
                            placeholder="(999) 999-9999"                             
                            onChange={handleChange}
                            value={phone ? phone : ''}
                            error={phoneError}
                        ></InputMask>

                    </div>
                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (emailError ? 'text-danger' : '')}><b>Website</b></label>
                        <input type="text" className="form-control fs-13px"
                            name="merchantWebsite"
                            onChange={handleChange}
                            value={website ? website : ''}
                            error={websiteError}
                        />
                    </div>
                </div>

            </div>


            <hr className="mb-10px bg-gray-600" />


            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-3 mb-2 mb-md-0">
                        <h5>
                            <b>
                                Address
                            </b>
                        </h5>

                    </div>
                    <div className="col-md-3 mb-2 mb-md-0">
                        <label className="form-check-label" >
                            <b>
                                Set Default
                            </b>
                            &nbsp;&nbsp;&nbsp;
                            <input className="form-check-input" type="checkbox" checked={defaultAddress} onChange={handleDefaultAddress} >
                            </input>
                        </label>
                    </div>
                </div>
            </div>


            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-6 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (emailError ? 'text-danger' : '')}><b>Street</b></label>
                        <input type="text" className="form-control fs-13px"
                            name="address"
                            onChange={handleChange}
                            value={address ? address : ''}
                            error={addressError}
                        />
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-2 mb-2 mb-md-0">
                        <label className={"col-md-6 " + (cityError ? 'text-danger' : '')}><b>City</b></label>
                        <input type="text" className="form-control fs-13px"
                            name="city"
                            onChange={handleChange}
                            value={city ? city : ''}
                            error={cityError}
                        />
                    </div>

                    <div className="col-md-2 mb-2 mb-md-0">
                        <label className={"col-md-3 " + (statusError ? 'text-danger' : '')}><b>State</b></label>
                        <select className="form-select"

                            value={state ? state : ''}
                            error={stateError}
                            onChange={handleChange}
                            name='state'
                        >
                            <option value=''>Select State</option>

                            {STATES.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.value}
                                </option>
                            ))}
                        </select>
                    </div>


                    <div className="col-md-2 mb-2 mb-md-0">

                        <label className={"col-md-6 " + (cityError ? 'text-danger' : '')}><b>Zip</b></label>
                        <input type="text" className="form-control fs-13px"
                            name="zip"
                            onChange={handleChange}
                            value={postalCode ? postalCode : ''}
                            error={postalCodeError}
                        />
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <div className="row gx-6">
                    <div className="col-md-3 mb-2 mb-md-0">
                        <button className='btn btn-primary d-block w-100 btn-lg h-9px fs-13px' onClick={handleSubmit}>{props.edit ? 'Save' : 'Add'}</button>
                    </div>
                    <div className="col-md-3 mb-2 mb-md-0">
                        {props.edit ? <button className='btn btn-gray d-block w-100 btn-lg h-9px fs-13px' onClick={handleEditCancel} >Cancel</button>
                            : <button className='btn btn-gray d-block w-100 btn-lg h-9px fs-13px' onClick={handleResetPreventingRefresh}>Reset</button>}
                    </div>
                </div>

            </div>
        </form >
    )
    return (

        <Panel>
            <PanelHeader>Merchant Form</PanelHeader>
            <PanelBody>
                {MERCHANTSFORMLATEST}
            </PanelBody>
        </Panel>

    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    addMerchant,
    editMerchant,
    setMerchantEdit,
    setModified,
    fetchIsos, fetchProcessors, fetchTerminalMake,
    fetchReportTypes, fetchPrefix
}


export default connect(mapStateToProps, mapDispatchToProps)(AddEditMerchants);