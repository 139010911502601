import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import { fetchMerchants } from '../../../redux/actions/dropdownActions';
import { BUSINSESS, tableHeadings } from '../../../utils/constants';

import { Panel, PanelBody, PanelHeader } from '../../header/panel/panel';
import TableView from "../Table/Table";

import { fetchAllTerminals } from '../../../redux/actions/TerminalActions';
import { fetchIsos } from "../../../redux/actions/dropdownActions";
import { getDetailedReport, getSummaryReport, getDetailedReportByISO, getSummaryReportByISO, setFetchingReport } from '../../../redux/actions/ExportActions';
import { setSnackbar } from "../../../redux/actions/SnackbarAction";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import addDays from 'date-fns/addDays';
import moment from 'moment';

import * as XLSX from 'xlsx';
import * as XLSXPopulate from 'xlsx-populate/browser/xlsx-populate';
import jsPDF from 'jspdf';
import * as autoTable from 'jspdf-autotable'
import { Backdrop, CircularProgress } from '@mui/material';


const Reports = (props) => {

    const reportDropdown = [{ reportID: 1, reportName: 'Summary' }, { reportID: 2, reportName: 'Detailed' }]


    const [isoValue, setIsoValue] = useState('');
    const [merchantID, setMerchantID] = useState('')
    const [terminal, setTerminal] = useState('');
    const [reportType, setReportType] = useState('')
    const [businessDay, setBusinessDay] = useState(true)
    const [isDate, setIsDate] = useState(false);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const [isoError, setIsoError] = useState(false);
    const [statusError, setStatusError] = useState(false);
    const [merchantError, setMerchantError] = useState(false)
    const [terminalError, setTerminalError] = useState(false)
    const [startError, setStartError] = useState(false)
    const [endError, setEndError] = useState(false)
    const [businessError, setBusinessError] = useState(false)
    const [reportError, setReportError] = useState(false)

    const [merchantDropdown, setMerchantDropdown] = useState([])
    const [terminalDropdown, setTerminalDropdown] = useState([])

    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [toMinDate, setToMinDate] = useState(new Date());
    const [toMaxDate, setToMaxDate] = useState(new Date());

    const [infoModelOpen, setInfoModelOpen] = useState(false);


    const [maxDays, setMaxDays] = useState(31)

    useEffect(() => {
        props.fetchIsos()
        setMaxDate(moment(new Date()).format('YYYY-MM-DD'))
        if(props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 || props.LOGINREDUCER.USER.role == 7 ){
            setMinDate('2021-01-01')
        }else {
            setMinDate('2022-01-01')
        }
        if (props.LOGINREDUCER.USER.role === 1) {
            setMaxDays(90)
        }
        if(props.LOGINREDUCER.USER.role == 4 ){
            console.log(props.DROPDOWNREDUCER.isosData[0].merchants[0].merchantID)
            setMerchantID(props.DROPDOWNREDUCER.isosData[0]?.merchants[0]?.merchantID)
            props.fetchMerchants(props.DROPDOWNREDUCER.isosData[0]?.merchants[0]?.merchantID)

        }
    }, [])

    // useEffect(()=>{

    //     handleTerminalDrop()
    //     console.log('UseEffect 2 : ' + terminalDropdown)

    // },[props.DROPDOWNREDUCER.merchantsData])


    const handleChange = (event) => {
        let targetName = event.target.name
        let targetValue = event.target.value

        switch (targetName) {

            case 'reportType':
                if (targetValue) {
                    setReportError(false);
                    setReportType(targetValue)
                }
                break;

            case 'isoID':
                if (targetValue) {

                    setIsoError(false)
                    setIsoValue(targetValue);
                    let merchants = props.DROPDOWNREDUCER.isosData.filter(iso => {
                        return iso.isoID == targetValue
                    })

                    if (props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 2 || props.LOGINREDUCER.USER.role == 6) {
                        console.log(merchants[0]?.merchants)
                        setMerchantDropdown(merchants[0]?.merchants)

                        console.log('merchant drop : ' + merchantDropdown)
                    } else {
                        setMerchantDropdown(merchants[0]?.merchants)
                    }
                }
                break;

            case 'merchantID':
                if (targetValue) {
                    setMerchantError(false);
                    setMerchantID(targetValue)
                    props.fetchMerchants(targetValue)
                }
            case 'terminalID':
                setTerminalError(false);
                setTerminal(targetValue)
                break;

            case 'startDate':
                if (moment(new Date(targetValue)).isBefore(new Date())) {
                    setStartDate(targetValue);
                    setStartError(false)
                    if (moment(addDays(new Date(targetValue), maxDays)).isBefore(new Date())) {
                        setToMaxDate(moment(addDays(new Date(targetValue), maxDays)).format('YYYY-MM-DD'))
                    } else {
                        setToMaxDate(moment(new Date()).format('YYYY-MM-DD'))
                    }
                    setToMinDate(moment(addDays(new Date(targetValue), 1)).format('YYYY-MM-DD'))
                } else {
                    setStartError(true)
                }

                break;

            case 'endDate':

                if (moment(addDays(new Date(startDate), maxDays)).isAfter(new Date(targetValue))) {
                    setEndError(false)
                    setEndDate(targetValue);
                } else {
                    setEndError(true)
                }
                break;
            case 'daterange':
                setIsDate(event.target.checked)
                break;

            case 'businessDay':
                setBusinessError(false);
                setBusinessDay(targetValue);
                break;
            case 'reportType':
                setReportError(false);
                setReportType(targetValue)
                break;

            default:
                break;
        }
    }

    const handleSubmit = () => {
        let body = {}
        body.businessDay = businessDay
        body.dateRange = isDate

        if (!reportType) {
            setReportError(true)
        } else {
            setReportError(false)
        }

        if (startDate) {
            body.startDate = startDate
        } else {
            setStartError(true)
        }
        if (isDate) {
            if (endDate) {
                body.endDate = endDate
            } else {

                setEndError(true)
            }
        }

        if (businessDay == "3") {
            body.reportType = "2"
            body.businessDay = false
        } else {
            body.reportType = "1"
        }

        if (merchantID && merchantID == "ALL MERCHANTS") {

            body.byISO = true;
            body.isoID = isoValue;

            if (!isoError && !reportError && !businessError) {

                if ((isDate && moment(addDays(new Date(startDate), maxDays)).isAfter(new Date(endDate))) || !isDate) {

                    if (reportType && JSON.stringify(businessDay) && JSON.stringify(isDate) && startDate && reportType == 1) {
                        props.setFetchingReport(true)
                        props.getSummaryReportByISO(body)
                    } else if (reportType && JSON.stringify(businessDay) && JSON.stringify(isDate) && startDate && reportType == 2) {
                        props.setFetchingReport(true)
                        props.getDetailedReportByISO(body)
                    }
                } else {
                    props.setSnackbar(true, 'error', 'Maximum Days Should be less than or Equal to 1 Month')
                }
            } else {
                props.setSnackbar(true, 'error', 'Please select all fields')

            }

        } else {


            if (terminal && terminal == "ALL TERMINALS") {
                body.byMerchant = true;
                body.merchantID = merchantID
            } else {
                body.byMerchant = false;
                body.merchantID = merchantID
                body.terminalID = terminal
            }

            if (!isoError && !merchantError && !terminalError && !reportError && !businessError) {


                if ((isDate && moment(addDays(new Date(startDate), maxDays)).isAfter(new Date(endDate))) || !isDate) {

                    if (reportType && JSON.stringify(businessDay) && JSON.stringify(isDate) && startDate && reportType == 1) {
                        props.setFetchingReport(true)
                        props.getSummaryReport(body)
                    } else if (reportType && JSON.stringify(businessDay) && JSON.stringify(isDate) && startDate && reportType == 2) {
                        props.setFetchingReport(true)
                        props.getDetailedReport(body)
                    }
                } else {
                    props.setSnackbar(true, 'error', 'Maximum Days Should be less than or Equal to 1 Month')
                }
            } else {
                props.setSnackbar(true, 'error', 'Please select all fields')

            }
        }

    }


    const handleTerminalDrop = () => {

        if (props.DROPDOWNREDUCER.merchantsData && props.DROPDOWNREDUCER.merchantsData.length) {
            setTerminalDropdown([{ terminalID: "ALL TERMINALS" }, ...props.DROPDOWNREDUCER.merchantsData])
            console.log('Terminal Dropdown : ' + terminalDropdown)
        } else {
            setTerminalDropdown([])
        }
    }



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const downloadXLSX = (ext) => {
        exportXLSX().then(url => {
            const downloadAnchorNode = document.createElement('a')
            downloadAnchorNode.setAttribute('href', url)
            downloadAnchorNode.setAttribute('download', `${Object.keys(props.EXPORTREDUCER.exportData).includes('reportDetails') ? 'Detailed Report' : 'Summary Report'}.${ext}`)
            downloadAnchorNode.click()
            downloadAnchorNode.remove()
        })
    }

    const downloadPDF = () => {
        const doc = new jsPDF();
        let title = `${Object.keys(props.EXPORTREDUCER.exportData).includes('reportDetails') ? 'Detailed Report' : 'Summary Report'}`
        // doc.text(title, 20, 10)
        doc.text(props.EXPORTREDUCER.exportData.headingName, 20, 10)
        let column, transactionCol = [
            { title: 'Total Transactions', field: 'transaction' },
            { title: 'Total Approval Count', field: 'approval' },
            { title: 'Approved Withdrawals', field: 'wthApprovalCount' },
            { title: 'Total Declined Count', field: 'decline' },
            { title: 'Total Amount', field: 'amount' },
            { title: 'Total Fee', field: 'fee' },
        ];
        let trasactionBody = []
        trasactionBody.push({
            transaction: props.EXPORTREDUCER.exportData.transactionTotals.totalTransactions,
            approval: props.EXPORTREDUCER.exportData.transactionTotals.totalApprovalCount,
            wthApprovalCount: props.EXPORTREDUCER.exportData.transactionTotals.totalWthApprovalCount,
            decline: props.EXPORTREDUCER.exportData.transactionTotals.totalDeclineCount,
            amount: props.EXPORTREDUCER.exportData.transactionTotals.totalAmount,
            fee: props.EXPORTREDUCER.exportData.transactionTotals.totalFee
        })
        if (Object.keys(props.EXPORTREDUCER.exportData).includes('reportDetails')) {
            column = [
                { title: 'Terminal ID', field: 'terminalID' },
                (props.LOGINREDUCER.USER.role === 1 || props.LOGINREDUCER.USER.role === 6) ? {
                    title: 'Processor Terminal ID', field: 'processorTermID'
                } : {},
                { title: 'Merchant', field: 'merchantName' },
                { title: 'Transaction Time', field: 'tranTime' },
                { title: 'Settled Date', field: 'businessDate' },
                { title: 'Transaction Type', field: 'tranType' },
                { title: 'Sequence Number', field: 'seqNumber' },
                { title: 'Amount', field: 'amount' },
                { title: 'Fee', field: 'fee' },
                { title: 'Pan', field: 'pan' },
                { title: 'Transaction Response', field: 'tranResponse' },
                { title: 'Statement Descriptor', field : 'bankStmtDescription'}
            ]
        } else {
            column = [
                { title: 'Terminal ID', field: 'terminalID' },
                (props.LOGINREDUCER.USER.role === 1 || props.LOGINREDUCER.USER.role === 6) ? {
                    title: 'Processor Terminal ID', field: 'processorTermID'
                } : {},
                { title: 'Merchant', field: 'merchantName' },
                { title: 'Date', field: 'transactionDate' },
                { title: 'Total Transactions', field: 'totalTransactions' },
                { title: 'Approved', field: 'numOfApprovals' },
                { title: 'Approved Withdrawals', field: 'numOfWthApprovals' },
                { title: 'Declined', field: 'numOfDeclines' },
                { title: 'Amount', field: 'amount' },
                { title: 'Fee', field: 'fee' },
                { title: 'Statement Descriptor', field : 'bankStmtDescription'}
            ]
        }
        let bodyData = []
        if (Object.keys(props.EXPORTREDUCER.exportData).includes('reportDetails')) {
            props.EXPORTREDUCER.exportData.reportDetails.map(data => {
                bodyData.push({
                    ...data
                })
            })
            // bodyData = props.EXPORTREDUCER.exportData.reportDetails
        } else {
            bodyData = props.EXPORTREDUCER.exportData.summaryDetails
        }

        doc.autoTable({
            theme: 'grid',
            columns: transactionCol.map(col => ({ ...col, dataKey: col.field })),
            body: trasactionBody
        })

        doc.autoTable({
            theme: 'grid',
            columns: column.map(col => ({ ...col, dataKey: col.field })),
            body: bodyData
        })
        doc.save(`${title}.pdf`)
    }

    const exportXLSX = () => {
        const title = [{ A: props.EXPORTREDUCER.exportData.headingName }]

        let table1 = [{
            A: 'Total Transactions',
            B: 'Total Approval Count',
            C: 'Total Approved With Count',
            D: 'Total Declined Count',
            E: 'Total Amount',
            F: "Total Fee"
        }]
        let table2

        if (Object.keys(props.EXPORTREDUCER.exportData).includes('reportDetails')) {

            if (props.LOGINREDUCER.USER.role === 1 || props.LOGINREDUCER.USER.role === 6)
                table2 = [{
                    A: 'Terminal ID',
                    B: 'Processor Terminal ID',
                    C: 'Merchant',
                    D: 'Transaction Time',
                    E: 'Transaction Date',
                    F: 'Settled Date',
                    G: 'Transaction Type',
                    H: 'Sequence Number',
                    I: 'Amount',
                    J: 'Fee',
                    K: 'Pan',
                    L: 'Transaction Response',
                    M: 'Statement Descriptor'
                }]
            else {
                table2 = [{
                    A: 'Terminal ID',
                    B: 'Merchant',
                    C: 'Transaction Time',
                    D: 'Transaction Date',
                    E: 'Settled Date',
                    F: 'Transaction Type',
                    G: 'Sequence Number',
                    H: 'Amount',
                    I: 'Fee',
                    J: 'Pan',
                    K: 'Transaction Response',
                    L: 'Statement Descriptor'
                }]
            }
        } else {
            if (props.LOGINREDUCER.USER.role === 1 || props.LOGINREDUCER.USER.role === 6) {
                table2 = [{
                    A: 'Terminal ID',
                    B: 'Processor Terminal ID',
                    C: 'Merchant',
                    D: 'Date',
                    E: 'Total Transactions',
                    F: 'Total Approved',
                    G: 'Approved withdrawals',
                    H: 'Declined',
                    I: 'Amount',
                    J: 'Fee',
                    K: 'Statement Descriptor'
                }]

            } else {
                table2 = [{
                    A: 'Terminal ID',
                    B: 'Merchant',
                    C: 'Date',
                    D: 'Total Transactions',
                    E: 'Total Approved',
                    F: 'Approved withdrawals',
                    G: 'Declined',
                    H: 'Amount',
                    I: 'Fee',
                    J: 'Statement Descriptor'
                }]

            }

        }

        table1.push({
            A: props.EXPORTREDUCER.exportData.transactionTotals.totalTransactions,
            B: props.EXPORTREDUCER.exportData.transactionTotals.totalApprovalCount,
            C: props.EXPORTREDUCER.exportData.transactionTotals.totalWthApprovalCount,
            D: props.EXPORTREDUCER.exportData.transactionTotals.totalDeclineCount,
            E: props.EXPORTREDUCER.exportData.transactionTotals.totalAmount,
            F: props.EXPORTREDUCER.exportData.transactionTotals.totalFee
        })

        if (Object.keys(props.EXPORTREDUCER.exportData).includes('reportDetails')) {
            props.EXPORTREDUCER.exportData.reportDetails.map(data => {
                // let dt = moment(data.tranTime).format('MM-DD-YYYY HH:mm:ss')
                if (props.LOGINREDUCER.USER.role === 1 || props.LOGINREDUCER.USER.role === 6) {
                    table2.push({
                        A: data.terminalID,
                        B: data.processorTermID,
                        C: data.merchantName,
                        D: data.tranTime,
                        E: data.tranDate,
                        F: data.businessDate,
                        G: data.tranType,
                        H: data.seqNumber,
                        I: data.amount,
                        J: data.fee,
                        K: data.pan,
                        L: data.tranResponse,
                        M: data.bankStmtDescription

                    })

                } else {
                    table2.push({
                        A: data.terminalID,
                        B: data.merchantName,
                        C: data.tranTime,
                        D: data.tranDate,
                        E: data.businessDate,
                        F: data.tranType,
                        G: data.seqNumber,
                        H: data.amount,
                        I: data.fee,
                        J: data.pan,
                        K: data.tranResponse,
                        L: data.bankStmtDescription

                    })

                }
            })
        } else {
            props.EXPORTREDUCER.exportData.summaryDetails.map(data => {
                let dt = moment(data.transactionDate).format('MM-DD-YYYY')
                if (props.LOGINREDUCER.USER.role === 1 || props.LOGINREDUCER.USER.role === 6) {
                    table2.push({
                        A: data.terminalID,
                        B: data.processorTermID,
                        C: data.merchantName,
                        D: data.transactionDate,
                        E: data.totalTransactions,
                        F: data.numOfApprovals,
                        G: data.numOfWthApprovals,
                        H: data.numOfDeclines,
                        I: data.amount,
                        J: data.fee,
                        K: data.bankStmtDescription
                    })
                } else {
                    table2.push({
                        A: data.terminalID,
                        B: data.merchantName,
                        C: data.transactionDate,
                        D: data.totalTransactions,
                        E: data.numOfApprovals,
                        F: data.numOfWthApprovals,
                        G: data.numOfDeclines,
                        H: data.amount,
                        I: data.fee,
                        J: data.bankStmtDescription

                    })
                }


            })
        }


        let finalTable = [{ A: Object.keys(props.EXPORTREDUCER.exportData).includes('reportDetails') ? 'Detailed Report' : 'Summary Report', B: title }].concat([""]).concat([""]).concat([""]).concat(table1).concat([""]).concat([""]).concat(table2)
        // let finalData = [{ A: $title }].concat([""]).concat([""]).concat([""]).concat(table1).concat([""]).concat([""]).concat(table2)
        let finalData = [...title, ...finalTable]
        const wb = XLSX.utils.book_new();

        const sheet = XLSX.utils.json_to_sheet(finalData, { skipHeader: true })

        XLSX.utils.book_append_sheet(wb, sheet, Object.keys(props.EXPORTREDUCER.exportData).includes('reportDetails') ? 'Detailed Report' : 'Summary Report')

        const workbookBlob = workbook2blob(wb)
        const dataInfo = {
            titleCell: "A2",
            titleRange: "A1:I2",
            tBodyRange: `A5:J${finalData.length}`
        }
        return addStyles(workbookBlob, dataInfo)
    }

    const addStyles = (workbookBlob, dataInfo) => {
        return XLSXPopulate.fromDataAsync(workbookBlob).then(wb => {
            wb.sheets().forEach(sheet => {
                // sheet.usedRange.style({
                //     fontFamily: 'Arial',
                //     vertialAlignment: "center"
                // })

                sheet.column('A').width(15)
                sheet.column('B').width(30)
                sheet.column('C').width(30)
                sheet.column('D').width(15)
                sheet.column('E').width(20)
                sheet.column('F').width(15)
                sheet.column('G').width(20)
                sheet.column('H').width(15)
                sheet.column('I').width(20)
                sheet.column('J').width(20)
                sheet.column('K').width(20)
                sheet.column('L').width(20)
                sheet.range(dataInfo.titleRange).merged(true).style({
                    bold: true,
                    horizontalAlignment: "center",
                    verticalAlignment: "center"
                })
                sheet.range(dataInfo.tBodyRange).style({
                    horizontalAlignment: "center",
                })
            })
            return wb.outputAsync().then(wbb => URL.createObjectURL(wbb));
        })
    }

    const workbook2blob = (wb) => {
        const params = {
            bookType: 'xlsx',
            type: "binary"
        }
        const wboutput = XLSX.write(wb, params)
        const blob = new Blob([s2ab(wboutput)], {
            type: 'application/octet-stream'
        })

        return blob
    }

    const s2ab = (data) => {
        const buffer = new ArrayBuffer(data.length)
        const view = new Uint8Array(buffer)
        for (let i = 0; i != data.length; i++) {
            view[i] = data.charCodeAt(i);
        }
        return buffer;
    }

    const INFO_DATA = (

        <div>

            <Modal isOpen={infoModelOpen} toggle={() => setInfoModelOpen(!infoModelOpen)}>
                <ModalHeader toggle={() => setInfoModelOpen(!infoModelOpen)} close={<button className="btn-close" onClick={() => setInfoModelOpen(!infoModelOpen)}></button>}>Report Info</ModalHeader>
                <ModalBody>

                    <p>
                        <p>
                            <b>
                                Summary Vs Detailed
                            </b>
                        </p>
                        <p>
                            <b>Summary</b>  Displays total amounts per date/date range chosen.
                        </p>
                        <p>
                            <b>Detailed </b> Displays transaction details per date/date range chosen.
                        </p>

                        <p>
                            <b>
                                Business Day Vs Calendar Day
                            </b>
                        </p>
                        <p>
                            <b>Business Day</b> Select this option when reconciling against bank deposits.
                            <br></br><b>Note: </b> This report will show the Transaction date (Txn Date/Time) as well as the Settled date. The Settled date is when the transaction closed out to be deposited. When choosing the date/date range, the dates pull by the Settled date.
                        </p>
                        <p>
                            <b>Calendar Day </b> DO NOT USE FOR RECONCILING DEPOSITS.   Select this option when wanting to review a specific calendar day/days of transactions (midnight to midnight).
                            <br></br><b>Note: </b> This report will show the Transaction date and Settled date (refers to deposits), but should be used only for the purpose of reviewing transactions and not reconciling deposits. When wanting to reconcile deposits, please use the Business Day report.
                        </p>
                    </p>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-white" onClick={() => setInfoModelOpen(false)}>Close</button>
                </ModalFooter>
            </Modal>

        </div>
    )




    return (
        <div className="row mb-3">

            {
                props.EXPORTREDUCER.fetching ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={props.EXPORTREDUCER.fetching}
                    // onClick={handleClose}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop> : null
            }


            <div className="col-xl-2">



                <Panel>

                    <PanelHeader>


                        <div className="row mb-0">

                            <div className="col-xl-8">
                                Export Report
                            </div>
                            <div className="col-xl-4">
                                <div className="align-item-right"> <i className="icon-info h4 d-block" onClick={() => setInfoModelOpen(true)}></i>
                                    {INFO_DATA}
                                </div>
                            </div>
                        </div>


                    </PanelHeader>

                    <PanelBody>
                        <div className="row mb-3">
                            <div className="col-xl-12">
                                <select className="form-select"
                                    onChange={handleChange}
                                    name='reportType'
                                    value={reportType ? reportType : ''}
                                >

                                    <option value=''>Select Report Type</option>
                                    {reportDropdown.map((option) => (
                                        <option key={option.reportID} value={option.reportID}>
                                            {option.reportName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-xl-12">
                                <select className="form-select"
                                    label="Business Day"
                                    value={businessDay ? businessDay : ''}
                                    onChange={handleChange}
                                    name='businessDay'
                                >

                                    {BUSINSESS.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.status}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>

                        {props.LOGINREDUCER.USER.role != 4 ?

                            <>
                                <div className="row mb-3">
                                    <div className="col-xl-12">
                                        <select className="form-select"
                                            value={isoValue ? isoValue : ''}
                                            onChange={handleChange}
                                            name='isoID'
                                        >
                                            <option value=''>Select ISO</option>

                                            {props.DROPDOWNREDUCER.isosData.map((option) => (
                                                <option key={option.isoID} value={option.isoID}>
                                                    {option.isoName}
                                                </option>
                                            ))}

                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-xl-12">
                                        <select className="form-select"
                                            value={merchantID ? merchantID : ''}
                                            onChange={handleChange}
                                            name='merchantID'
                                            onBlur={handleTerminalDrop}

                                        >

                                            <option value=''>Select Merchant</option>
                                            {(props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 2 || props.LOGINREDUCER.USER.role == 3 || props.LOGINREDUCER.USER.role == 6 || props.LOGINREDUCER.USER.role == 7) ? <option value='ALL MERCHANTS'>ALL MERCHANTS</option> : null}
                                            {merchantDropdown.map((option) => (
                                                <option key={option.merchantID} value={option.merchantID}>
                                                    {option.merchantName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </> : null}

                        {(merchantID != 'ALL MERCHANTS') && props.LOGINREDUCER.USER.role != 4 ? <div className="row mb-3">
                            <div className="col-xl-12">
                                <select className="form-select"
                                    value={terminal ? terminal : ''}
                                    onChange={handleChange}
                                    name='terminalID'
                                >
                                    <option value=''>Select Terminal</option>
                                    {terminalDropdown.map((option) => (
                                        <option key={option.terminalID} value={option.terminalID}>
                                            {option.terminalID}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>
                            : null}
                            {props.LOGINREDUCER.USER.role == 4 ?  <div className="row mb-3">
                            <div className="col-xl-12">
                                <select className="form-select"
                                    value={terminal ? terminal : ''}
                                    onChange={handleChange}
                                    name='terminalID'
                                >
                                    <option value=''>Select Terminal </option>
                                    <option value= 'ALL TERMINALS'>ALL TERMINALS</option>
                                    {props.DROPDOWNREDUCER.merchantsData.map((option) => (
                                        <option key={option.terminalID} value={option.terminalID}>
                                            {option.terminalID}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>
                            : null}


                        <div className="row mb-3">

                            <div className="col-xl-12">
                                <label className="form-check-label" >
                                    <b>Enable Date Range</b>&nbsp;&nbsp;&nbsp;
                                    <input className="form-check-input" type="checkbox" checked={isDate} onChange={handleChange} name='daterange' >
                                    </input>
                                </label>

                            </div>

                        </div>
                        <div className="row mb-3">
                            <div className="col-xl-12">
                                <label className="form-check-label" >
                                    <b>{isDate ? 'Start ' : ''} Date</b>
                                </label>
                                <input
                                    max={maxDate}
                                    min={minDate}
                                    defaultValue={maxDate}
                                    // value = {startDate ? startDate : ''}
                                    id="startDate"
                                    label="Start Date"
                                    type="date"
                                    onChange={handleChange}
                                    name="startDate"
                                    className="form-control fs-13px"
                                ></input>
                            </div>
                        </div>

                        {isDate ? <div className="row mb-3">
                            <div className="col-xl-12">
                                <label className="form-check-label" >
                                    <b>End Date</b>
                                </label>

                                <input
                                    defaultValue={endDate}
                                    min={toMinDate}
                                    max={toMaxDate}
                                    id="endDate"
                                    label="End Date"
                                    type="date"
                                    onChange={handleChange}
                                    name="endDate"
                                    className="form-control fs-13px"
                                ></input>
                            </div>
                        </div> : null}


                        <button className="btn btn-primary w-100px me-5px" onClick={handleSubmit}>Run Report</button>

                    </PanelBody>

                </Panel>

            </div>
            <div className="col-xl-10">

                <Panel>

                    <PanelHeader>

                        <div className="row mb-0">

                            <div className="col-xl-6">
                                <h4>Report</h4>
                            </div>

                            <div className="col-xl-2">
                                <button className="btn btn-sm btn-primary d-block w-40 btn-lg h-30px fs-09px" onClick={() => downloadXLSX('xlsx')}>Download As Excel</button>

                            </div>

                            <div className="col-xl-2">
                                <button className="btn btn-sm btn-primary d-block w-40 btn-lg h-30px fs-09px" onClick={() => downloadXLSX('csv')}>Download As CSV</button>

                            </div>
                            <div className="col-xl-2">
                                <button className="btn btn-sm btn-primary d-block w-40 btn-lg h-30px fs-09px" onClick={downloadPDF}>Download As PDF</button>
                                {/* <Button fullWidth onClick={downloadPDF} >Download as PDF</Button> */}

                            </div>

                        </div>


                        {/* <Button fullWidth onClick={() => downloadXLSX('xlsx')} >Download as Excel</Button> */}
                        {/* <Button fullWidth onClick={() => downloadXLSX('csv')} >Download as CSV</Button> */}

                    </PanelHeader>

                    <PanelBody>
                        <h4>{props.EXPORTREDUCER.exportData ? props.EXPORTREDUCER.exportData.headingName : ''}</h4>
                    </PanelBody>

                    {/* {reportType === '1' ? <TableView headings={props.EXPORTREDUCER.exportData ? tableHeadings['exportData'] : tableHeadings['exportData']} data={props.EXPORTREDUCER.exportData.summaryDetails ? props.EXPORTREDUCER.exportData.summaryDetails : []} />
                        : <TableView headings={tableHeadings['reportDetails']} data={props.EXPORTREDUCER.exportData.reportDetails ? props.EXPORTREDUCER.exportData.reportDetails : []} />} */}

                    {reportType === '1' ? <TableView height={600} headings={(props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6) ? tableHeadings.summaryReportAdmin : tableHeadings.summaryReportClient} data={props.EXPORTREDUCER.exportData.summaryDetails ? props.EXPORTREDUCER.exportData.summaryDetails : []} />
                        : <TableView height={600} headings={(props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6) ? tableHeadings.detailedReportAdmin : tableHeadings.detailedReportClient} data={props.EXPORTREDUCER.exportData.reportDetails ? props.EXPORTREDUCER.exportData.reportDetails : []} />}

                    <PanelBody>

                        <div className="row">

                            <div className="col-md-3">
                                <label className="col-md-12"><h5><b>Total Transactions :&nbsp;&nbsp;</b>{props.EXPORTREDUCER.exportData?.transactionTotals?.totalTransactions?.toLocaleString()}</h5></label>
                            </div>
                            <div className="col-md-3">
                                <label className="col-md-12"><h5><b>Total Approved :&nbsp;&nbsp;</b>{props.EXPORTREDUCER.exportData?.transactionTotals?.totalApprovalCount?.toLocaleString()}</h5></label>
                            </div>
                            <div className="col-md-3">
                                <label className="col-md-12"><h5><b>Approved Withdrawals :&nbsp;&nbsp;</b>{props.EXPORTREDUCER.exportData?.transactionTotals?.totalWthApprovalCount?.toLocaleString()}</h5></label>
                            </div>
                            <div className="col-md-3">
                                <label className="col-md-12"><h5><b>Total Declined :&nbsp;&nbsp;&nbsp;</b>{props.EXPORTREDUCER.exportData?.transactionTotals?.totalDeclineCount?.toLocaleString()}</h5></label>
                            </div>

                            <div className="col-md-3">
                                <label className="col-md-12"><h5><b>Amount : &nbsp;&nbsp;&nbsp; $ </b>{props.EXPORTREDUCER.exportData?.transactionTotals?.totalAmount?.toLocaleString()}</h5></label>
                            </div>

                            <div className="col-md-3">
                                <label className="col-md-12"><h5><b>Fee :&nbsp;&nbsp;&nbsp; $ </b>{props.EXPORTREDUCER.exportData?.transactionTotals?.totalFee?.toLocaleString()}</h5></label>
                            </div>

                        </div>

                    </PanelBody>


                </Panel>


            </div>
        </div>

    )



}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    setSnackbar,
    // uploadReport,
    fetchMerchants,
    fetchAllTerminals,
    fetchIsos,
    getDetailedReport,
    setFetchingReport,
    getSummaryReport,
    getDetailedReportByISO,
    getSummaryReportByISO,
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);