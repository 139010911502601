import axios from '../../config/axios';
import config from '../../config/api';
import { setSnackbar } from './SnackbarAction';

export const getAllMerchants = (data) => ({
    type: 'GET_ALL_MERCHANTS',
    data
})


export const getMerchantInfo = (data) => ({
    type: 'GET_MERCHANTS',
    data
})

export const getFilteredData = (data) => ({
    type: 'FILTER_MERCHANTS',
    data
})

export const setMerchantEdit = (data) => ({
    type: 'SET_EDIT',
    data
})

export const setMerchantID = (data) => ({
    type: 'SET_ID',
    data
})

export const setModified = (data) => ({
    type: 'SET_MODIFIED',
    data
})


export const fetchAllMerchants = () => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/merchant/getAllMerchants`).then(response => {
            if (response.status === 200) {
                dispatch(getAllMerchants(response.data));
            } else {
                dispatch(getAllMerchants([]));
            }
        }).catch(error => {
            dispatch(getAllMerchants([]));
        })
    }
}

export const fetchMerchantByClient = (clientID) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/merchant/getAllMerchantsByClientID/${clientID}`).then(response => {
            if (response.status === 200) {
                dispatch(getAllMerchants(response.data));
            } else {
                dispatch(getAllMerchants([]));
            }
        }).catch(error => {
            dispatch(getAllMerchants([]));
        })
    }
}

export const fetchMerchantInfo = (merchantID) => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/merchant/getMerchantInfo/${merchantID}`).then(response => {
            if (response.status === 200) {
                dispatch(getMerchantInfo([response.data]));
            } else {
                dispatch(getMerchantInfo([]));
            }
        }).catch(error => {
            dispatch(getMerchantInfo([]));
        })
    }
}

export const addMerchant = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.FETCH_URL}/merchant/addMerchant`, body).then(response => {
            if (response.status === 201) {
                dispatch(setModified(true))
                dispatch(setMerchantID(response.data.merchantID))
                dispatch(setSnackbar(true, 'success', 'Merchant Added Successfully'))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
            if (response.status === 201) dispatch(setModified(true))
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const editMerchant = (body) => {
    return function (dispatch) {
        axios.put(`${config.api.FETCH_URL}/merchant/updateMerchantInfo`, body).then(response => {
            if (response.status === 200) {
                dispatch(setModified(true))
                dispatch(setMerchantID(response.data.merchantID))
                dispatch(setSnackbar(true, 'success', 'Merchant Updated Successfully'))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}
export const filteredData = (data) => {
    return function (dispatch) {
        dispatch(getFilteredData(data))
    }
}