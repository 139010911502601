import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Panel, PanelBody, PanelHeader } from "../../header/panel/panel"
import { DENOMINATIONS } from '../../../utils/constants';

import { fetchCassetteData, updateCassetteData } from '../../../redux/actions/TerminalActions';


const TerminalCassetteData = (props) => {

    const [terminalID, setTerminalID] = useState('')

    const [denoA, setDenoA] = useState('')
    const [denoB, setDenoB] = useState('')
    const [denoC, setDenoC] = useState('')
    const [denoD, setDenoD] = useState('')

    const [denoALoaded, setDenoALoaded] = useState(0)
    const [denoBLoaded, setDenoBLoaded] = useState(0)
    const [denoCLoaded, setDenoCLoaded] = useState(0)
    const [denoDLoaded, setDenoDLoaded] = useState(0)

    const [denoADispensed, setDenoADispensed] = useState(0)
    const [denoBDispensed, setDenoBDispensed] = useState(0)
    const [denoCDispensed, setDenoCDispensed] = useState(0)
    const [denoDDispensed, setDenoDDispensed] = useState(0)


    const [resetValue, setResetValue] = useState(0);

    const [denoAError, setDenoAError] = useState(false)
    const [denoBError, setDenoBError] = useState(false)
    const [denoCError, setDenoCError] = useState(false)
    const [denoDError, setDenoDError] = useState(false)
    const [denoALoadedError, setDenoALoadedError] = useState(false)
    const [denoBLoadedError, setDenoBLoadedError] = useState(false)
    const [denoCLoadedError, setDenoCLoadedError] = useState(false)
    const [denoDLoadedError, setDenoDLoadedError] = useState(false)
    const [resetValueError, setResetValueError] = useState(false);

    const [edit, setEdit] = useState(false)

    const [reload, setReload] = useState(false)
    // const [toggleReload, setToggleReload] = useState(true)

    const toggleReload = () => {
        if (reload !== true) {
            setReload(true)
            setTimeout(() => {
                setReload(false)
            }, 2000);
        }
    }

    useEffect(() => {

        props.fetchCassetteData(props.terminalID)

    }, [])
    useEffect(() => {

    }, [edit])

    const handleChange = (event) => {
        let targetName = event.target.name
        let targetValue = event.target.value
        switch (targetName) {

            case 'denoA':
                setDenoA(targetValue)
                break;
            case 'denoB':
                setDenoB(targetValue)
                break;
            case 'denoC':
                setDenoC(targetValue)
                break;
            case 'denoD':
                setDenoD(targetValue)
                break;
            case 'denoALoaded':
                setDenoALoaded(targetValue)
                break;
            case 'denoBLoaded':
                setDenoBLoaded(targetValue)
                break;
            case 'denoCLoaded':
                setDenoCLoaded(targetValue)
                break;
            case 'denoDLoaded':
                setDenoDLoaded(targetValue)
                break;
            case 'resetValue':
                setResetValue(targetValue)
                break;
            default:
                break;
        }
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        let body = {}

        if (terminalID) {
            body.terminalID = terminalID
        }

        // if(denoA && denoALoaded) {
        body.denoA = denoA
        body.denoALoaded = denoALoaded
        // }else {
        // setDenoAError(true)
        // }

        // if(denoA && denoALoaded) {
        body.denoB = denoB
        body.denoBLoaded = denoBLoaded
        // }else {
        // setDenoBError(true)
        // }
        // if(denoA && denoALoaded) {
        body.denoC = denoC
        body.denoCLoaded = denoCLoaded
        // }else {
        // setDenoCError(true)
        // }
        // if(denoA && denoALoaded) {
        body.denoD = denoD
        body.denoDLoaded = denoDLoaded

        body.resetAmount = resetValue

        // }else {
        // setDenoDError(true)
        // }
        if (!denoAError && !denoBError && !denoCError && !denoDError) {
            console.log('Body : ' + body)
            setReload(true)
            props.updateCassetteData(body)
            setEdit(false)
            setReload(false)
        }


    }

    const handleResetPreventingRefresh = (event) => {

        setDenoA('20')
        setDenoB('5')
        setDenoC('0')
        setDenoD('0')

        setDenoALoaded(100)
        setDenoBLoaded(300)
        setDenoCLoaded(0)
        setDenoDLoaded(0)

        setResetValue(500)

    }

    const editCassette = () => {

        props.fetchCassetteData(props.terminalID)

        let data = props.TERMINALREDUCER.cassetteData;

        console.log('data : ' + data.terminalID)

        setTerminalID(props.terminalID)
        setDenoA(data.denoA)
        setDenoB(data.denoB)
        setDenoC(data.denoC)
        setDenoD(data.denoD)


        setDenoALoaded(data.denoALoaded)
        setDenoBLoaded(data.denoBLoaded)
        setDenoCLoaded(data.denoCLoaded)
        setDenoDLoaded(data.denoDLoaded)

        setDenoADispensed(data.denoADispensed)
        setDenoBDispensed(data.denoBDispensed)
        setDenoCDispensed(data.denoCDispensed)
        setDenoDDispensed(data.denoDDispensed)

        setResetValue(data.resetAmount)

        
        setEdit(!edit)

    }

    let CASSETTE_EDIT_FORM = (

        <div className="mb-3">

            <div className="row gx-3">

                <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                        <tbody>
                            <tr>
                                <td><label className={"col-md-12 "}> <b>Denomination</b></label> </td>
                                <td>  <select className="form-select"
                                    onChange={handleChange}
                                    name='denoA'
                                    value={denoA ? denoA : ' '}
                                >
                                    {DENOMINATIONS.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.value}
                                        </option>
                                    ))}
                                </select>
                                </td>
                                <td>  <select className="form-select"
                                    onChange={handleChange}
                                    name='denoB'
                                    value={denoB ? denoB : ' '}
                                >
                                    {DENOMINATIONS.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.value}
                                        </option>
                                    ))}
                                </select>
                                </td>
                                <td>  <select className="form-select"
                                    onChange={handleChange}
                                    name='denoC'
                                    disabled={true}
                                    value={denoC ? denoC : ' '}
                                >
                                    {DENOMINATIONS.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.value}
                                        </option>
                                    ))}
                                </select>
                                </td>
                                <td>  <select className="form-select"
                                    onChange={handleChange}
                                    name='denoD'
                                    disabled={true}
                                    value={denoD ? denoD : ' '}
                                >
                                    {DENOMINATIONS.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.value}
                                        </option>
                                    ))}
                                </select>
                                </td>
                            </tr>
                            <tr>
                                <td><label className={"col-md-12 "}> <b>Notes Loaded</b></label> </td>

                                <td>
                                    <input type="text" className="form-control fs-13px"
                                        name="denoALoaded"
                                        onChange={handleChange}
                                        value={denoALoaded ? denoALoaded : 0}
                                    /></td>
                                <td>
                                    <input type="text" className="form-control fs-13px"
                                        name="denoBLoaded"
                                        onChange={handleChange}
                                        value={denoBLoaded ? denoBLoaded : 0}
                                    /></td>
                                <td>
                                    <input type="text" className="form-control fs-13px"
                                        name="denoCLoaded"
                                        onChange={handleChange}
                                        disabled={true}
                                        value={denoCLoaded ? denoCLoaded : 0}
                                    /></td>
                                <td>
                                    <input type="text" className="form-control fs-13px"
                                        name="denoDLoaded"
                                        onChange={handleChange}
                                        disabled={true}
                                        value={denoDLoaded ? denoDLoaded : 0}
                                    /></td>
                            </tr>
                            <tr>
                                <td><label className={"col-md-12 "}> <b>Notes Dispensed</b></label> </td>
                                <td>
                                    <input type="text" className="form-control fs-13px"
                                        name="denoALoaded"
                                        onChange={handleChange}
                                        value={denoADispensed ? denoADispensed : 0}
                                        disabled={true}
                                    /></td>
                                <td>
                                    <input type="text" className="form-control fs-13px"
                                        name="denoBLoaded"
                                        onChange={handleChange}
                                        value={denoBDispensed ? denoBDispensed : 0}
                                        disabled={true}
                                    /></td>
                                <td>
                                    <input type="text" className="form-control fs-13px"
                                        name="denoCLoaded"
                                        onChange={handleChange}
                                        value={denoCDispensed ? denoCDispensed : 0}
                                        disabled={true}
                                    /></td>
                                <td>
                                    <input type="text" className="form-control fs-13px"
                                        name="denoDLoaded"
                                        onChange={handleChange}
                                        value={denoDDispensed ? denoDDispensed : 0}
                                        disabled={true}
                                    /></td>
                            </tr>

                        </tbody>
                    </table>
                </div>

            </div>

            <br></br>

            <div className="col-md-2 mb-2 mb-md-0">
                <label className={"col-md-12 " + (resetValueError ? 'text-danger' : '')}><b>Reset Amount</b></label>
                <input type="text" className="form-control fs-13px"
                    name="resetValue"
                    onChange={handleChange}
                    value={resetValue ? + resetValue : ''}
                />
            </div>

            <br></br>


            <div className="mb-3">

                <div className="row gx-3">

                    <div className="col-md-3 mb-2 mb-md-0">
                        <button className='btn btn-primary d-block w-100 btn-lg h-9px fs-13px' onClick={handleSubmit}>Update</button>
                    </div>

                    <div className="col-md-3 mb-2 mb-md-0">
                        <button className='btn btn-gray d-block w-100 btn-lg h-9px fs-13px' onClick={handleResetPreventingRefresh}>Reset to Default</button>
                    </div>
                </div>
            </div>



        </div>)


    return (

        <Panel>

            <PanelHeader>

                <div className="row mb-0">

                    <div className="col-xl-4">
                        Terminal Cassette Data
                    </div>
                    <div className="col-xl-7">

                    </div>
                    <div className="col-xl-1">
                        <button className='btn btn-sm btn-primary d-block w-40 btn-lg h-35px fs-09px' onClick={editCassette}>{!edit ? 'Edit' : 'Cancel'}</button>
                    </div>

                </div>



            </PanelHeader>

            <PanelBody>

                {
                    !edit ?


                        <div className="mb-3">

                            <div className="row gx-3">

                                <div className="table-responsive">
                                    <table className="table table-bordered mb-0">
                                        <tbody>
                                            <tr>
                                                <td><label className={"col-md-12 "}> <b>Denomination</b></label> </td>
                                                <td>  <select className="form-select"
                                                    onChange={handleChange}
                                                    name='denoA'
                                                    value={props.TERMINALREDUCER.cassetteData.denoA ? props.TERMINALREDUCER.cassetteData.denoA : ' '}
                                                    disabled={true}
                                                >
                                                    {DENOMINATIONS.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.value}
                                                        </option>
                                                    ))}
                                                </select>
                                                </td>
                                                <td>  <select className="form-select"
                                                    onChange={handleChange}
                                                    name='denoB'
                                                    value={props.TERMINALREDUCER.cassetteData.denoB ? props.TERMINALREDUCER.cassetteData.denoB : ' '}
                                                    disabled={true}
                                                >
                                                    {DENOMINATIONS.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.value}
                                                        </option>
                                                    ))}
                                                </select>
                                                </td>
                                                <td>  <select className="form-select"
                                                    onChange={handleChange}
                                                    name='denoC'
                                                    value={props.TERMINALREDUCER.cassetteData.denoC ? props.TERMINALREDUCER.cassetteData.denoC : ' '}
                                                    disabled={true}
                                                >
                                                    {DENOMINATIONS.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.value}
                                                        </option>
                                                    ))}
                                                </select>
                                                </td>
                                                <td>  <select className="form-select"
                                                    onChange={handleChange}
                                                    name='denoD'
                                                    value={props.TERMINALREDUCER.cassetteData.denoD ? props.TERMINALREDUCER.cassetteData.denoD : ' '}
                                                    disabled={true}
                                                >
                                                    {DENOMINATIONS.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.value}
                                                        </option>
                                                    ))}
                                                </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><label className={"col-md-12 "}> <b>Notes Loaded</b></label> </td>

                                                <td>
                                                    <input type="text" className="form-control fs-13px"
                                                        name="denoALoaded"
                                                        onChange={handleChange}
                                                        value={props.TERMINALREDUCER.cassetteData.denoALoaded ? props.TERMINALREDUCER.cassetteData.denoALoaded : 0}
                                                        disabled={true}
                                                    /></td>
                                                <td>
                                                    <input type="text" className="form-control fs-13px"
                                                        name="denoBLoaded"
                                                        onChange={handleChange}
                                                        value={props.TERMINALREDUCER.cassetteData.denoBLoaded ? props.TERMINALREDUCER.cassetteData.denoBLoaded : 0}
                                                        disabled={true}
                                                    /></td>
                                                <td>
                                                    <input type="text" className="form-control fs-13px"
                                                        name="denoCLoaded"
                                                        onChange={handleChange}
                                                        value={props.TERMINALREDUCER.cassetteData.denoCLoaded ? props.TERMINALREDUCER.cassetteData.denoCLoaded : 0}
                                                        disabled={true}
                                                    /></td>
                                                <td>
                                                    <input type="text" className="form-control fs-13px"
                                                        name="denoDLoaded"
                                                        onChange={handleChange}
                                                        value={props.TERMINALREDUCER.cassetteData.denoDLoaded ? props.TERMINALREDUCER.cassetteData.denoDLoaded : 0}
                                                        disabled={true}
                                                    /></td>
                                            </tr>
                                            <tr>
                                                <td><label className={"col-md-12 "}> <b>Notes Dispensed</b></label> </td>
                                                <td>
                                                    <input type="text" className="form-control fs-13px"
                                                        name="denoALoaded"
                                                        onChange={handleChange}
                                                        value={props.TERMINALREDUCER.cassetteData.denoADispensed ? props.TERMINALREDUCER.cassetteData.denoADispensed : 0}
                                                        disabled={true}
                                                    /></td>
                                                <td>
                                                    <input type="text" className="form-control fs-13px"
                                                        name="denoBLoaded"
                                                        onChange={handleChange}
                                                        value={props.TERMINALREDUCER.cassetteData.denoBDispensed ? props.TERMINALREDUCER.cassetteData.denoBDispensed : 0}
                                                        disabled={true}
                                                    /></td>
                                                <td>
                                                    <input type="text" className="form-control fs-13px"
                                                        name="denoCLoaded"
                                                        onChange={handleChange}
                                                        value={props.TERMINALREDUCER.cassetteData.denoCDispensed ? props.TERMINALREDUCER.cassetteData.denoCDispensed : 0}
                                                        disabled={true}
                                                    /></td>
                                                <td>
                                                    <input type="text" className="form-control fs-13px"
                                                        name="denoDLoaded"
                                                        onChange={handleChange}
                                                        value={props.TERMINALREDUCER.cassetteData.denoDDispensed ? props.TERMINALREDUCER.cassetteData.denoDDispensed : 0}
                                                        disabled={true}
                                                    /></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <br></br>

                            <div className="col-md-2 mb-2 mb-md-0">
                                <label className={"col-md-12 " + (resetValueError ? 'text-danger' : '')}><b>Reset Amount</b></label>
                                <input type="text" className="form-control fs-13px"
                                    name="resetValue"
                                    onChange={handleChange}
                                    value={props.TERMINALREDUCER.cassetteData.resetAmount ? props.TERMINALREDUCER.cassetteData.resetAmount : ''}
                                    disabled={true}
                                />
                            </div>

                        </div>
                        :  CASSETTE_EDIT_FORM }

            </PanelBody>

        </Panel>


    )

}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {

    fetchCassetteData,
    updateCassetteData

}


export default connect(mapStateToProps, mapDispatchToProps)(TerminalCassetteData);
