const initialState = { ISAUTH: false, IS_EMAIL_VERIFIED: false, USER: {}, LOGIN_ATTEMPT: false, LOGIN_MODIFIED: false };

export const LOGINREDUCER = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                ISAUTH: true,
                IS_EMAIL_VERIFIED: action.data.emailVerified,
                LOGIN_ATTEMPT: false,
                USER: action.data
            }
        case 'LOGIN_FAILURE':
            return {
                ...state,
                LOGIN_ATTEMPT: true,
            };
        case 'LOGOUT_SUCCESS':
            return initialState;
        case 'LOGOUT_FAILURE':
            return {
                ...state,
            };
        case 'LOGIN_MODIFIED':
            return {
                ...state,
                LOGIN_MODIFIED: action.data
            }    
        default:
            return state;
    }
}