import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import { Panel, PanelBody, PanelHeader } from "../../header/panel/panel"

import { terminalAdminHeadings, terminalHeadings, terminalSuperISOHeadings } from '../../../utils/constants';
import moment from 'moment';
import Table from '../Table/Table';
import { fetchTerminal, fetchTerminalHistory, fetchCassetteData, setTerminalID } from '../../../redux/actions/TerminalActions';

import { fetchTransactions } from '../../../redux/actions/TransactionActions';
import { resetKey } from '../../../redux/actions/UploadActions';
import { fetchMerchants } from '../../../redux/actions/dropdownActions';

import { tableHeadings } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import TerminalBatchList from './TerminalBatchList';
import TerminalCassetteData from './TerminalCassetteData';
import TerminalHistory from './TerminalHistory';


const TerminalProfile = (props) => {

    const navigate = useNavigate();

    const [isoValue, setIsoValue] = useState('');
    const [merchantID, setMerchantID] = useState('')
    const [terminal, setTerminal] = useState('');
    const [terminalIDEntered, setTerminalIDEntered] = useState('')


    const [merchantDropdown, setMerchantDropdown] = useState([])
    const [terminalDropdown, setTerminalDropdown] = useState([])


    const handleTerminalSelect = () => {
        let tid = terminal ? terminal : terminalIDEntered;

        if (tid) {
            props.setTerminalID(tid)
            props.fetchCassetteData(tid)
            props.fetchTerminal(tid)
            props.fetchTransactions(tid)
            props.fetchTerminalHistory(tid)

        }

    }

    const handleTerminalDrop = () => {

        if (props.DROPDOWNREDUCER.merchantsData && props.DROPDOWNREDUCER.merchantsData.length) {
            setTerminalDropdown(props.DROPDOWNREDUCER.merchantsData)
            console.log(terminalDropdown)
        } else {
            setTerminalDropdown([])
        }
    }

    const handleChange = (event) => {
        let targetName = event.target.name
        let targetValue = event.target.value

        switch (targetName) {

            case 'isoID':
                if (targetValue) {
                    setIsoValue(targetValue);
                    let merchants = props.DROPDOWNREDUCER.isosData.filter(iso => {
                        return iso.isoID == targetValue
                    })

                    if (props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 2 || props.LOGINREDUCER.USER.role == 6) {
                        console.log(merchants[0]?.merchants)
                        setMerchantDropdown(merchants[0]?.merchants)

                        console.log('merchant drop : ' + merchantDropdown)
                    } else {
                        setMerchantDropdown(merchants[0]?.merchants)
                    }
                }
                break;

            case 'merchantID':
                if (targetValue) {
                    setMerchantID(targetValue)
                    props.fetchMerchants(targetValue)
                }
            case 'terminalID':
                setTerminal(targetValue)
                setTerminalIDEntered('')
                break;

            case 'terminalIDEntered':
                setTerminalIDEntered(targetValue)
                setTerminal('')
                setIsoValue('');
                setMerchantID('')

                break;

            default:
                break;
        }
    }

    const heading = props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ? terminalAdminHeadings : props.LOGINREDUCER.USER.role == 7 ? terminalSuperISOHeadings : terminalHeadings

    useEffect(() => {
        let id = props.TERMINALREDUCER.terminalID
        if (id) {

            props.fetchCassetteData(id)
            props.fetchTerminal(id)
            props.fetchTransactions(id)
            props.fetchTerminalHistory(id)
            console.log('History : ' + id + '  ' + props.TERMINALREDUCER.terminalHistory)

        } else {
            handleBackToList();
        }

    }, [])

    const handleBackToList = () => {
        navigate('/terminal/list')
    }

    const handleEditClick = () => {
        navigate('/terminal/edit')
    }

    const handleResetSessionKey = (event) => {
        event.preventDefault();

        let body = {}
        body.terminalID = props.TERMINALREDUCER.terminalID

        console.log('Resetting session key - : ' + body)
        props.resetKey(body)
    }

    let SELECT_SECTION = (
        <Panel>

            <PanelBody>

                <div className="row mb-3">
                    <div className="col-xl-2">
                        <select className="form-select"
                            value={isoValue ? isoValue : ''}
                            onChange={handleChange}
                            name='isoID'
                        >
                            <option value=''>Select ISO</option>

                            {props.DROPDOWNREDUCER.isosData.map((option) => (
                                <option key={option.isoID} value={option.isoID}>
                                    {option.isoName}
                                </option>
                            ))}

                        </select>
                    </div>

                    <div className="col-xl-2">
                        <select className="form-select"
                            value={merchantID ? merchantID : ''}
                            onChange={handleChange}
                            name='merchantID'
                            onBlur={handleTerminalDrop}

                        >

                            <option value=''>Select Merchant</option>
                            {merchantDropdown.map((option) => (
                                <option key={option.merchantID} value={option.merchantID}>
                                    {option.merchantName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-xl-2">
                        <select className="form-select"
                            value={terminal ? terminal : ''}
                            onChange={handleChange}
                            name='terminalID'
                        >
                            <option value=''>Select Terminal</option>
                            {terminalDropdown.map((option) => (
                                <option key={option.terminalID} value={option.terminalID}>
                                    {option.terminalID}
                                </option>
                            ))}

                        </select>
                    </div>
                    <div className="col-sm-1"><b>OR Enter Terminal ID</b></div>

                    <div className="col-xl-2">
                        <input type="text"
                            className="form-control"
                            placeholder="Enter Terminal ID  here"
                            name="terminalIDEntered"
                            value={terminalIDEntered ? terminalIDEntered : ''}
                            onChange={handleChange} />

                    </div>

                    <div className="col-xl-2">

                        <button className="btn btn-primary w-100px me-5px" onClick={handleTerminalSelect}>View</button>
                    </div>
                </div>


            </PanelBody>

        </Panel>


    )

    return (

        <div>
            <div className="row mb-3">

                {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ?
                    <div className="col-xl-12">
                        {SELECT_SECTION}
                    </div>
                    : null}
                <hr className="mb-10px bg-gray-600" />

                <div className="col-xl-4">

                    <Panel>

                        <PanelHeader>

                            <div className="row mb-0">

                                <div className="col-xl-4">
                                    Terminal Profile
                                </div>

                                <div className="col-xl-6"></div>

                                <div className="col-xl-2">
                                    {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ? <button className='btn btn-primary d-block w-30 btn-lg h-9px fs-11px' onClick={handleEditClick}>Edit</button> : null}

                                </div>

                            </div>

                        </PanelHeader>

                        <PanelBody>

                            <div className="table-responsive">

                                <table className="table mb-0">

                                    <tbody>

                                        {props.TERMINALREDUCER.terminalData && props.TERMINALREDUCER.terminalData.length ? Object.keys(heading).map(data => {

                                            switch (data) {
                                                case 'lastUpdated':
                                                case 'createDate':
                                                    return (
                                                        <tr key={data}>
                                                            <th >{heading[data]}</th>
                                                            <th>:</th>
                                                            <td>{props.TERMINALREDUCER.terminalData[0] && Object.keys(props.TERMINALREDUCER.terminalData[0]).includes(data) ? moment(props.TERMINALREDUCER.terminalData[0][data]).format('MMM DD, YYYY HH:mm:ss') : '-'}</td>
                                                        </tr>
                                                    )
                                                case 'modelID':
                                                    let make = props.DROPDOWNREDUCER.terminalMakeData.filter(terminal => {
                                                        return terminal.makeID == props.TERMINALREDUCER.terminalData[0]['makeID']
                                                    })
                                                    make = make[0].terminalModels
                                                    let name = make.filter(makeid => {
                                                        return makeid.modelID == props.TERMINALREDUCER.terminalData[0][data]
                                                    })
                                                    return (
                                                        <tr key={data}>
                                                            <th >{heading[data]}</th>
                                                            <th>:</th>
                                                            <td>{props.TERMINALREDUCER.terminalData[0] && Object.keys(props.TERMINALREDUCER.terminalData[0]).includes(data) ? name[0].modelName : '-'}</td>
                                                        </tr>

                                                    )
                                                case 'processorID':
                                                    let processor = props.DROPDOWNREDUCER.processorsData.filter(processor => {
                                                        return processor.processorID == props.TERMINALREDUCER.terminalData[0][data]
                                                    })
                                                    return (
                                                        <tr key={data}>
                                                            <th >{heading[data]}</th>
                                                            <th>:</th>
                                                            <td>{props.TERMINALREDUCER.terminalData[0] && Object.keys(props.TERMINALREDUCER.terminalData[0]).includes(data) ? processor[0].processorName : '-'}</td>
                                                        </tr>

                                                    )
                                                case 'resetKey':
                                                    return (

                                                        <tr key={data}>
                                                            <th>{heading[data]}</th>
                                                            <th>:</th>
                                                            <td>
                                                                {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ? <button className='btn btn-primary d-block w-30 btn-sm h-6px fs-11px' onClick={handleResetSessionKey}>Reset Session Key</button> : null}

                                                            </td>
                                                        </tr>
                                                    )
                                                case 'terminalStatusID':
                                                    return (

                                                        <tr key={data}>
                                                            <th>{heading[data]}</th>
                                                            <th>:</th>

                                                            <td className={props.TERMINALREDUCER.terminalData[0][data] == 1 ? 'approved' : props.TERMINALREDUCER.terminalData[0][data] == 2 ? 'suspended' : props.TERMINALREDUCER.terminalData[0][data] == 3 ? 'inactive' : 'reject'}>{props.TERMINALREDUCER.terminalData[0] && Object.keys(props.TERMINALREDUCER.terminalData[0]).includes(data) ? props.TERMINALREDUCER.terminalData[0][data] == 1 ? 'Active' : props.TERMINALREDUCER.terminalData[0][data] == 2 ? 'Suspended' : props.TERMINALREDUCER.terminalData[0][data] == 3 ? 'InActive' : 'Deleted' : '-'}</td>
                                                        </tr>
                                                    )
                                                case 'autoBatch':
                                                    return (

                                                        <tr key={data}>
                                                            <th>{heading[data]}</th>
                                                            <th>:</th>
                                                            <td >{props.TERMINALREDUCER.terminalData[0] && Object.keys(props.TERMINALREDUCER.terminalData[0]).includes(data) ? (props.TERMINALREDUCER.terminalData[0][data] == true ?
                                                                'Yes ' : 'No') : '-'}
                                                            </td>
                                                        </tr>
                                                    )
                                                case 'creditBlock':
                                                    return (

                                                        <tr key={data}>
                                                            <th>{heading[data]}</th>
                                                            <th>:</th>
                                                            <td >
                                                                {props.TERMINALREDUCER.terminalData[0] && Object.keys(props.TERMINALREDUCER.terminalData[0]).includes(data) ? (props.TERMINALREDUCER.terminalData[0][data] == true ? 'Yes' : 'No') : '-'}

                                                            </td>
                                                        </tr>
                                                    )
                                                case 'checkDigit':
                                                    return (
                                                        <tr key={data}>
                                                            <th >{heading[data]}</th>
                                                            <th>:</th>
                                                            <td>{props.TERMINALREDUCER.terminalData[0] && Object.keys(props.TERMINALREDUCER.terminalData[0]).includes(data) && props.TERMINALREDUCER.terminalData[0][data] != 'null' ? props.TERMINALREDUCER.terminalData[0][data] : '-'}
                                                                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ? <button className='btn-primary h-1px fs-11px' onClick={handleEditClick}>Assign new key</button> : null} */}
                                                            </td>

                                                        </tr>
                                                    )

                                                default:
                                                    return (
                                                        <tr key={data}>
                                                            <th>{heading[data]}</th>
                                                            <th>:</th>
                                                            <td>{props.TERMINALREDUCER.terminalData[0] && Object.keys(props.TERMINALREDUCER.terminalData[0]).includes(data) && props.TERMINALREDUCER.terminalData[0][data] != null ? props.TERMINALREDUCER.terminalData[0][data] : '-'}</td>
                                                        </tr>

                                                    )
                                            }

                                        }) : null}


                                    </tbody>
                                </table>
                            </div>

                        </PanelBody>

                    </Panel>
                </div>
                <div className="col-xl-8">
                    <Panel>
                        <PanelHeader>Transactions</PanelHeader>
                        <Table height={600} headings={tableHeadings.terminalTransactions} data={props.TRANSACTIONREDUCER.transactionsData} />
                    </Panel>
                </div>

                <div className="col-xl-7">
                    {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ?
                        <div className="row mb-3">
                            <div className="col-xl-12">
                                <TerminalCassetteData terminalID={props.TERMINALREDUCER.terminalID}></TerminalCassetteData>
                            </div>
                        </div>
                        : null}
                </div>
                    {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ?
                        <div className="col-xl-5">
                            {/* <Panel>
                            <PanelHeader>Terminal History</PanelHeader>
                            < Table headings={tableHeadings.terminalHistory} data={props.TERMINALREDUCER.terminalHistory} />
                        </Panel> */}
                            <TerminalHistory></TerminalHistory>
                        </div>

                        : null}

            </div>

            <div className="row mb-3">



                {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 || props.LOGINREDUCER.USER.role == 7 ?
                    <div className="col-xl-12">
                        <TerminalBatchList terminalID={props.TERMINALREDUCER.terminalID}></TerminalBatchList>
                    </div>
                    : null}

            </div>




        </div>
    )

}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchTerminal,
    fetchTransactions,
    resetKey,
    fetchTerminalHistory,
    fetchCassetteData,
    fetchMerchants,
    setTerminalID
}


export default connect(mapStateToProps, mapDispatchToProps)(TerminalProfile);