import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { setSnackbar } from "../redux/actions/SnackbarAction";
import { connect } from "react-redux";


const CustomizedSnackBar = (props) => {

    const dispatch = useDispatch();

    const handleClose = (event, reason) => {
        if (reason == 'clickaway')
            return
        dispatch(setSnackbar(false, props.SNACKBARREDUCER.snackbarType, props.SNACKBARREDUCER.snackbarMessage))
    }
    return (
        <div className={''}>
            <Snackbar
                open={props.SNACKBARREDUCER.snackbarOpen}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <Alert
                    elevation={6}
                    variant={'filled'}
                    onClose={handleClose}
                    severity={props.SNACKBARREDUCER.snackbarType}
                    color={props.SNACKBARREDUCER.snackbarType}>
                    {props.SNACKBARREDUCER.snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}
function mapStateToProps(state) {
    return state
}
export default connect(mapStateToProps)(CustomizedSnackBar);