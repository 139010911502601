import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SidebarProfile from './sidebar-profile.jsx';
import SidebarNav from './sidebar-nav.jsx';
import DropdownProfile from '../header/profile.js';

import Header from '../header/header.jsx';
import { connect } from "react-redux";

import { fetchIsos, fetchProcessors, fetchTerminalMake, fetchReportTypes, fetchPrefix, fetchProcessorTerminalModels } from "../../../redux/actions/dropdownActions";

const Sidebar = (props) => {

	const [open, setOpen] = useState(false)

	const handleMobileDraw = () => {

		setOpen(!open)

	}

	useEffect(() => {
		props.fetchIsos()
		props.fetchProcessors()
		props.fetchTerminalMake()
		props.fetchReportTypes()
		props.fetchPrefix()
		props.fetchProcessorTerminalModels()
	}, [])


	return (
		<div className={'app app-header-fixed app-sidebar-fixed' + (open ? ' app-sidebar-mobile-toggled has-scroll' : '')}>
			<React.Fragment>
				<div id="sidebar" className={'app-sidebar ' + 'app-sidebar-transparent' + 'app-sidebar-grid'}>
					<PerfectScrollbar className="app-sidebar-content" options={{ suppressScrollX: true }}>
						<div className="menu">
							<SidebarProfile />
						</div>
						<SidebarNav />

					</PerfectScrollbar>
				</div>
			</React.Fragment>
			{/* <Header></Header> */}

			<div id="header" className='app-header'>
				<div className="navbar-header ">
					<div to="/" className="navbar-brand"><span className="navbar-logo"></span> <b>Reporting&nbsp;</b> <span> Portal</span></div>
					<button onClick={handleMobileDraw} type="button" className="navbar-mobile-toggler"><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
				</div>
				<div className="navbar-nav">
					<DropdownProfile />
				</div>
			</div>
			<div className={'app-content '}>
				<Outlet></Outlet>
			</div>
		</div>
	)
}

function mapStateToProps(state) {
	return state
}

const mapDispatchToProps = {
	fetchIsos, fetchProcessors, fetchTerminalMake,
	fetchReportTypes, fetchPrefix, fetchProcessorTerminalModels
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);