import axios from '../../config/axios';
import config from '../../config/api';
import { setSnackbar } from './SnackbarAction';


export const getAllUsers = (data) => ({
    type: 'GET_USERS',
    data
})

export const getUserData = (data) => ({
    type: 'GET_USER',
    data
})

export const getFilteredData = (data) => ({
    type: 'FILTER_USERS',
    data
})

export const setModified = (data) => ({
    type: 'SET_MODIFIED',
    data
})

export const setAssignments = (data) => ({
    type: 'SET_ASSIGNMENTS',
    data
})

export const setUserID = (data) => ({
    type: 'SET_ID',
    data
})

export const setAddEmailResponse = (data) => ({
    type: 'ADD_EMAIL',
    data
})

export const setUserDropdown = (data) => ({
    type: 'SET_USER_DROPDOWN',
    data
})

export const fetchAllUsers = () => {

    return function (dispatch) {
        axios.get(`${config.api.BASE_URL}/user/getAllUsers`).then(response => {
            if (response.status === 200) {
                dispatch(getAllUsers(response.data));
            } else {
                dispatch(getAllUsers([]));
            }
        }).catch(error => {
            dispatch(getAllUsers([]));
        })
    }
}

export const fetchUser = (userID) => {
    return function (dispatch) {
        axios.get(`${config.api.BASE_URL}/user/getUser/${userID}`).then(response => {
            if (response.status === 200) {
                dispatch(getUserData([response.data]));
            } else {
                dispatch(getUserData([]));
            }
        }).catch(error => {
            dispatch(getUserData([]));
        })
    }
}

export const addUser = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.BASE_URL}/user/addUser`, body).then(response => {
            if (response.status === 201) {
                dispatch(setModified(true))
                dispatch(setUserID(response.data.id))
                dispatch(setSnackbar(true, 'success', 'User Added Successfully'))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const editUser = (body) => {
    return function (dispatch) {
        axios.put(`${config.api.BASE_URL}/user/updateUser`, body).then(response => {
            if (response.status === 200) {
                dispatch(setModified(true))
                dispatch(setUserID(response.data.id))
                dispatch(setSnackbar(true, 'success', 'User Edited Successfully'))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const updatePassword = (body) => {
    return function (dispatch) {
        axios.put(`${config.api.BASE_URL}/user/updatePassword`, body).then(response => {
            if (response.status === 200) {
                dispatch(setSnackbar(true, 'success', 'Password Updated Successfully'))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const fetchAssignments = (USERID) => {
    return function (dispatch) {
        axios.get(`${config.api.BASE_URL}/user/getAssignments/${USERID}`).then(response => {
            if (response.status === 200) {
                dispatch(setAssignments(response.data))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const assignUser = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.BASE_URL}/user/addAssigment`, body).then(response => {
            if (response.status === 200) {
                dispatch(fetchAssignments(body.userID))
                dispatch(setSnackbar(true, 'success', 'Assigned Successfully'))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const filteredData = (data) => {
    return function (dispatch) {
        dispatch(getFilteredData(data))
    }
}

export const addEmail = (body) => {

    return function (dispatch) {
        console.log(body)
        axios.post(`${config.api.BASE_URL}/user/addEmail`, body).then(response => {
            if (response.status === 200) {
                dispatch(setSnackbar(true, 'success', 'Email Sent. Please Check your email for code'))
                dispatch(setAddEmailResponse(response.data))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }

}

export const verifyCode = (body) => {

    return function (dispatch) {
        console.log(body)
        axios.post(`${config.api.BASE_URL}/user/verifyemail`, body).then(response => {
            if (response.status === 200) {
                if(response.data?.emailVerified) {
                    dispatch(setSnackbar(true, 'success', 'Email Verified. Redirecting...'))
                }else {
                    dispatch(setSnackbar(true,'error', 'Could not verify code, try again'))
                }
                dispatch(setAddEmailResponse(response.data))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }

}

export const fetchlUserDropdown = () => {

    return function (dispatch) {
        axios.get(`${config.api.BASE_URL}/user/getUserDropdown`).then(response => {
            if (response.status === 200) {
                dispatch(setUserDropdown(response.data));
            } else {
                dispatch(setUserDropdown([]));
            }
        }).catch(error => {
            dispatch(setUserDropdown([]));
        })
    }
}

export const unAssignUser = (body) => {
    return function (dispatch) {
        console.log('Body : ' + body)
        axios.delete(`${config.api.BASE_URL}/user/deleteAssignent`, { data: body } ).then(response => {
            if (response.status === 200) {
                dispatch(fetchAssignments(body.userID))
                dispatch(setSnackbar(true, 'success', 'Unassigned Successfully'))
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
        }).catch(error => {
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}