const initialState = { usersData: [], userData: [], filteredData: [], assignedData: [], modified: false, userID: null, addEmailresponse: [], userDropdownData: [] };

export const USERSREDUCER = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_USERS':
            return {
                ...state,
                usersData: action.data
            }
        case 'GET_USER':
            return {
                ...state,
                userData: action.data
            }
        case 'FILTER_USERS':
            return {
                ...state,
                filteredData: action.data
            }
        case 'SET_MODIFIED':
            return {
                ...state,
                modified: action.data
            }
        case 'SET_ASSIGNMENTS':
            return {
                ...state,
                assignedData: action.data
            }
        case 'SET_ID':
            return {
                ...state,
                userID: action.data
            }
        case 'ADD_EMAIL':
            return {
                ...state,
                addEmailresponse: action.data
            }
        case 'SET_USER_DROPDOWN' :
            return {
                ...state,
                userDropdownData: action.data
            }
        default:
            return state;
    }
}