import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAllUsers, fetchUser, filteredData, updatePassword, setModified, fetchAssignments, assignUser, fetchlUserDropdown } from '../../../redux/actions/UserActions';
import { loginAsUser, loginModified } from '../../../redux/actions/LoginActions'
import { connect } from "react-redux";
import { setSnackbar } from '../../../redux/actions/SnackbarAction';
import { fetchMerchants } from '../../../redux/actions/dropdownActions';

import { Panel, PanelBody, PanelHeader } from "../../header/panel/panel"

import { LOGIN_AS_ROLES } from '../../../utils/constants';


const LoginAsUser = (props) => {


    const navigate = useNavigate()


    const [password, setPassword] = useState('')
    const [subUsername, setSubUsername] = useState('')
    const [subUserRoleID, setSubUserRoleID] = useState('')


    const [passwordError, setPasswordError] = useState(false)
    const [subUsernameError, setSubUsernameError] = useState(false)
    const [subUserRoleIDError, setSubUserRoleIDError] = useState(false)


    const [userDropdown, setUserDropdown] = useState([])


    useEffect(() => {
        props.fetchlUserDropdown()
    }, [])


    useEffect(() => {
        console.log(props.LOGINREDUCER.LOGIN_MODIFIED)
        if (props.LOGINREDUCER.LOGIN_MODIFIED) {
            props.loginModified(false)
            navigate('/')
        }
    }, [props.LOGINREDUCER])

    const handleChange = (event) => {
        let targetName = event.target.name
        let targetValue = event.target.value
        if (targetValue) {

            switch (targetName) {
                case 'password':
                    setPasswordError(false)
                    setPassword(targetValue)
                    break;
                case 'subUsername':
                    setSubUsernameError(false)
                    setSubUsername(targetValue)
                    break;
                case 'subUserRoleID':
                    setSubUserRoleID(targetValue)
                    setSubUserRoleIDError(false)
                    console.log('subUserRoleID : ' + subUserRoleID)
                    let data = props.USERSREDUCER.userDropdownData.filter(data => {
                        return data.userRoleID == targetValue
                    })
                    setUserDropdown(data)
                    console.log(data)
                    break;
            }

        }

    }




    const handlesubmit = (event) => {

        event.preventDefault()

        let body = {}

        if (!subUsername) {
            setSubUsernameError(true)
        } else {
            body.subUsername = subUsername
        }


        if (!password) {
            setPasswordError(true)
        } else {
            body.username = props.LOGINREDUCER.USER.user;
            body.password = password
        }



        console.log(body)

        if (!subUsernameError && !passwordError && body.username) {
            props.loginAsUser(body)

        }
    }

    let LOGIN_AS_USER = (

        <form className="fs-13px">

            <div className="mb-3">

                <div className="col-md-8 mb-2 mb-md-0">

                    <label className={"col-md-3 " + (subUserRoleIDError ? 'text-danger' : '')}><b>Role</b></label>
                    <select className="form-select"
                        value={subUserRoleID ? subUserRoleID : ''}
                        onChange={handleChange}
                        name='subUserRoleID'

                    >
                        <option value=''>Select Role</option>
                        {LOGIN_AS_ROLES.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.role}
                            </option>
                        ))}

                    </select>
                </div>
            </div>

            <div className="mb-3">

                <div className="col-md-8 mb-2 mb-md-0">

                    <label className={"col-md-3 " + (subUsernameError ? 'text-danger' : '')}><b>User</b></label>
                    <select className="form-select"
                        value={subUsername ? subUsername : ''}
                        onChange={handleChange}
                        name='subUsername'
                    >
                        <option value=''>Select User</option>
                        {userDropdown.map((option) => (
                            <option key={option.username} value={option.username}>
                                {option.username}
                            </option>
                        ))}

                    </select>
                </div>
            </div>

            <div className="mb-3">

                <div className="col-md-8 mb-2 mb-md-0">

                    <label className={"col-md-6 " + (passwordError ? 'text-danger' : '')}><b>Password</b></label>
                    <input
                        type="password" className="form-control fs-13px"
                        name="password"
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="mb-3">

                <div className="col-md-8 mb-2 mb-md-0">

                    <button className='btn btn-primary d-block w-100 btn-lg h-9px fs-13px' onClick={handlesubmit}>Login</button>
                </div>
            </div>

        </form>
    )

    return (

        <div className="row mb-3 ">

            <div className="col-xl-5">
                <Panel>
                    <PanelHeader>Login As User</PanelHeader>
                    <PanelBody>
                        {LOGIN_AS_USER}
                    </PanelBody>

                </Panel>
            </div>

        </div>
    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchAllUsers, fetchUser, filteredData,
    setSnackbar, updatePassword, setModified,
    fetchAssignments, fetchMerchants, assignUser, fetchlUserDropdown, loginAsUser, loginModified
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginAsUser);