import React, { useEffect } from 'react';
import { connect } from "react-redux";

import { Panel, PanelBody, PanelHeader } from "../../header/panel/panel"

import { terminalHeadings } from '../../../utils/constants';
import moment from 'moment';
import Table from '../Table/Table';
import { fetchTerminal } from '../../../redux/actions/TerminalActions';
import { fetchTransactions } from '../../../redux/actions/TransactionActions';

import { tableHeadings } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import TerminalBatchList from './TerminalBatchList';


const TerminalView = (props) => {

    const navigate = useNavigate();

    useEffect(() => {
        let id = props.TERMINALREDUCER.terminalID
        if (id) {
            props.fetchTerminal(id)
            props.fetchTransactions(id)
        } else {
            handleBackToList();
        }

    }, [])

    const handleBackToList = () => {
        navigate('/terminal/list')
    }

    const handleEditClick = () => {
        navigate('/terminal/edit')
    }

    return (

        <div>
            <div className="row mb-3">

                <div className="col-xl-5">

                    <Panel>

                        <PanelHeader>

                            <div className="row mb-0">

                                <div className="col-xl-4">
                                    Terminal Profile
                                </div>

                                <div className="col-xl-6"></div>

                                <div className="col-xl-2">

                                    {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ? <button className='btn btn-primary d-block w-30 btn-lg h-9px fs-11px' onClick={handleEditClick}>Edit</button> : null}

                                </div>

                            </div>

                        </PanelHeader>

                        <PanelBody>

                            <div className="table-responsive">

                                <table className="table mb-0">

                                    <tbody>

                                        {props.TERMINALREDUCER.terminalData && props.TERMINALREDUCER.terminalData.length ? Object.keys(terminalHeadings).map(data => {
                                            if (data.toLowerCase().includes('date')) {
                                                return (
                                                    <tr key={data}>
                                                        <th >{terminalHeadings[data]}</th>
                                                        <th>:</th>
                                                        <td>{props.TERMINALREDUCER.terminalData[0] && Object.keys(props.TERMINALREDUCER.terminalData[0]).includes(data) ? moment(props.TERMINALREDUCER.terminalData[0][data]).format('MMM DD, YYYY HH:MM:SS') : '-'}</td>
                                                    </tr>

                                                )
                                            } else {
                                                if (data == 'modelID') {
                                                    let make = props.DROPDOWNREDUCER.terminalMakeData.filter(terminal => {
                                                        return terminal.makeID == props.TERMINALREDUCER.terminalData[0]['makeID']
                                                    })
                                                    make = make[0].terminalModels
                                                    let name = make.filter(makeid => {
                                                        return makeid.modelID == props.TERMINALREDUCER.terminalData[0][data]
                                                    })
                                                    return (
                                                        <tr key={data}>
                                                            <th >{terminalHeadings[data]}</th>
                                                            <th>:</th>
                                                            <td>{props.TERMINALREDUCER.terminalData[0] && Object.keys(props.TERMINALREDUCER.terminalData[0]).includes(data) ? name[0].modelName : '-'}</td>
                                                        </tr>

                                                    )
                                                } else
                                                    if (data == 'processorID') {
                                                        let processor = props.DROPDOWNREDUCER.processorsData.filter(processor => {
                                                            return processor.processorID == props.TERMINALREDUCER.terminalData[0][data]
                                                        })
                                                        return (
                                                            <tr key={data}>
                                                                <th >{terminalHeadings[data]}</th>
                                                                <th>:</th>
                                                                <td>{props.TERMINALREDUCER.terminalData[0] && Object.keys(props.TERMINALREDUCER.terminalData[0]).includes(data) ? processor[0].processorName : '-'}</td>
                                                            </tr>

                                                        )
                                                    } else
                                                        if (data == 'terminalStatusID') {
                                                            return (
                                                                <tr key={data}>
                                                                    <th>{terminalHeadings[data]}</th>
                                                                    <th>:</th>

                                                                    <td className={props.TERMINALREDUCER.terminalData[0][data] == 1 ? 'approved' : props.TERMINALREDUCER.terminalData[0][data] == 2 ? 'suspended' : props.TERMINALREDUCER.terminalData[0][data] == 3 ? 'inactive' : 'reject'}>{props.TERMINALREDUCER.terminalData[0] && Object.keys(props.TERMINALREDUCER.terminalData[0]).includes(data) ? props.TERMINALREDUCER.terminalData[0][data] == 1 ? 'Active' : props.TERMINALREDUCER.terminalData[0][data] == 2 ? 'Suspended' : props.TERMINALREDUCER.terminalData[0][data] == 3 ? 'InActive' : 'Deleted' : '-'}</td>
                                                                </tr>
                                                            )
                                                        } else {
                                                            return (
                                                                <tr key={data}>
                                                                    <th>{terminalHeadings[data]}</th>
                                                                    <th>:</th>

                                                                    <td>{props.TERMINALREDUCER.terminalData[0] && Object.keys(props.TERMINALREDUCER.terminalData[0]).includes(data) ? String(props.TERMINALREDUCER.terminalData[0][data]) : '-'}</td>
                                                                </tr>

                                                            )
                                                        }

                                            }
                                        }) : null}


                                    </tbody>
                                </table>
                            </div>

                        </PanelBody>

                    </Panel>
                </div>

                <div className="col-xl-7">

                    <TerminalBatchList terminalID={props.TERMINALREDUCER.terminalID}></TerminalBatchList>


                </div>

            </div>

            <div className="row mb-3">

                <div className="col-xl-12">
                    <Panel>
                        <PanelHeader>Transactions</PanelHeader>
                        <Table headings={tableHeadings.terminalTransactions} data={props.TRANSACTIONREDUCER.transactionsData} />
                    </Panel>
                </div>
            </div>
        </div>
    )

}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchTerminal,
    fetchTransactions
}


export default connect(mapStateToProps, mapDispatchToProps)(TerminalView);