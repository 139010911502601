
import React, { useEffect, useState } from 'react';
import TableView from '../Table/Table';
import { tableHeadings } from '../../../utils/constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { setModified } from '../../../redux/actions/UploadActions';
import { Panel, PanelBody, PanelHeader } from '../../header/panel/panel';



const KeysTable = (props) => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate()
    useEffect(() => {

    }, [])


    const handleClick = (value) => {
        setOpen(true)
    }


    const handleBack = () => {
        props.setModified(false)
        navigate('/import')
    }

    let headings = []
    let downloadData = []

    headings = [
        {
            key: 'keyId', label: 'Terminal ID'
        },

        {
            key: 'terminalPresent', label: 'Terminal Present'
        },
        {
            key: 'status', label: 'Key Status'
        },
        {
            key: 'partOneCheckDigit', label: 'Part One',
        },
        {
            key: 'partTwoCheckDigit', label: 'Part Two'
        },
        {
            key: 'combinedCheckDigits', label: 'Check Digit'
        },
        {
            key: 'keyPresent', label: 'Key Present'
        },
        {
            key: 'keyBound', label: 'Key Bound'
        },
        {
            key: 'keyOverriden', label: 'Key Changed'
        },
        {
            key: 'message', label: 'Message'
        },
    ]

    props.UPLOADREDUCER.keysResult && props.UPLOADREDUCER.keysResult.map(item => {
        downloadData.push(item)
    })

    return (
        <Panel>
            <PanelHeader>
                Key Results
                    <button className='btn btn-primary d-block w-30 btn-lg h-9px fs-11px' > save
                        {/* <CSVLink data={downloadData} headers={headings} filename={'Keys Result'}>Save</CSVLink> */}
                    </button>
            </PanelHeader>
            {/* <div className='button-section'>
                <div className='back-arrow' onClick={handleBack}>
                    <ArrowBackIcon />
                    <span>Back</span>
                </div>
            </div> */}
            <PanelBody>
                <TableView height={600} headings={tableHeadings['keys']} data={props.UPLOADREDUCER.keysResult} />
            </PanelBody>
        </Panel>
    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    setModified
}


export default connect(mapStateToProps, mapDispatchToProps)(KeysTable);