import axios from 'axios';
import { useNavigate } from 'react-router';
import configureStore from '../redux/store';

const instance = axios.create({});

let token = sessionStorage.getItem('user')?"Bearer "+JSON.parse(sessionStorage.getItem('user')).token:'';

if (token) {
  instance.defaults.headers.common['Authorization'] = token;
}

// Setting a default timeout

instance.defaults.timeout = 120000;


instance.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    configureStore.store.dispatch({ type: 'LOGOUT_SUCCESS' })
    
    // history.push('/');

    // sessionStorage.clear();
  }
  else if (error.response.status === 500) {
    // history.goBack();
    // notification.error({
    //   message: 'Error',
    //   description: 'Something went wrong. Please try again!'
    // })
  }

  return Promise.reject(error);
});

export const setToken = (token) => {
  instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

export default instance;