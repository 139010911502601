import React, { useEffect, useState } from 'react';
import TableView from '../Table/Table';
import { tableHeadings } from '../../../utils/constants';
import { fetchAllUsers, filteredData, setModified, setUserID, fetchUser } from '../../../redux/actions/UserActions';
import { connect } from "react-redux";

import { useNavigate } from 'react-router';
import { Panel, PanelHeader } from '../../header/panel/panel';

const Merchant = (props) => {

    const navigate = useNavigate()

    const [searchValue, setSearchValue] = useState('');


    useEffect(() => {
        props.fetchAllUsers()
        props.setModified(false)
        props.filteredData()


    }, [])

    const handleClick = (value) => {

        props.setUserID(value.data.id)
        console.log(value.data.id)
        navigate('/user/view')
    }

    const handleSearch = (event) => {
        setSearchValue(event.target.value)
        let filteredUsers = event.target.value && event.target.value.length ? props.USERSREDUCER.usersData.filter(user => {
            if (user.username.toLowerCase().includes(event.target.value.toLowerCase()) || user.firstName.toLowerCase().includes(event.target.value.toLowerCase()) || user.lastName.toLowerCase().includes(event.target.value.toLowerCase())) {
                return user
            }
        }) : []

        props.filteredData(filteredUsers)

    }

    const addUser = (event) => {
        event.preventDefault()
        navigate('/user/add')
    }

    return (

        <Panel>

            <PanelHeader>

                <div className="row mb-0">

                    <div className="col-xl-4">
                        <h4>Users</h4>
                    </div>
                    <div className="col-xl-3">

                    </div>
                    <div className="col-xl-2">
                        <input type="text" className="form-control" placeholder="Search..." onChange={handleSearch} />
                    </div>
                    <div className="col-xl-1">

                    </div>
                    <div className="col-xl-2">
                        <button className="btn btn-sm btn-primary d-block w-40 btn-lg h-35px fs-09px" onClick={addUser}>Add User</button>

                    </div>

                </div>


            </PanelHeader>
            <TableView height={600} headings={tableHeadings['users']} data={(props.USERSREDUCER.filteredData && props.USERSREDUCER.filteredData.length) || searchValue ? props.USERSREDUCER.filteredData : props.USERSREDUCER.usersData} handleClick={handleClick} />

        </Panel>


    )

}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchAllUsers,
    filteredData,
    setModified,
    setUserID,
    fetchUser,
}


export default connect(mapStateToProps, mapDispatchToProps)(Merchant);