import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { addUser, editUser, setModified } from '../../../redux/actions/UserActions';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROLES, ROLES, STATUS } from '../../../utils/constants';
import { fetchMerchants } from '../../../redux/actions/dropdownActions';
import { setSnackbar } from '../../../redux/actions/SnackbarAction';
import { fetchIsos, fetchProcessors, fetchTerminalMake, fetchReportTypes, fetchPrefix } from "../../../redux/actions/dropdownActions";
import { Panel, PanelBody, PanelHeader } from '../../header/panel/panel';


const UserAddEdit = (props) => {

    const navigate = useNavigate()
    const [isoValue, setIso] = useState('');
    const [username, setUsername] = useState('');
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('');
    const [role, setRole] = useState(2);
    const [phone, setPhone] = useState('');
    const [merchantID, setMerchantID] = useState('')
    const [tempPassword, setTempPassword] = useState('');
    const [status, setStatus] = useState(1);

    const [isoError, setIsoError] = useState(false);
    const [merchantError, setMerchantError] = useState(false)
    const [userError, setUserError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [fnameError, setFnameError] = useState(false);
    const [lnameError, setLnameError] = useState(false);
    const [roleError, setRoleError] = useState(false);
    const [passError, setPassError] = useState(false);
    const [statusError, setStatusError] = useState(false);
    const [merchantDropdown, setMerchantDropdown] = useState([])

    useEffect(() => {
        if (props.edit) {
            let data = props.USERSREDUCER.userData[0]
            setIso(data?.isoID)
            setMerchantID(data?.merchantID)
            setRole(data.userRoleId + '')
            setUsername(data?.username)
            setFirstname(data?.firstName)
            setLastname(data?.lastName)
            setStatus(data?.userStatusID)
            let merchants = props.DROPDOWNREDUCER.isosData.filter(iso => {
                return iso.isoID == data?.isoID
            })
            setMerchantDropdown(merchants[0]?.merchants)
            
        }
    }, [])

    useEffect(() => {
        props.fetchIsos()
    }, [])

    useEffect(() => {
        if (!props?.edit) handleReset()
    }, [props.edit])

    useEffect(() => {
        if (props.USERSREDUCER.modified) {
            props.setModified(false)
            navigate('/user/view')
        }
    }, [props.USERSREDUCER])

    const handleChange = (event) => {

        let targetValue = event.target.value
        let targetName = event.target.name

        switch (targetName) {
            case 'isoValue':
                setIsoError(false);
                setIso(targetValue);
                let merchants = props.DROPDOWNREDUCER.isosData.filter(iso => {
                    return iso.isoID == targetValue
                })
                setMerchantDropdown(merchants[0]?.merchants)
                break;
            case 'username':
                setUserError(false);
                setUsername(targetValue);
                break;
            case 'firstname':
                setFnameError(false);
                setFirstname(targetValue)
                break;
            case 'lastname':
                setLnameError(false);
                setLastname(targetValue);
                break;
            case 'role':
                setRoleError(false)
                setRole(targetValue)
                break;
            case 'userStatusID':
                setStatusError(false)
                setStatus(targetValue)
                break
            case 'tempPassword':
                setPassError(false)
                setTempPassword(targetValue)
                break;
            case 'merchantID':
                setMerchantError(false);
                setMerchantID(targetValue)
                break;
            default:
                break;
        }

    }

    const handleSubmit = (event) => {

        event.preventDefault()

        let body = {}

        if (isoValue) {
            setIsoError(false);
            body.isoID = isoValue
        } else {
            setIsoError(true)
        }

        if (username) {
            setUserError(false);
            body.username = username
        } else {
            setUserError(true)
        }


        if (firstname) {
            setFnameError(false);
            body.firstName = firstname
        } else {
            setFnameError(true)
        }

        if (merchantID) {
            body.merchantID = merchantID
        } else {
            setMerchantError(true)
        }

        if (status) {
            setStatusError(false);
            body.userStatusID = status
        } else {
            setStatusError(true)
        }


        if (lastname) {
            setLnameError(false);
            body.lastName = lastname
        } else {
            setLnameError(true)
        }


        if (role) {
            setRoleError(false);
            body.userRoleId = role
        } else {
            setRoleError(true)
        }


        if (tempPassword) {
            setPassError(false);
            body.password = tempPassword
        } else {
            setPassError(true)
        }

        if (username && firstname && lastname && status && role && tempPassword && tempPassword.length >= 8 && !props.edit) {
            props.addUser(body)
        } else if (username && firstname && lastname && status && role && props.edit) {
            body = {
                ...props.USERSREDUCER.userData[0],
                ...body
            }
            props.editUser(body);
        }
    }

    const handleResetWithDefaultPrevent = (event) =>{
        event.preventDefault()
        handleReset()
    }

    const handleReset = () => {
        setIso('')
        setUsername('')
        setFirstname('')
        setLastname('')
        setRole('')
        setPhone('')
        setMerchantID('')
        setTempPassword('')
        setStatus(1)
        setStatusError(false)
        setMerchantError(false)
        setIsoError(false)
        setUserError(false)
        setFnameError(false)
        setLnameError(false)
        setRoleError(false)
        setPassError(false)
    }



    const checkPassword = () => {
        if (tempPassword && tempPassword.length < 8) {
            props.setSnackbar(true, 'error', 'Password Should Contain Min 8 Characters')
            setPassError(true)
        }
    }

    const handleCancel = (event) => {
        event.preventDefault()
        navigate('/user/view')

    }

    let USERSFORM = (

        <form className="fs-13px">

            <div className="mb-3">
                <div className="row gx-3">

                    <div className="col-md-3 mb-2 mb-md-0">
                        <label className={"col-md-6 " + (fnameError ? 'text-danger' : '')}><b>First Name</b></label>
                        <input
                            type="text" className="form-control fs-13px"
                            name="firstname"
                            onChange={handleChange}
                            value={firstname ? firstname : ''}
                            error={fnameError}
                        />
                    </div>

                    <div className="col-md-3 mb-2 mb-md-0">
                        <label className={"col-md-6 " + (fnameError ? 'text-danger' : '')}><b>Last Name</b></label>
                        <input
                            type="text" className="form-control fs-13px"
                            name="lastname"
                            onChange={handleChange}
                            value={lastname ? lastname : ''}
                            error={lnameError}
                        />
                    </div>

                </div>
            </div>

            <div className="mb-3">
                <div className="row gx-3">

                    <div className="col-md-3 mb-2 mb-md-0">
                        <label className={"col-md-6 " + (fnameError ? 'text-danger' : '')}><b>Username</b></label>

                        <input
                            type="text" className="form-control fs-13px"
                            name="username"
                            onChange={handleChange}
                            value={username ? username : ''}
                            error={userError}
                        />
                    </div>

                    <div className="col-md-3 mb-2 mb-md-0">
                        <label className={"col-md-3 " + (roleError ? 'text-danger' : '')}><b>Role</b></label>
                        <select className="form-select"
                            value={role ? role : ''}
                            onChange={handleChange}
                            name='role'
                        >
                            <option value=''>Select Role</option>
                            {'1'.includes(String(props.LOGINREDUCER.USER.role)) ?  ADMIN_ROLES.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.role}
                                </option>
                            )) : 

                            ROLES.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.role}
                                </option>
                            ))}

                        </select>
                    </div>

                </div>
            </div>

            <div className="mb-3">
                <div className="row gx-3">
                    
                    {['2', '3', '4', '5'].includes(role) ? 
                    <div className="col-md-3 mb-2 mb-md-0">

                        <label className={"col-md-3 " + (isoError ? 'text-danger' : '')}><b>ISO</b></label>
                        <select className="form-select"
                            value={isoValue ? isoValue : ''}
                            error={isoError}
                            onChange={handleChange}
                            name='isoValue'
                        >
                            <option value=''>Select ISO</option>

                            {props.DROPDOWNREDUCER.isosData.map((option) => (
                                <option key={option.isoID} value={option.isoID}>
                                    {option.isoName}
                                </option>
                            ))}

                        </select>
                    </div> 
                     : null}

                    {['4'].includes(role) ? 
                    <div className="col-md-3 mb-2 mb-md-0">
                        <label className={"col-md-3 " + (merchantError ? 'text-danger' : '')}><b>Merchant</b></label>

                        <select className="form-select"
                            value={merchantID ? merchantID : ''}
                            error={merchantError}
                            onChange={handleChange}
                            name='merchantID'
                        >

                            <option value=''>Select Merchant</option>
                            {merchantDropdown.map((option) => (
                                <option key={option.merchantID} value={option.merchantID}>
                                    {option.merchantName}
                                </option>
                            ))}
                        </select>
                    </div> 
                    : null}

                </div>

            </div>
            <div className="mb-3">
                <div className="row gx-3">

                    {!props.edit ? <div className="col-md-3 mb-2 mb-md-0">
                        <label className={"col-md-6 " + (passError ? 'text-danger' : '')}><b>Temporary Password</b></label>
                        <input
                            type="text" className="form-control fs-13px"
                            name="tempPassword"
                            onChange={handleChange}
                            value={tempPassword ? tempPassword : ''}
                            error={passError}
                        />
                    </div> : null}

                    <div className="col-md-3 mb-2 mb-md-0">
                        <label className={"col-md-6 " + (statusError ? 'text-danger' : '')}><b>Status</b></label>
                        <select className="form-select"
                            value={status ? status : ''}
                            onChange={handleChange}
                            name='userStatusID'
                        >
                            {STATUS.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.status}
                                </option>
                            ))}
                        </select>
                    </div>

                </div>
            </div>




                <div className="mb-3">
                    <div className="row gx-6">
                        <div className="col-md-3 mb-2 mb-md-0">
                            <button className='btn btn-primary d-block w-100 btn-lg h-9px fs-13px' onClick={handleSubmit}>{props.edit ? 'Save' : 'Add'}</button>
                        </div>
                        <div className="col-md-3 mb-2 mb-md-0">
                            {props.edit ? <button className='btn btn-gray d-block w-100 btn-lg h-9px fs-13px' onClick={handleCancel} >Cancel</button>
                                : <button className='btn btn-gray d-block w-100 btn-lg h-9px fs-13px' onClick={handleResetWithDefaultPrevent}>Reset</button>}
                        </div>
                    </div>

                </div>

        </form>

    )

    return (
        <Panel >
            <PanelHeader>User Form</PanelHeader>
            <PanelBody>
                {USERSFORM}
            </PanelBody>
        </Panel>
    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    addUser,
    editUser,
    fetchMerchants,
    setSnackbar,
    setModified,
    fetchIsos, fetchProcessors, fetchTerminalMake,
    fetchReportTypes, fetchPrefix
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAddEdit);