import React, { useEffect, useState } from 'react';
import Table from '../Table/Table';
import { tableHeadings } from '../../../utils/constants';
import { fetchTerminalBatchData } from '../../../redux/actions/TerminalActions';
import { connect } from "react-redux";
import { Panel, PanelHeader } from '../../header/panel/panel';
import { closeTerminalBatch, setLoadingBatchData } from '../../../redux/actions/TerminalActions';

const TerminalBatchList = (props) => {


    useEffect(() => {
        props.fetchTerminalBatchData(props.terminalID)

    },[props.TERMINALREDUCER.loadingBatchData])


    const handleBatchClose = (event) => {

        event.preventDefault();

        let body = {}

        if (props.terminalID) {
            body.terminalID = props.terminalID
            body.settle = true

        }


        if (body.terminalID) {

            props.setLoadingBatchData(true)
            props.closeTerminalBatch(body)
        }

    }

    const handleTrailBatchClose = (event) => {

        event.preventDefault();

        let body = {}

        if (props.terminalID) {
            body.terminalID = props.terminalID
            body.settle = false
        }


        if (body.terminalID) {

            props.setLoadingBatchData(true)
            props.closeTerminalBatch(body)
        }

    }



    return (

        <Panel>

            <PanelHeader>
                <div className="row mb-0">

                    <div className="col-xl-8">
                        Terminal Batch List
                    </div>
                    <div className="col-xl-2">
                    <button className='btn btn-sm btn-primary d-block w-40 btn-lg h-35px fs-09px' onClick={handleTrailBatchClose}>Test Batch</button>

                    </div>
                    <div className="col-xl-2">
                        <button className='btn btn-sm btn-primary d-block w-40 btn-lg h-35px fs-09px' onClick={handleBatchClose}>Close Batch</button>

                    </div>

                </div>
            </PanelHeader>

            {!props.TERMINALREDUCER.loadingBatchData ?  <Table height={600} headings={tableHeadings.terminalBatchTotals} data={props.TERMINALREDUCER.terminalBatchData} /> : null}

        </Panel>


    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchTerminalBatchData,
    closeTerminalBatch,
    setLoadingBatchData
}


export default connect(mapStateToProps, mapDispatchToProps)(TerminalBatchList);