import React, { useEffect, useState } from 'react';
import TableView from '../Table/Table';
import { tableHeadings } from '../../../utils/constants';
import { connect } from "react-redux";

import { useNavigate } from 'react-router';
import { Panel, PanelHeader } from '../../header/panel/panel';

import { fetchAllClients, fetchClient, filteredData, setEdited, setModified, setISOID } from '../../../redux/actions/IsoActions';

const ISOList = (props) => {

    const navigate = useNavigate()

    const [searchValue, setSearchValue] = useState('');


    useEffect(() => {
        props.fetchAllClients()
        props.setModified(false)
        props.filteredData()

        return () => {
            props.setEdited(false)
        }
    }, [])

    const handleClick = (value) => {
        props.setISOID(value.data.isoID)
        console.log(value.data.isoID)
        navigate('/iso/view')
    }

    const handleAddISO = (event) => {
        event.preventDefault()
        navigate('/iso/add')
    }

    const handleSearch = (event) => {
        setSearchValue(event.target.value)
        let filteredClients = event.target.value && event.target.value.length ? props.ISOREDUCER.clientsData.filter(client => {
            if (client.isoName.toLowerCase().includes(event.target.value.toLowerCase())) {
                return client
            }
        }) : []

        props.filteredData(filteredClients)

    }



    return (

        <Panel>

            <PanelHeader>

                <div className="row mb-0">

                    <div className="col-xl-4">
                        <h4>ISOs</h4>
                    </div>
                    <div className="col-xl-3">

                    </div>
                    <div className="col-xl-2">
                        <input type="text" className="form-control" placeholder="Search..." onChange={handleSearch} />

                    </div>
                    <div className="col-xl-1">

                    </div>
                    <div className="col-xl-2">
                        {props.LOGINREDUCER.USER.role == 1 ? <button className="btn btn-sm btn-primary d-block w-40 btn-lg h-35px fs-09px" onClick={handleAddISO}>Add ISO</button>
                            : null}
                    </div>

                </div>


            </PanelHeader>

            <TableView height={600} headings={tableHeadings['iso']} data={(props.ISOREDUCER.filteredData && props.ISOREDUCER.filteredData.length) || searchValue ? props.ISOREDUCER.filteredData : props.ISOREDUCER.clientsData} handleClick={handleClick} />

        </Panel>


    )

}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchAllClients,
    fetchClient,
    filteredData,
    setEdited,
    setModified,
    setISOID
}


export default connect(mapStateToProps, mapDispatchToProps)(ISOList);