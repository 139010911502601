import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Launch from './Launch';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AuthProvider } from './utils/Auth';

import './index.css'

import 'bootstrap/dist/css/bootstrap.css';
import 'react-calendar/dist/Calendar.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'flag-icon-css/css/flag-icons.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/react.scss';
import 'bootstrap-social/bootstrap-social.css';
import 'bootstrap-daterangepicker/daterangepicker.css';


ReactDOM.render((
    <AuthProvider>
        <BrowserRouter>
            <Launch/>
        </BrowserRouter>
    </AuthProvider>
), document.getElementById('root'));