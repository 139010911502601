import { combineReducers } from "redux";
import { LOGINREDUCER } from "./reducers/LoginReducer";
import { TERMINALREDUCER } from "./reducers/TerminalReducer";
import { ISOREDUCER } from "./reducers/IsoReducer";
import { MERCHANTREDUCER } from "./reducers/MerchantReducer";
import { TRANSACTIONREDUCER } from "./reducers/TransactionReducer";
import { DROPDOWNREDUCER } from './reducers/dropdownReducer';
import { USERSREDUCER } from './reducers/UserReducer';
import { SNACKBARREDUCER } from './reducers/SnackbarReducer';
import { UPLOADREDUCER } from "./reducers/UploadReducer";
import { EXPORTREDUCER } from './reducers/ExportReducers';

const appReducer = combineReducers({
  LOGINREDUCER,
  TERMINALREDUCER,
  ISOREDUCER,
  MERCHANTREDUCER,
  TRANSACTIONREDUCER,
  DROPDOWNREDUCER,
  USERSREDUCER,
  SNACKBARREDUCER,
  UPLOADREDUCER,
  EXPORTREDUCER
});

const rootReducer = (state, action) => {
  if (action.type == "LOGOUT_SUCCESS") {
    return appReducer(undefined, action)
  }
  return appReducer(state, action);
}

export default rootReducer;