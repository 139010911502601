import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { startLogout } from '../../../redux/actions/LoginActions';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { AccountCircle } from '@material-ui/icons';
import { setUserID } from '../../../redux/actions/UserActions';

const DropdownProfile = (props) =>  {

	const navigate = useNavigate();

	const handleLogout = () => {
        props.startLogout();
        navigate('/login');
    }
	const handleProfileClick = (value) => {
        props.setUserID(props.LOGINREDUCER.USER.userID)
        navigate('/user/profile')
    }

	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => {
		setDropdownOpen(!dropdownOpen);
	}

		return (
			<Dropdown isOpen={dropdownOpen} toggle={toggle} className="navbar-item navbar-user dropdown" tag="div">
				<DropdownToggle tag="a" className="navbar-link dropdown-toggle d-flex align-items-center">
					<AccountCircle></AccountCircle>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu dropdown-menu-end" end tag="div">
					<DropdownItem onClick={handleProfileClick}>View Profile</DropdownItem>
					<div className="dropdown-divider"></div>
					<button className='btn btn-sm btn-primary' onClick={handleLogout}> Log Out</button>
				</DropdownMenu>
			</Dropdown>
		);
};

function mapStateToProps(state) {
	return state
}

const mapDispatchToProps = {
	startLogout,setUserID
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownProfile);

