import React, { useState, useEffect } from 'react';
import Table from '../Table/Table';
import { BUSINSESS, tableHeadings } from '../../../utils/constants';
import { fetchAllUsers, fetchUser, filteredData, updatePassword, setModified, fetchAssignments, assignUser, fetchlUserDropdown, setUserID } from '../../../redux/actions/UserActions';
import { getAutoReportsList, addAutoReport, delAutoReport, processAutoReport, getAutoReportsLog } from '../../../redux/actions/ExportActions';
import { fetchAllTerminals } from '../../../redux/actions/TerminalActions';
import { fetchIsos } from "../../../redux/actions/dropdownActions";

import { connect } from "react-redux";
import { setSnackbar } from '../../../redux/actions/SnackbarAction';
import { fetchMerchants } from '../../../redux/actions/dropdownActions';

import { Panel, PanelBody, PanelHeader } from "../../header/panel/panel"

import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { LOGIN_AS_ROLES } from '../../../utils/constants';


const AutoReports = (props) => {

    const [reportType, setReportType] = useState('')
    const [businessDay, setBusinessDay] = useState(true)


    const [terminalError, setTerminalError] = useState(false)
    const [merchantError, setMerchantError] = useState(false)
    const [isoError, setIsoError] = useState(false);
    const [businessError, setBusinessError] = useState(false)
    const [reportError, setReportError] = useState(false)

    const [isoValue, setIsoValue] = useState('');
    const [merchantID, setMerchantID] = useState('')
    const [terminal, setTerminal] = useState('');

    const [terminalDropdown, setTerminalDropdown] = useState([])
    const [merchantDropdown, setMerchantDropdown] = useState([])
    // const [infoModelOpen, setInfoModelOpen] = useState(false);
    const [addModelOpen, setAddModelOpen] = useState(false);
    const [delModelOpen, setDelModelOpen] = useState(false)

    const [data, setData] = useState(null)

    const [subUsername, setSubUsername] = useState('')
    const [subUserRoleID, setSubUserRoleID] = useState('')


    const [userID, setUserID] = useState('')
    const [subUsernameError, setSubUsernameError] = useState(false)
    const [subUserRoleIDError, setSubUserRoleIDError] = useState(false)
    const [emailID, setEmailID] = useState('')


    const [userDropdown, setUserDropdown] = useState([])




    const reportDropdown = [{ reportID: 1, reportName: 'Summary' }, { reportID: 2, reportName: 'Detailed' }]

    useEffect(() => {
        props.getAutoReportsList()
        props.getAutoReportsLog()
        props.fetchAllTerminals()
        props.fetchIsos()
        props.fetchAllUsers()
        props.fetchlUserDropdown()

    }, [])


    const handleTerminalDrop = () => {

        if (props.DROPDOWNREDUCER.merchantsData && props.DROPDOWNREDUCER.merchantsData.length) {
            setTerminalDropdown([{ terminalID: "ALL TERMINALS" }, ...props.DROPDOWNREDUCER.merchantsData])
            console.log(terminalDropdown)
        } else {
            setTerminalDropdown([])
        }
    }

    const handleDeleteButton = (value) => {
        setDelModelOpen(true)
    }

    const handleDeleteConfirmation = (event) => {
        console.log(data?.data?.autoReportID)
        props.delAutoReport(data?.data?.autoReportID)
        setDelModelOpen(false)
    }

    const onCellClicked = (value) => {
        setData(value)
    }

    const handleChange = (event) => {
        let targetName = event.target.name
        let targetValue = event.target.value

        switch (targetName) {

            case 'reportType':
                if (targetValue) {
                    setReportError(false);
                    setReportType(targetValue)
                }
                break;

            case 'isoID':
                if (targetValue) {

                    setIsoError(false)
                    setIsoValue(targetValue);
                    let merchants = props.DROPDOWNREDUCER.isosData.filter(iso => {
                        return iso.isoID == targetValue
                    })

                    setMerchantDropdown(merchants[0]?.merchants)
                }
                break;

            case 'merchantID':
                if (targetValue) {
                    setMerchantError(false);
                    setMerchantID(targetValue)
                    props.fetchMerchants(targetValue)
                }
            case 'terminalID':
                setTerminalError(false);
                setTerminal(targetValue)
                break;


            case 'businessDay':
                setBusinessError(false);
                setBusinessDay(targetValue);
                break;
            case 'reportType':
                setReportError(false);
                setReportType(targetValue)
                break;
            case 'userID':
                setUserID(targetValue)
                break;
            case 'emailID':
                setEmailID(targetValue)
                break;
            case 'subUsername':
                setSubUsernameError(false)
                setSubUsername(targetValue)
                break;
            case 'subUserRoleID':
                setSubUserRoleID(targetValue)
                setSubUserRoleIDError(false)
                console.log('subUserRoleID : ' + subUserRoleID)
                let data = props.USERSREDUCER.userDropdownData.filter(data => {
                    return data.userRoleID == targetValue
                })
                setUserDropdown(data)
                console.log(data)
                break;

            default:
                break;
        }
    }


    const submitAdd = () => {


        let body = {}

        body.userID = userID
        body.reportOption = reportType;
        if (businessDay == "3") {
            body.reportType = "2"
            body.businessDay = false
        } else {
            body.reportType = "1"
            body.businessDay = businessDay
        }
        if (terminal && terminal == "ALL TERMINALS") {
            body.byMerchant = true;
            body.reportEntity = merchantID;
        } else {
            body.byMerchant = false;
            body.reportEntity = terminal;
        }

        body.emailID = emailID;

        console.log(body)

        props.addAutoReport(body)
        setAddModelOpen(false)
        handleClear()

    }

    const processAutoReports = () => {
        console.log('Processing auto reports')
        props.processAutoReport(1)

    }

    const handleClear = () => {
        setSubUsername('')
        setEmailID('')
        setSubUserRoleID('')
        setIsoValue('')
        setMerchantID('')
        setTerminal('')

    }

    const DELETE_CONFIRMATION = (
        <p className="lead text-dark">
            <SweetAlert danger showCancel
                confirmBtnText="Yes!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                onConfirm={handleDeleteConfirmation}
                onCancel={() => setDelModelOpen(!delModelOpen)}
            >

                Are you sure you want to delete - {data?.data?.username}  {' '}  {data?.data?.reportEntityName} {' Report'}

            </SweetAlert>
        </p>
    )

    const tableHeadings = {

        assignments: [
            { field: 'autoReportID', tooltipField: 'autoReportID', headerName: 'ID', minWidth: 130, maxWidth: 200 },

            { field: 'username', tooltipField: 'username', headerName: 'User', minWidth: 90, maxWidth: 120 },
            {
                field: 'reportOption', tooltipField: 'reportOption', headerName: 'Report option', minWidth: 120, maxWidth: 150, cellRenderer: function (params) {
                    return params.value == '1' ? 'Summary' : params.value == '2' ? 'Detailed' : 'N/A';
                }
            },
            {
                field: 'reportType', tooltipField: 'reportType', headerName: 'Report', minWidth: 90, maxWidth: 120, cellRenderer: function (params) {
                    return params.value == '1' ? 'Reconciled' : params.value == '2' ? 'Unreconciled' : 'N/A';
                }
            },
            {
                field: 'businessDay', tooltipField: 'businessDay', headerName: 'Business Day', minWidth: 120, maxWidth: 150, cellRenderer: function (params) {
                    return params.value == true ? 'Yes' : 'No';
                }
            },
            { field: 'reportEntityName', tooltipField: 'reportEntityName', headerName: 'Report Entity', minWidth: 120, maxWidth: 150, },
            { field: 'emailID', tooltipField: 'emailID', headerName: 'Email', minWidth: 200, maxWidth: 220, },
            {
                field: 'createDate', tooltipField: 'createDate', headerName: 'Created Date', minWidth: 200, maxWidth: 200, cellRenderer: function (params) {
                    return moment(params.value).format('MMM DD, YYYY HH:mm:ss');
                }
            },
            {
                field: 'Action', tooltipField: 'action', headerName: 'Action', minWidth: 120, cellRenderer: function (params) {
                    return <button className="btn btn-danger btn-icon btn-circle btn-sm me-1" onClick={handleDeleteButton}>X</button>
                }
            },
        ],

        autoReportLog: [

            { field: 'autoReportLogID', tooltipField: 'autoReportLogID', headerName: 'Auto Report Log ID', minWidth: 200, maxWidth: 200 },
            
            { field: 'autoReportID', tooltipField: 'autoReportID', headerName: 'Auto Report ID', minWidth: 200, maxWidth: 200 },
            
            { field: 'emailID', tooltipField: 'emailID', headerName: 'Email', minWidth: 250, maxWidth: 250 },
            
            {
                field: 'sentDate', tooltipField: 'sentDate', headerName: 'Sent Date', minWidth: 250, maxWidth: 250, cellRenderer: function(params) {
                    return moment(params.value).format('MMM DD, YYYY HH:mm:ss');
                }
            },

            {
                field: 'status', tooltipField: 'status', headerName: 'Status', minWidth: 120, maxWidth: 150, cellRenderer: function (params) {

                    return <div className={params.value == "1" ? 'approved' : 'reject'}>{params.value == '1' ? 'Sent' : params.value == '2' ? 'Failed' : 'Contact IT'}</div>

                    return ;
                }
            },
        ]

    }


    let AUTO_REPORT_LOG = (
        <Panel>
            <PanelHeader>
                Auto Report Log

            </PanelHeader>

            <Table height={600} headings={tableHeadings.autoReportLog} data={props.EXPORTREDUCER.autoReportLog} />


        </Panel>
    )

    let AUTO_REPORTS_LIST = (
        <Panel>

            <PanelHeader>

                <div className="row mb-0">

                    <div className="col-xl-6">
                        <h4>Auto Reports List</h4>
                    </div>
                    <div className="col-xl-2">

                    </div>
                    {/* <div className="col-xl-2">
                        <input type="text" className="form-control" placeholder="Search..." />

                    </div> */}

                    <div className="col-xl-1">
                        {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ?
                            <button className="btn btn-sm btn-primary d-block w-40 btn-lg h-35px fs-09px" onClick={() => setAddModelOpen(true)} >Add</button>
                            : null}
                    </div>
                    <div className="col-xl-3">
                        {props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ?
                            <button className="btn btn-sm btn-primary d-block w-40 btn-lg h-35px fs-09px" onClick={processAutoReports} >Process Reconciled Reports</button>
                            : null}
                    </div>

                </div>
            </PanelHeader>

            <Table height={600} headings={tableHeadings.assignments} data={props.EXPORTREDUCER.autoReport} handleClick={onCellClicked} />

        </Panel>
    )

    let AUTO_REPORT_ASSIGNMENT = (

        <p className="lead text-dark">

            <SweetAlert showCancel
                confirmBtnText="Add"
                cancelBtnBsStyle="default"
                onConfirm={submitAdd}
                onCancel={() => setAddModelOpen(!addModelOpen)}
            >

                <Panel>


                    <PanelHeader>Form</PanelHeader>

                    <PanelBody>

                        <form className="fs-13px">

                            <div className="row mb-3">
                                <div className="col-xl-12">

                                    <select className="form-select"
                                        value={subUserRoleID ? subUserRoleID : ''}
                                        onChange={handleChange}
                                        name='subUserRoleID'

                                    >
                                        <option value=''>Select Role</option>
                                        {LOGIN_AS_ROLES.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.role}
                                            </option>
                                        ))}

                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-xl-12">

                                    <select className="form-select"
                                        value={userID ? userID : ''}
                                        onChange={handleChange}
                                        name='userID'
                                    >
                                        <option value=''>Select User</option>
                                        {userDropdown.map((option) => (
                                            <option key={option.userID} value={option.userID}>
                                                {option.username}
                                            </option>
                                        ))}

                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <select className="form-select"
                                        onChange={handleChange}
                                        name='reportType'
                                        value={reportType ? reportType : ''}
                                    >

                                        <option value=''>Select Report Type</option>
                                        {reportDropdown.map((option) => (
                                            <option key={option.reportID} value={option.reportID}>
                                                {option.reportName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <select className="form-select"
                                        label="Business Day"
                                        value={businessDay ? businessDay : ''}
                                        onChange={handleChange}
                                        name='businessDay'
                                    >

                                        {BUSINSESS.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.status}
                                            </option>
                                        ))}

                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <select className="form-select"
                                        value={isoValue ? isoValue : ''}
                                        onChange={handleChange}
                                        name='isoID'
                                    >
                                        <option value=''>Select ISO</option>

                                        {props.DROPDOWNREDUCER.isosData.map((option) => (
                                            <option key={option.isoID} value={option.isoID}>
                                                {option.isoName}
                                            </option>
                                        ))}

                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <select className="form-select"
                                        value={merchantID ? merchantID : ''}
                                        onChange={handleChange}
                                        name='merchantID'
                                        onBlur={handleTerminalDrop}
                                    >

                                        <option value=''>Select Merchant</option>
                                        {merchantDropdown.map((option) => (
                                            <option key={option.merchantID} value={option.merchantID}>
                                                {option.merchantName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-xl-12">
                                    <select className="form-select"
                                        value={terminal ? terminal : ''}
                                        onChange={handleChange}
                                        name='terminalID'
                                    >
                                        <option value=''>Select Terminal</option>
                                        {terminalDropdown.map((option) => (
                                            <option key={option.terminalID} value={option.terminalID}>
                                                {option.terminalID}
                                            </option>
                                        ))}

                                    </select>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-xl-12">

                                    <input type="text"
                                        value={emailID ? emailID : ''}
                                        className="form-control fs-13px"
                                        placeholder="Email Address"
                                        name="emailID"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>


                        </form >
                    </PanelBody>

                </Panel>

            </SweetAlert>
        </p>
    )

    return (
        <>
            {AUTO_REPORTS_LIST}
            {AUTO_REPORT_LOG}
            {addModelOpen ?
                AUTO_REPORT_ASSIGNMENT : null
            }
            {
                delModelOpen ? DELETE_CONFIRMATION : null
            }

        </>
    )
}

function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchAllUsers, fetchUser, filteredData,
    setSnackbar, updatePassword, setModified,
    fetchAssignments, fetchMerchants, assignUser, getAutoReportsList,
    fetchAllTerminals, fetchIsos, fetchlUserDropdown, addAutoReport,
    delAutoReport, processAutoReport, getAutoReportsLog
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoReports);