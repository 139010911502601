import React, { useEffect, useState } from 'react';
import Table from '../Table/Table';
import { tableHeadings } from '../../../utils/constants';
import { connect } from "react-redux";
import { fetchAllTransactions } from '../../../redux/actions/TransactionActions';
import { Panel, PanelHeader } from '../../header/panel/panel';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import { setTerminalID } from '../../../redux/actions/TerminalActions';

const Transactions = (props) => {

    const [autoRefresh, setAutoRefresh] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {

        props.fetchAllTransactions(0);

        const interval = setInterval(() => {

            props.fetchAllTransactions(0);
        }, 10000)

        if (!autoRefresh) {
            clearInterval(interval)
        }
        return () => clearInterval(interval)

    }, [autoRefresh])


    const handleClick = (value) => {
        console.log(value.data.terminalId)
        props.setTerminalID(value.data.terminalId)
        navigate('/terminal/view')
    }


    return (

        <div>

            <Panel>
                <PanelHeader>

                    <Grid container >

                        <Grid item xs={4}><h5>Real Time Transactions</h5></Grid>

                        <Grid item xs={6}></Grid>

                        <Grid item xs={2} >
                            <div className="form-check form-switch mb-3">
                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Auto Refresh</label>
                                <input className="form-check-input" type="checkbox" checked={autoRefresh} onChange={e => setAutoRefresh(e.target.checked)} id="flexSwitchCheckDefault" />
                            </div>
                        </Grid>

                    </Grid>

                </PanelHeader>

                <Table height={600} headings={props.LOGINREDUCER.USER.role == 1 || props.LOGINREDUCER.USER.role == 6 ? tableHeadings.realtimeAdmin : tableHeadings.realtime} data={props.TRANSACTIONREDUCER.transactionsData} handleClick={handleClick}/>

            </Panel>
        </div>
    );

}


function mapStateToProps(state) {
    return state
}

const mapDispatchToProps = {
    fetchAllTransactions,
    setTerminalID

}


export default connect(mapStateToProps, mapDispatchToProps)(Transactions);