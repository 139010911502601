import axios from '../../config/axios';
import config from '../../config/api';
import { setSnackbar } from './SnackbarAction';

export const setLoading = (data) => ({
    type: 'SET_LOADING',
    data
})

export const setHistory = (data) => ({
    type: 'SET_HISTORY',
    data
})

export const setResult = (data) => ({
    type: 'SET_RESULT',
    data
})

export const setModified = (data) => ({
    type: 'SET_MODIFIED',
    data
})


export const uploadReport = (body) => {
    let formData = new FormData()
    formData.append('processID', body.processorID)
    formData.append('file', body.file)
    return function (dispatch) {
        axios.post(`${config.api.FETCH_URL}/reports/uploadFile/${body.processorID}`, formData).then(response => {
            if (response.status === 200) {
                if (response.data.status == 'Fail') {
                    dispatch(setSnackbar(true, 'error', response.data.message))

                } else {
                    dispatch(setSnackbar(true, 'success', response.data.message))
                }
            } else {
                dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
            }
            dispatch(getUploadHistory())
            dispatch(setLoading(false))
        }).catch(error => {
            dispatch(setLoading(false))
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const uploadKeys = (body) => {
    let formData = new FormData()
    formData.append('file', body.file)
    return function (dispatch) {
        axios.post(`${config.api.KEYS_URL}/keys/import-from-file`, formData).then(response => {
            if (response.status === 200) {
                dispatch(setResult(response.data))
                dispatch(setSnackbar(true, 'success', 'Report Uploaded Sucessfully'))
                dispatch(setModified(true))
            } 
            dispatch(setLoading(false))
        }).catch(error => {
            dispatch(setLoading(false))
            dispatch(setResult([]))
            dispatch(setSnackbar(true, 'error', 'Something Went Wrong'))
        })
    }
}

export const resetKey = (body) => {
    return function (dispatch) {
        axios.post(`${config.api.KEYS_URL}/keys/resetKey`, body).then(response => {
            if (response.status === 200) {
                dispatch(setResult(response.data))
                dispatch(setSnackbar(true, 'success', 'Key Reset Sucessful'))
                dispatch(setModified(true))
            } 
            dispatch(setLoading(false))
        }).catch(error => {
            dispatch(setLoading(false))
            dispatch(setResult([]))
            dispatch(setSnackbar(true, 'error', 'Error - Try again later'))
        })
    }
}


export const getUploadHistory = () => {
    return function (dispatch) {
        axios.get(`${config.api.FETCH_URL}/reports/uploadHistory`).then(response => {
            if (response.status === 200) {
                dispatch(setHistory(response.data))
            } else {
                dispatch(setHistory([]))
            }
        }).catch(error => {
            dispatch(setHistory([]))
        })
    }

    
}
