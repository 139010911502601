import React from 'react';
import { Link } from 'react-router-dom';

class ErrorPage extends React.Component {
  
	render() {
		return (
			<div className="error">
				{/* <div className="error-code">400</div> */}
				<div className="error-content">
					<div className="error-message">Page not found or you do not have access to this page</div>
					<div className="error-desc mb-4">
						Please go home by clicking below link <br />
					</div>
					<div>
						<Link to="/dashboard" className="btn btn-success px-3">Go Home</Link>
					</div>
				</div>
			</div>
		)
	}
}

export default ErrorPage;